import React from "react";
import Slider from "react-slick";
import useStyles, { FormInputSelect } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import map from "../../assets/images/map.png";

import {
    Container,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    Checkbox,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
    ArrowBackIos,
    ArrowDropDown,
    CheckCircleOutline,
    HighlightOff,
    Mail,
    Phone,
    Star,
    Storefront,
} from "@mui/icons-material";
import {
    contact_us,
    get_area,
    get_brand,
    get_colour,
    get_profile,
    get_surfaces,
    get_user,
    get_warranty_product,
    register_warranty,
} from "../../API/API";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/FormInput/FormInput";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


export default function Warranty() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useNavigate();

    const [token, setToken] = React.useState([]);

    React.useEffect(() => {
        let token = window.localStorage.getItem("token");
        setToken(token || "");
        console.log("CURRENT TOKEN", token);
    }, []);

    const [colour, setColour] = React.useState([]);
    const [selectdColour, setSelectedColour] = React.useState("");

    const [area, setArea] = React.useState([]);
    const [selectedArea, setSelectedArea] = React.useState("");

    const [brand, setBrand] = React.useState([]);
    const [selectedBrand, setSelectedBrand] = React.useState("");

    const [surfaces, setSurfaces] = React.useState([]);
    const [selectedSurfaces, setSelectedSurfaces] = React.useState("");

    const [selectedSubSeries, setSelectedSubSeries] = React.useState("");

    const [chooseUser, setChooseUser] = React.useState("");

    const [acceptTerms, setAcceptTerms] = React.useState(false);

    const [warrantyProduct, setWarrantyProduct] = React.useState([]);

    const getWarrantyProduct = () => {
        get_warranty_product({
            token: token,
            brand_id: selectedBrandID,
            series_id: selectedSubSeries,
        }).then((json) => {
            if (json.status) {
                setWarrantyProduct(json.data)
            }

        })
    }

    const getColour = () => {
        get_colour().then((json) => {
            setColour(json.data);
        });
    };
    const getArea = () => {
        get_area().then((json) => {
            setArea(json.data);
        });
    };
    const getBrand = () => {
        get_brand().then((json) => {
            setBrand(json.data);
        });
    };

    const getSurfaces = () => {
        get_surfaces().then((json) => {
            setSurfaces(json.data);
        });
    };

    const [selectedProduct, setSelectedProduct] = React.useState("");

    const handleChangeProduct = (event) => {
        const selectedProductID = event.target.value;
        setSelectedProduct(selectedProductID);

        const selectedProduct = warrantyProduct.find(
            (product) => product.product_id === selectedProductID
        );
        const selectedProductName = selectedProduct ? selectedProduct.name : "";

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            product: selectedProductName,
        }));
    };

    const [selectedBrandID, setSelectedBrandID] = React.useState("");

    const handleChangeBrand = (event) => {
        const selectedBrandID = event.target.value;
        setSelectedBrand(selectedBrandID);
        setSelectedBrandID(selectedBrandID);

        const selectedBrand = brand.find(
            (item) => item.brand_id === selectedBrandID
        );
        const selectedBrandName = selectedBrand ? selectedBrand.name : "";

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            brand: selectedBrandName,
            series: null,
        }));

        getWarrantyProduct();
    };

    const handleChangeSubSeries = (event) => {
        const selectedSubSeriesID = event.target.value;
        const selectedBrand = brand.find(
            (item) => item.brand_id === selectedBrandID
        );
        const selectedSubSeries = selectedBrand
            ? selectedBrand.series.find(
                (item) => item.series_id === selectedSubSeriesID
            )
            : null;
        setSelectedSubSeries(selectedSubSeriesID);

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            series: selectedSubSeries ? selectedSubSeries.name : "",
        }));
    };

    // const handleChangeColour = (event) => {
    //     const selectedColourID = event.target.value;
    //     const selectedColour = colour.find((item) => item.colour_id === selectedColourID);
    //     const selectedColourCode = selectedColour ? selectedColour.color_code : "";
    //     setSelectedColour(selectedColourID);

    //     setWarrantyData((prevWarrantyData) => ({
    //         ...prevWarrantyData,
    //         colour: selectedColourCode,
    //     }));
    // };
    const handleChangeArea = (event) => {
        const selectedAreaID = event.target.value;
        const selectedArea = area.find((item) => item.area_id === selectedAreaID);
        const selectedAreaName = selectedArea ? selectedArea.name : "";
        setSelectedArea(selectedAreaID);

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            area: selectedAreaName,
        }));
    };

    const handleChangeSurfaces = (event) => {
        const selectedSurfacesID = event.target.value;
        const selectedSurfaces = surfaces.find(
            (item) => item.surfaces_id === selectedSurfacesID
        );
        const selectedSurfacesName = selectedSurfaces ? selectedSurfaces.name : "";
        setSelectedSurfaces(selectedSurfacesID);

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            surfaces: selectedSurfacesName,
        }));
    };

    const [imageFile, setImageFile] = React.useState();

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

    //RADIO

    const handleChooseUser = (event) => {
        const selectedUser = event.target.value;

        setChooseUser(selectedUser);
        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            user: selectedUser,
        }));
        console.log(event.target.value);
    };

    //WARRANTY DATA

    const [warrantyData, setWarrantyData] = React.useState({
        user: "",
        name: "",
        address: "",
        address2: "",
        postcode: "",
        city: "",
        phone: "",
        email: "",
        brand: "",
        series: "",
        product: "",
        surfaces: "",
        purchase_from: "",
        area: "",
        date: "",
    });

    const handleSubmitWarranty = () => {
        if (
            !warrantyData.user ||
            !warrantyData.name ||
            !warrantyData.address ||
            !warrantyData.postcode ||
            !warrantyData.city ||
            !warrantyData.phone ||
            !warrantyData.email ||
            !warrantyData.brand ||
            // (selectedBrand && selectedBrand.series && selectedBrand.series.length > 0 && !warrantyData.series) ||
            // !warrantyData.product ||
            // !warrantyData.surfaces ||
            // !warrantyData.area ||
            !warrantyData.date ||
            !imageFile
        ) {
            alert("Please fill in all required fields.");
            return;
        }
        if (!acceptTerms) {
            alert("Please accept the warranty terms and privacy policy.");
            return;
        }

        const formData = new FormData();

        formData.append("user", warrantyData.user);
        formData.append("name", warrantyData.name);
        formData.append("address", warrantyData.address);
        formData.append("address2", warrantyData.address2);
        formData.append("postcode", warrantyData.postcode);
        formData.append("city", warrantyData.city);
        formData.append("phone", warrantyData.phone);
        formData.append("email", warrantyData.email);
        formData.append("brand", warrantyData.brand);
        formData.append("series", warrantyData.series);
        formData.append("product", warrantyData.product);
        formData.append("surfaces", warrantyData.surfaces);
        formData.append("area", warrantyData.area);
        formData.append("date", warrantyData.date);
        formData.append("purchase_from", warrantyData.purchase_from);

        formData.append("image", imageFile);

        // const formDataValues = {};

        // for (let pair of formData.entries()) {
        //   formDataValues[pair[0]] = pair[1];
        // }

        // console.log(formDataValues);

        register_warranty(formData).then((json) => {
            if (json.status) {
                const translatedMessage = json[`${i18n.language}_message`] || json.message;
                alert(t(`${translatedMessage}`));
            } else {
                const translatedMessage = json[`${i18n.language}_message`] || json.message;
                alert(t(`${translatedMessage}`));
            }
        });

        // register_warranty({ ...warrantyData, image: imageFile }).then((json) => {
        //     if (json.status) {
        //         alert(json.message);
        //     } else {
        //         alert(json.message);
        //     }
        // });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "date") {
            const currentDate = new Date().toISOString().split("T")[0];
            if (value > currentDate) {
                // Display error message or reset the date to current date
                alert("Selected date cannot be greater than the current date.");
                setWarrantyData((prevWarrantyData) => ({
                    ...prevWarrantyData,
                    date: currentDate,
                }));
                return;
            }
        }

        setWarrantyData((prevWarrantyData) => ({
            ...prevWarrantyData,
            [name]: value,
        }));
    };

    React.useEffect(() => {
        getColour();
        getBrand();
        getArea();
        getSurfaces();
        getWarrantyProduct();
    }, [
        selectedBrandID,
        selectedSubSeries
    ]);
    return (
        <React.Fragment>
            <Navbar />

            <div style={{ padding: "8rem 0 0 0" }}>
                {/* <Container style={{ marginBottom: "2rem" }}> */}
                <Section pd="2ren" style={{ marginBottom: "2rem" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paragraph
                            color="#000"
                            bold="700"
                            size="24px"
                            margin="0 0 1rem 0"
                            center
                            style={{ fontFamily: "'Gotu', sans-serif" }}
                        >
                            {t("Welcome, you are")}:
                        </Paragraph>
                        <Paragraph
                            color="#000"
                            bold="700"
                            size="20px"
                            margin="0 0 2rem 0"
                            center
                        >
                            {chooseUser}
                        </Paragraph>
                        <div className={classes.radioForm}>
                            <div className={classes.radioUser}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="otto+"
                                    name="radio-buttons-group"
                                    style={{ color: "#fff" }}
                                    value={chooseUser}
                                >
                                    <FormControlLabel
                                        value={t("House Owner")}
                                        control={<Radio style={{ color: "#fff" }} />}
                                        label={t("House Owner")}
                                        onChange={handleChooseUser}
                                    />
                                    <FormControlLabel
                                        value={t("Fabricator")}
                                        control={<Radio style={{ color: "#fff" }} />}
                                        label={t("Fabricator")}
                                        onChange={handleChooseUser}
                                    />
                                    <FormControlLabel
                                        value={t("Designer / Architecture")}
                                        control={<Radio style={{ color: "#fff" }} />}
                                        label={t("Designer / Architecture")}
                                        onChange={handleChooseUser}
                                    />
                                    <FormControlLabel
                                        value={t("Carpenter / Contractor")}
                                        control={<Radio style={{ color: "#fff" }} />}
                                        label={t("Carpenter / Contractor")}
                                        onChange={handleChooseUser}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                </Section>
                {/* </Container> */}
                <Container>
                    <Section>
                        <div className={classes.theForm}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Paragraph
                                        color="#000"
                                        bold="700"
                                        size="24px"
                                        margin="0 0 2rem 0"
                                        style={{ fontFamily: "'Gotu', sans-serif" }}
                                    >
                                        {t("Buyer's Details and Installation Address")}
                                    </Paragraph>
                                    {/* <FormInput
                                        label="Surname"
                                        placeholder="Surname"
                                        border="1px solid #707070"
                                        name="surname"
                                    // onChange={handleChange}
                                    /> */}
                                    <FormInput
                                        label={t("Name")}
                                        placeholder={t("Name")}
                                        border="1px solid #707070"
                                        name="name"
                                        onChange={handleChange}
                                        value={warrantyData.name}
                                    />
                                    <FormInput
                                        label={t("Address")}
                                        placeholder={t("Address")}
                                        border="1px solid #707070"
                                        name="address"
                                        onChange={handleChange}
                                        value={warrantyData.address}
                                    />
                                    <FormInput
                                        label={t("Address 2")}
                                        placeholder={t("Address 2")}
                                        border="1px solid #707070"
                                        name="address2"
                                        onChange={handleChange}
                                        value={warrantyData.address2}
                                    />
                                    <FormInput
                                        label={t("Zip / Postal Code")}
                                        placeholder={t("Zip / Postal Code")}
                                        border="1px solid #707070"
                                        name="postcode"
                                        type="number"
                                        onChange={handleChange}
                                        value={warrantyData.postcode}
                                    />
                                    <FormInput
                                        label={t("Town / City")}
                                        placeholder={t("Town / City")}
                                        border="1px solid #707070"
                                        name="city"
                                        onChange={handleChange}
                                        value={warrantyData.city}
                                    />
                                    <FormInput
                                        label={t("Phone")}
                                        placeholder={t("Phone")}
                                        border="1px solid #707070"
                                        name="phone"
                                        type="number"
                                        onChange={handleChange}
                                        value={warrantyData.phone}
                                    />
                                    <FormInput
                                        label={t("Email")}
                                        placeholder={t("Email")}
                                        border="1px solid #707070"
                                        name="email"
                                        onChange={handleChange}
                                        value={warrantyData.email}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Paragraph
                                        color="#000"
                                        bold="700"
                                        size="24px"
                                        margin="0 0 2rem 0"
                                        style={{ fontFamily: "'Gotu', sans-serif" }}
                                    >
                                        {t("Worktop Details")}
                                    </Paragraph>
                                    <div>
                                        <Paragraph margin="0 0 1rem 0 ">{t("Brand")}</Paragraph>
                                        <FormInputSelect className={classes.theWidth02}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="brand"
                                                value={selectedBrand}
                                                onChange={handleChangeBrand}
                                                style={{ width: "100%", margin: "0 0 1rem 0" }}
                                            >
                                                {brand.map((item) => (
                                                    <MenuItem
                                                        key={item.brand_id}
                                                        value={item.brand_id}
                                                        style={{ color: "#000" }}
                                                    >
                                                        {i18n.language === "en" && item.name}
                                                        {i18n.language === "my" && item.malay_brand_name}
                                                        {i18n.language === "my" &&
                                                            item.malay_brand_name == "" &&
                                                            item.name}
                                                        {i18n.language === "cn" && item.chinese_brand_name}
                                                        {i18n.language === "cn" &&
                                                            item.chinese_brand_name == "" &&
                                                            item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormInputSelect>
                                        {brand.map((item) => (
                                            <>
                                                {selectedBrand == item.brand_id &&
                                                    item.series.length > 0 && (
                                                        <>
                                                            <Paragraph margin="0 0 1rem 0 ">
                                                                {t("Sub-Series")}
                                                            </Paragraph>
                                                            <FormInputSelect className={classes.theWidth02}>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    name="series"
                                                                    value={selectedSubSeries}
                                                                    onChange={handleChangeSubSeries}
                                                                    style={{
                                                                        width: "100%",
                                                                        margin: "0 0 1rem 0",
                                                                    }}
                                                                >
                                                                    {item.series.map((seriesItem) => (
                                                                        <MenuItem
                                                                            key={seriesItem.series_id}
                                                                            value={seriesItem.series_id}
                                                                            style={{ color: "#000" }}
                                                                        >
                                                                            {i18n.language === "en" &&
                                                                                seriesItem.name}
                                                                            {i18n.language === "my" &&
                                                                                seriesItem.malay_series_name}
                                                                            {i18n.language === "my" &&
                                                                                seriesItem.malay_series_name == "" &&
                                                                                seriesItem.name}
                                                                            {i18n.language === "cn" &&
                                                                                seriesItem.chinese_series_name}
                                                                            {i18n.language === "cn" &&
                                                                                seriesItem.chinese_series_name == "" &&
                                                                                seriesItem.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormInputSelect>
                                                        </>
                                                    )}
                                            </>
                                        ))}
                                        <Paragraph margin="0 0 1rem 0 ">{t("Colour")}</Paragraph>
                                        <FormInputSelect className={classes.theWidth02}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="product"
                                                value={selectedProduct}
                                                onChange={handleChangeProduct}
                                                style={{ width: "100%", margin: "0 0 1rem 0" }}
                                            >
                                                {warrantyProduct.map((item) => (
                                                    <MenuItem
                                                        key={item.product_id}
                                                        value={item.product_id}
                                                        style={{ color: "#000" }}
                                                    >
                                                        {/* <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            <span
                                                                className="checkmark"
                                                                style={{ background: item.color_code }}
                                                            ></span> */}
                                                        <Paragraph>
                                                            {item.name}
                                                            {/* {i18n.language === 'en' && item.name}
                                                                {i18n.language === 'my' && item.malay_name}
                                                                {i18n.language === 'my' && item.malay_name == "" && item.name}
                                                                {i18n.language === 'cn' && item.chinese_name}
                                                                {i18n.language === 'cn' && item.chinese_name == "" && item.name} */}
                                                        </Paragraph>
                                                        {/* </div> */}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormInputSelect>
                                        <Paragraph margin="0 0 1rem 0 ">{t("Surfaces")}</Paragraph>
                                        <FormInputSelect className={classes.theWidth02}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="surfaces"
                                                value={selectedSurfaces}
                                                onChange={handleChangeSurfaces}
                                                style={{ width: "100%", margin: "0 0 1rem 0" }}
                                            >
                                                {surfaces.map((item) => (
                                                    <MenuItem
                                                        key={item.surfaces_id}
                                                        value={item.surfaces_id}
                                                        style={{ color: "#000" }}
                                                    >
                                                        {i18n.language === "en" && item.name}
                                                        {i18n.language === "my" && item.malay_surfaces_name}
                                                        {i18n.language === "my" &&
                                                            item.malay_surfaces_name == "" &&
                                                            item.name}
                                                        {i18n.language === "cn" &&
                                                            item.chinese_surfaces_name}
                                                        {i18n.language === "cn" &&
                                                            item.chinese_surfaces_name == "" &&
                                                            item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormInputSelect>
                                        <Paragraph margin="0 0 1rem 0 ">{t("Area")}</Paragraph>
                                        <FormInputSelect className={classes.theWidth02}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="area"
                                                value={selectedArea}
                                                onChange={handleChangeArea}
                                                style={{ width: "100%", margin: "0 0 0 0" }}
                                            >
                                                {area.map((item) => (
                                                    <MenuItem
                                                        key={item.area_id}
                                                        value={item.area_id}
                                                        style={{ color: "#000" }}
                                                    >
                                                        {i18n.language === "en" && item.name}
                                                        {i18n.language === "my" && item.malay_area_name}
                                                        {i18n.language === "my" &&
                                                            item.malay_area_name == "" &&
                                                            item.name}
                                                        {i18n.language === "cn" && item.chinese_area_name}
                                                        {i18n.language === "cn" &&
                                                            item.chinese_area_name == "" &&
                                                            item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormInputSelect>
                                        <FormInput
                                            label={t("Invoice Date")}
                                            placeholder={t("Invoice Date")}
                                            type="date"
                                            border="1px solid #707070"
                                            name="date"
                                            onChange={handleChange}
                                            value={warrantyData.date}
                                        />
                                        {/* <hr style={{ borderTop: "1px dashed #817E7E"}} /> */}
                                        {/* <Paragraph
                                            margin="0 0 1rem 0 "
                                        >
                                            Proof of Purchase:
                                        </Paragraph> */}
                                        <FormInput
                                            label={t("Proof of Purchase")}
                                            type="file"
                                            border="1px solid #707070"
                                            accept="image/*"
                                            name="image"
                                            onChange={handleImageChange}
                                        />
                                        <FormInput
                                            label={t("Purchase From")}
                                            placeholder={t("Purchase From")}
                                            border="1px solid #707070"
                                            name="purchase_from"
                                            onChange={handleChange}
                                            value={warrantyData.purchase_from}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTerms}
                                        onChange={(event) => setAcceptTerms(event.target.checked)}
                                    />
                                }
                                label={t("I accept the warranty terms and the Privacy Policy")}
                            />
                            <br /><br />
                            <Button
                                label={t("REGISTER WARRANTY")}
                                color="#fff"
                                bg="#353535"
                                br="6px"
                                onClick={() => handleSubmitWarranty()}
                            />
                        </div>
                    </Section>
                </Container>
                <Container>
                    <Section pd="2rem" style={{ marginBottom: "1rem" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <a
                                    // className={classes.pdfWarranty}
                                    style={{
                                        display: "flex",
                                        border: "1px solid #000",
                                        padding: ".4rem .8rem",
                                        borderRadius: "8px",
                                        //   width: "26%"
                                    }}
                                >
                                    <Paragraph>{t("Read Warranty Terms and Condition")}</Paragraph>
                                    <NavigateNextIcon style={{ marginLeft: ".3rem" }} />
                                </a>
                            </Grid>
                        </Grid>

                    </Section>
                </Container>
            </div>
            <Footer />
        </React.Fragment>
    );
}
