import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import banner002 from "../../assets/images/banner002.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
  display: flex;
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  root2: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        width: "95%",
        height: "100%",
        margin: "0 auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "&img": {
      height: "10px",
      width: "100%",
      objectFit: "cover"
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  root3: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  root4: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-list": {
      height: "20rem",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "& .slick-arrow": {
      color: "black",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "black",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "black",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "black",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "black",
          },
        },
      },
    },
  },

  bannerOverlay: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  textOverlay: {
    display: "flex",
    flexDirection: 'column',
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "150px"
  },

  theBanner: {
    position: "relative",
    width: "100%",
    // height: "20rem",

    // ...md("md", {
    //   height: "26rem"
    // }),


    // ...md("xmd", {
    //   height: "35rem"
    // }),

    // ...md("slg", {
    //   height: "47rem"
    // }),


    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "34px",
    ...md("sm", {
      fontSize: "44px",
    }),
    ...md("md", {
      fontSize: "54px",
    }),
    ...md("xmd", {
      fontSize: "64px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "20px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theSeeMore: {
    width: "30%",
    fontSize: "12px",
    background: "#333335",
    padding: ".4rem 1rem",
    color: "#FFF",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "600",

    ...md("md", {
      width: "15%",
    }),

    ...md("xmd", {
      width: "10%",
    }),
  },

  theBox: {
    position: "absolute",
    // width: "100%",
    top: "50%",
    left: "5%",
    transform: "translateY(-50%)",

    ...md("md", {
      top: "50%",
      width: "50%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },



  theService: {
    position: "relative",
    width: "20%",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "100%",
      marginBottom: "1rem",
      transition: "0.5s",
    },
  },

  theFlex: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",

    ...md("md", {
      display: "flex",
    }),
  },

  theTabList: {
    display: "flex",
  },

  theMB: {
    marginTop: "2rem",
    display: "block",
    fontStyle: "italic",
    ...md("md", {
      marginTop: "0rem",
      fontStyle: "unset",
    }),
  },

  theTabActive: {
    borderBottom: "2px solid #800000",
    marginRight: "1.5rem",
    cursor: "pointer",
    marginTop: ".4rem"
  },
  theTabActive1: {
    borderBottom: "2px solid #800000",
    marginRight: ".5rem",
    cursor: "pointer",
    marginTop: ".4rem"
  },
  theTab: {
    borderBottom: "2px solid #FFF",
    marginRight: "1.5rem",
    cursor: "pointer",
    marginTop: ".4rem"
  },
  theTab1: {
    borderBottom: "2px solid #FFF",
    marginRight: ".5rem",
    cursor: "pointer",
    marginTop: ".4rem"
  },
  _theTabActive1: {
    borderBottom: "5px solid #800000",
    marginRight: ".5rem",
    cursor: "pointer",
    marginBottom: "1rem",
    width: "150px"
    // padding: "10px 40px"
  },
  _theTab1: {
    borderBottom: "5px solid #ECEBEF",
    marginRight: ".5rem",
    cursor: "pointer",
    marginBottom: "1rem",
    width: "150px"
    // padding: "10px 40px"
  },
  theGallery: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  theStone: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex",
      height: "20rem",
      objectFit: "cover",
    },
    ...md("sm", {
      "& img": {
        width: "100%",
        display: "flex",
        height: "25rem",
        objectFit: "cover"
      }
    }),
    ...md("md", {
      "& img": {
        width: "100%",
        display: "flex",
        height: "35rem",
        objectFit: "cover"
      }
    }),
    ...md("lg", {
      "& img": {
        width: "100%",
        display: "flex",
        height: "40rem",
        objectFit: "cover",
      }
    }),

  },
  theOverlay: {
    position: "absolute",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    top: 0,
    left: 0,
    padding: "1rem",
    background: "rgba(0,0,0,.5)",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  theShopProd: {
    position: "relative",
    width: "100%",
    top: "3rem",
    "& img": {
      width: "70%",
      margin: "0 auto",
      display: "flex",
      height: "100%",
      objectFit: "cover"
    },
  },
  theProd: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "70%",
      margin: "0 auto",
      display: "flex",
      height: "100%",
      objectFit: "cover"
    },
  },
  theGallerySlider: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex",
      height: "30rem",
      objectFit: "cover",
    },
  },
  theBG: {
    height: "auto",
    backgroundImage: "url(" + banner002 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
  },
  thePersonStar: {
    position: "relative",
    width: "15%",
    marginRight: "1rem",

    ...md("sm", {
      width: "8%",
    }),

    ...md("md", {
      width: "15%",
    }),
    "& img": {
      width: "100%",
    },
  },

  theMap: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theCustom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    width: "70%",
    margin: "0 auto",
    padding: "2rem",

    ...md("md", {
      padding: "2rem 6rem",
    }),
  },
  theCert: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  imageBrand: {
    width: "100%",
    position: "relative",

    "& img": {
      width: "100%",
      height: "100px",
      position: "relative",
      objectFit: "contain"
    }
  },
  // cardHover: {
  //   padding: "1.5rem",
  //   background: "#FFF",
  //   transition: "0.5s",

  //   "&:hover": {
  //     padding: "1.5rem",
  //     background: "#FFF",
  //     transform: "translateY(-10px)",
  //     transition: "0.5s",
  //   },
  //   "&:hover img": {
  //     transform: "translate(5px) scale(1.2)",
  //     transition: "0.5s",
  //   }
  // },
  cardHover: {
    display: "block",
    top: "0px",
    position: "relative",
    backgroundColor: "#f2f8f9",
    borderRadius: "4px",
    padding: "32px 24px",
    margin: "8px",
    zIndex: "0",
    border: "1px solid #f2f8f9",
    transition: "0.5s",

    "&:hover": {
      transition: "0.5s",
      boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
      top: "-8px",
      border: "1px solid #cccccc",
      backgroundColor: "#fff",
    },
    "&:hover img": {
      transition: "0.5s",
      transform: "translateY(-6px) scale(1.2)",
      backgroundColor: "white",
    },
  },
  productOverlay: {
    position: "relative",
    background: "black",
    padding: "1rem",
    width: "80px",
    bottom: "2rem",

    ...md("sm", {
      width: "120px",
    })
  },
  textProduct: {
    fontSize: "18px",

    ...md("sm", {
      fontSize: "24px"
    })
  },
  centerCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
};

export default makeStyles(config);
