import React, { useEffect, useState } from "react";
import useStyles, { FooterContainer, NavbarLogo, NavbarLink } from "./styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import Section from "../Section/Section";
import Paragraph from "../Typography/Paragraph";
import FormInputNoLabel from "../FormInputNoLabel/FormInputNoLabel";
import {
  Mail,
  Phone,
  Storefront,
  Facebook,
  Instagram,
} from "@mui/icons-material";

import logo from "../../assets/images/logo.png";

import thelogo from "../../assets/images/thelogo.png";

import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Button from "../Button/Button";

import { subscribe } from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [contactData, setContactData] = useState({
    email: "",
  });

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitSubscribe = () => {
    if (contactData.email.length == 0) {
      alert(t("All Field Required"));
      return;
    }
    subscribe(contactData).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        setContactData({
          email: "",
        });
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  return (
    <React.Fragment>
      <FooterContainer>
        <Container>
          <Section pd="0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className={classes.theLogo}>
                  <img src={logo} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a>
                    <Facebook />
                  </a>
                  <a>
                    <Instagram />
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Paragraph margin="0 0 1rem 0" color="#333335" bold="700">
                  {t("CONTACT US")}
                </Paragraph>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <Mail style={{ color: "#353535" }} />
                  <Paragraph color="#353535" margin="0 0 0 1rem">
                    ottoglobalapexsurfacing@email.com
                  </Paragraph>
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <Phone style={{ color: "#353535" }} />
                  <div>
                    <div style={{ display: "flex" }}>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        MY
                      </Paragraph>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        +607-6991449 / +6010-9301449
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        SG
                      </Paragraph>
                      <Paragraph color="#353535" margin="0 0 0 1rem">
                        +65-63658781
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: ".4rem" }}>
                  <Storefront style={{ color: "#353535" }} />
                  <div>
                    <div style={{ display: "flex" }}>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        Mon - Fri
                      </Paragraph>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        8:30 am - 6:00 pm
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        Sat
                      </Paragraph>
                      <Paragraph color="#353535" margin="0 0 0 1rem">
                        8.30 am - 1:00 pm
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph color="#353535" margin="0 0 .4rem 1rem">
                        Sun
                      </Paragraph>
                      <Paragraph color="#353535" margin="0 0 0 1rem">
                        Closed
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Paragraph margin="0 0 1rem 0" color="#333335" bold="700">
                  {t("SUBSCRIBE")}
                </Paragraph>
                <Paragraph>
                  {t("Enter your email to get notified about our latest promotion")}
                </Paragraph>
                <br />
                <div style={{ display: "flex" }}>
                  <FormInputNoLabel
                    placeholder={t("Email")}
                    border="1px sollid #d7d7d7"
                    pd="1rem"
                    name="email"
                    onChange={handleChange}
                    style={{fontStyle:"italic"}}
                  />
                  <Button
                    style={{ width: "50%" }}
                    label={t("SUBMIT")}
                    bg="#000"
                    color="#FFF"
                    pd="1.1rem 2rem"
                    onClick={() => handleSubmitSubscribe()}
                  />
                </div>
              </Grid>
            </Grid>
            <br />
          </Section>
        </Container>
      </FooterContainer>
      <Container>
        <div
          style={{
            padding: "1rem 0",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div className={classes.theCert}>
            <img src={thelogo} />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
