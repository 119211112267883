import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { useTranslation } from "react-i18next";



const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);
  const { t, i18n } = useTranslation();


  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    setCart(cart);
  }

  function getProductQuantityInCart(productId) {
    const cartItems = getCart();
    const productInCart = cartItems.find((item) => item.product_id === productId);

    if (productInCart) {
      return productInCart.quantity;
    }

    return 0; // Return 0 if the product is not found in the cart
  }

  function addProduct(item) {
    console.log(item);
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    newItem.price = getPriceByQuantity(newItem, newItem.quantity).toFixed(2);

    if (cartTemp.length === 0) {
      newItem["index"] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;

      for (var i = 0; i < cartTemp.length; i++) {
        if (cartTemp[i].product_id == newItem.product_id) {
          existed = 1;
          index = i;
        }
      }

      if (existed == 1) {
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);

        if (existed == 1 && new_quantity > cartTemp[index].stock) {
          alert(t("Maximum stock reached for this product"));
          return false;
        }

        cartTemp[index]["quantity"] = new_quantity;
        cartTemp[index]["price"] = parseFloat(
          getPriceByQuantity(cartTemp[index], new_quantity).toFixed(2)
        );
      } else {
        newItem["index"] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);

    return true;

  }

  function getTotalCart() {
    var total_amount = 0;

    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }

    for (var i = 0; i < cartTemp.length; i++) {
      var arr = cartTemp[i].price;
      // console.log("pricee", cartTemp[i].price)
      total_amount = parseFloat(total_amount) + parseFloat(arr);
    }
    return total_amount;
  }

  function removeProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    setCart(cartTemp);
    Storage(cartTemp);
  }

  function getPriceByQuantity(cartRowItem, qty) {
    return cartRowItem.single_price * qty;
  }

  function increaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }

    var stock = cartTemp[index].stock






    if (cartTemp[index].quantity >= stock) {
      alert(t("Maximum stock reached for this product"))
    } else {
      var quantity = parseFloat(cartTemp[index].quantity) + 1;
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] = parseFloat(
        getPriceByQuantity(cartTemp[index], quantity)
      );
    }



    setCart(cartTemp);
    Storage(cartTemp);
  }

  function decreaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] = getPriceByQuantity(cartTemp[index], quantity);
    } else {
      cartTemp.splice(index, 1);
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    setCartFunction,
    decreaseProduct,
    increaseProduct,
    getProductQuantityInCart,
  };
};

export default useCart;
