import React from "react";
// import Slider from "react-slick";
import useStyles, { CarouselContainer, CarouselIMG, FormInputSelect } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import bannershop from "../../assets/images/bannershop.png";

import table from "../../assets/images/table.png";
import Slider from "react-slick";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Pagination,
  Hidden
} from "@mui/material";
import {

  Slider as PriceSlider
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  HighlightOff,
  Star,
} from "@mui/icons-material";
import { get_area, get_banner, get_outlet_store, get_shop, get_type, get_user } from "../../API/API";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Shop() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { user_id, page_id } = useParams();


  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [user, setUser] = React.useState([]);
  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");
          localStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("isLoggedIn");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };


  const { hide_price } = user;

  // const [userType, setUserType] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = window.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");
  // }, [])

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);

  const [banner, setBanner] = React.useState([]);
  const getBanner = (page_id) => {
    get_banner({ page_id: 3 }).then((json) => {
      setBanner(json.data)
    })
  }

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [sort, setSort] = React.useState(0);

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  // const [expanded, setExpanded] = React.useState(1);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [expanded, setExpanded] = React.useState([1]);
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded((prevExpanded) => [...prevExpanded, panel]);
    } else {
      setExpanded((prevExpanded) =>
        prevExpanded.filter((item) => item !== panel)
      );
    }
  };

  // API SHOP

  const [type, setType] = React.useState([]);

  const [area, setArea] = React.useState([]);

  const getType = () => {
    get_type().then((json) => {
      setType(json.data);
    });
  };

  const getArea = () => {
    get_area().then((json) => {
      setArea(json.data);
    });
  };

  React.useEffect(() => {
    getType();
    getArea();
    getBanner(page_id);
    if (isLoggedIn) {
      getUser();
    }
  }, [isLoggedIn]);

  const [shop, setShop] = React.useState([]);

  const [outlet, setOutlet] = React.useState([]);

  const [chooseType, setChooseType] = React.useState(0);

  const handleChooseType = (type_id) => {
    setChooseType(type_id);
    console.log(type_id);
  };

  const [chooseArea, setChooseArea] = React.useState(0);

  const handleChooseArea = (area_id) => {
    setChooseArea(area_id);
    console.log(area_id);
  };

  const [chooseMinPrice, setChooseMinPrice] = React.useState(0);

  const [chooseMaxPrice, setChooseMaxPrice] = React.useState(0);

  const handleChangeResetFilter = () => {
    setChooseType(0);
    setChooseArea(0);
    setChooseMinPrice(0);
    setChooseMaxPrice(0);
    setValue([0, 100]);
  };

  const getShop = () => {
    get_shop({
      token: token,
      type_id: chooseType,
      area_id: chooseArea,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
    }).then((json) => {
      setShop(json.data);
      setPageNum(json.pages);
    });
  };

  const getOutletStore = () => {
    get_outlet_store({
      token: token,
      type_id: chooseType,
      area_id: chooseArea,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
    }).then((json) => {
      setOutlet(json.data);
      setPageNum(json.pages)
    })
  }

  React.useEffect(() => {
    getShop();
    getOutletStore();
  }, [chooseType, chooseArea, chooseMinPrice, chooseMaxPrice, sort]);

  const [pageList, setPageList] = React.useState(1);

  const [pageNum, setPageNum] = React.useState("");

  const handleChangePage = (ispage) => {
    setPageList(ispage);
    get_shop({
      type_id: chooseType,
      area_id: chooseArea,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
      page: ispage,
    }).then((json) => {
      setShop(json.data);
    });
    get_outlet_store({
      token: token,
      type_id: chooseType,
      area_id: chooseArea,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
    }).then((json) => {
      setOutlet(json.data);
    });
  };


  // SHOP FLITER PRICE RANGE

  const priceRange = [
    {
      value: 0,
      scaledValue: 0,
    },
    {
      value: 25,
      scaledValue: 1000,
    },
    {
      value: 50,
      scaledValue: 1250,
    },
    {
      value: 75,
      scaledValue: 1875,
    },
    {
      value: 100,
      scaledValue: 2500,
    },
    {
      value: 125,
      scaledValue: 3125,
    },
    {
      value: 150,
      scaledValue: 3750,
    },
    {
      value: 175,
      scaledValue: 4375,
    },
    {
      value: 200,
      scaledValue: 5000,
    },
  ];

  const scaleValues = (valueArray) => {
    let min = scale(valueArray[0]);
    let max = scale(valueArray[1]);

    return "RM " + min + " - " + "RM " + max;
  };

  const scale = (value) => {
    if (value === undefined) {
      return undefined;
    }
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = priceRange[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = priceRange[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  function numFormatter(num) {
    if (num >= 0) {
      return "RM " + num.toFixed(0);
    }
  }

  const [value, setValue] = React.useState([0, 100]);

  const handleChangeRange = (event, newValue) => {
    setValue(newValue);
  };

  const getValue = () => {
    setChooseMinPrice(value[0]);
    setChooseMaxPrice(value[1]);
  };

  React.useEffect(() => {
    getValue();
  }, [value]);

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    // console.log(event.target.value);
  };

  return (
    <React.Fragment>
      <Navbar />

      <div>
        <Slider className={classes.root3} {...settings}>
          {banner != null &&
            banner.map((item) => {
              return (
                <>
                  <div className={classes.bannerOverlay}>
                    <div className={classes.theBanner}>
                      <CarouselContainer>
                        <CarouselIMG src={window.innerWidth > 968 ? item.pc_image : item.mobile_image} />
                      </CarouselContainer>
                    </div>
                    {/* <div className={classes.textOverlay}>
                    <Paragraph
                      className={classes.theFontTitle}
                      size="50px"
                      color="black"
                      bold="700"
                      margin="0 0 .4rem 0"
                    >
                      {item.text}
                    </Paragraph>
                  </div> */}
                  </div>
                </>
              );
            })}
        </Slider>
      </div>

      {/* <div className={classes.theBanner}>
        <img src={bannershop} />
      </div> */}
      <Container>
        <Section pd="2rem 0">
          <div className={classes.theFlex}>
            <div className={classes.theTabList}>
              <div
                className={tab == 0 ? classes.theTabActive : classes.theTab}
                onClick={() => handleChangeTab(0)}
              >
                <Paragraph
                  bold={tab == 0 ? "bold" : "normal"}
                  margin="0 0 .4rem 0"
                  style={{ textTransform: "uppercase", fontFamily: "'Gotu', sans-serif" }}
                  size="14px"
                >
                  {t("Shop")}
                </Paragraph>
              </div>
              <div
                className={tab == 1 ? classes.theTabActive : classes.theTab}
                onClick={() => handleChangeTab(1)}
              >
                <Paragraph
                  bold={tab == 1 ? "bold" : "normal"}
                  margin="0 0 .4rem 0"
                  style={{ textTransform: "uppercase", fontFamily: "'Gotu', sans-serif" }}
                  size="14px"
                >
                  {t("Outlet Store")}
                </Paragraph>
              </div>
            </div>
          </div>
          <br />
          <br />
          {tab == 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <FormInputNoLabel
                  placeholder={t("Search Here")}
                  border="1px solid #acacac"
                  onChange={handleSearch}
                  value={searchKey}
                />
                <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                  {t("Shop Filter")}:
                </Paragraph>
                <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                  {t("Shop All")}
                </Paragraph>
                <hr />
                <Accordion expanded={expanded.includes(1)} onChange={handleChange(1)}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                    aria-controls="1"
                    id="1"
                  >
                    <Paragraph size="16px" bold="600" color="#353535">
                      {t("Type")}
                    </Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={chooseType}
                      name="radio-buttons-group"
                    >
                      {type.map((item) => (
                        <FormControlLabel
                          value={item.type_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_type_name ||
                            i18n.language === 'my' && item.malay_type_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_type_name ||
                            i18n.language === 'cn' && item.chinese_type_name === "" && item.name
                          }
                          onChange={() => handleChooseType(item.type_id)}
                        />
                      ))}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Accordion expanded={expanded.includes(2)} onChange={handleChange(2)}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                    aria-controls="2"
                    id="2"
                  >
                    <Paragraph size="16px" bold="600" color="#353535">
                      {t("Area")}
                    </Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={chooseArea}
                      name="radio-buttons-group"
                    >
                      {area.map((item) => (
                        <FormControlLabel
                          value={item.area_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_area_name ||
                            i18n.language === 'my' && item.malay_area_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_area_name ||
                            i18n.language === 'cn' && item.chinese_area_name === "" && item.name
                          }
                          onChange={() => handleChooseArea(item.area_id)}
                        />
                      ))}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Paragraph color="#353535" bold="600" margin="1rem 0 1rem 0">
                  {t("Price Range")}
                </Paragraph>
                <PriceSlider
                  value={value}
                  onChange={handleChangeRange}
                  valueLabelDisplay="auto"
                  // getAriaValueText={valuetext}
                  min={0}
                  step={1}
                  max={200}
                  valueLabelFormat={numFormatter}
                  // marks={priceRange}
                  scale={scaleValues}
                />
                <Paragraph color="#7C7C7C">
                  {t("Price")}:
                  <b style={{ color: "#353535", marginLeft: "1rem" }}>
                    {/* RM 0 - RM 3210 */}
                    {scaleValues(value)}
                  </b>
                </Paragraph>
                <br />
                <br />
                <Button
                  label={t("Reset Filter")}
                  color="#FFF"
                  bg="#353535"
                  onClick={() => handleChangeResetFilter()}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <div className={classes.theWidth}>
                  <Paragraph
                    color="#353535"
                    bold="500"
                    className={classes.theWidth01}
                  >
                    {t("Sort By")}:
                  </Paragraph>
                  <FormInputSelect className={classes.theWidth02}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sort}
                      defaultValue={sort}
                      onChange={handleChangeSort}
                    >
                      <MenuItem value="0" style={{ color: "#000" }}>
                        {t("Latest to Oldest")}
                      </MenuItem>
                      <MenuItem value="1" style={{ color: "#000" }}>
                        {t("Oldest to Latest")}
                      </MenuItem>
                    </Select>
                  </FormInputSelect>
                </div>
                <br />

                <Grid container spacing={3}>
                  {shop != null &&
                    shop.map((item, index) => {
                      if (searchKey != "") {
                        if (
                          item.name
                            .toUpperCase()
                            .indexOf(searchKey.toUpperCase()) <= -1
                        ) {
                          return;
                        }
                      }
                      return (
                        <Grid item xs={12} sm={6} md={4}>
                          <a href={`../shopdetail/${item.product_id}`}>
                            <Card style={{ padding: "1rem" }}>
                              <div className={classes.theGallery}>
                                <img src={item.image} />
                              </div>
                              <Paragraph color="#333335" size="12px" bold="100">
                                {i18n.language === 'en' && item.area}
                                {i18n.language === 'my' && item.malay_area_name}
                                {i18n.language === 'my' && item.malay_area_name == "" && item.area}
                                {i18n.language === 'cn' && item.chinese_area_name}
                                {i18n.language === 'cn' && item.chinese_area_name == "" && item.area}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .4rem 0"
                              >
                                {i18n.language === 'en' && item.name}
                                {i18n.language === 'my' && item.malay_name}
                                {i18n.language === 'my' && item.malay_name == "" && item.name}
                                {i18n.language === 'cn' && item.chinese_name}
                                {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                              </Paragraph>
                              <hr />
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: ".6rem",
                                }}
                              > */}
                              {businessType == 1 && hide_price == 0 ? (
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".6rem" }}>
                                  <div>
                                    <Paragraph color="#800000" bold="600">
                                      RM {item.discount_business_price}
                                    </Paragraph>
                                    <Paragraph
                                      color="#333335"
                                      bold="600"
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      RM {item.business_price}
                                    </Paragraph>
                                  </div>
                                  <div
                                    style={{
                                      background: "#333335",
                                      padding: ".3rem 1rem",
                                      borderRadius: "8px",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Paragraph color="#FFF">
                                      {item.discount_business_percent}%
                                    </Paragraph>
                                  </div>
                                </div>
                              ) : businessType == 1 && hide_price == 1 ? (
                                <div>
                                  {Hidden}
                                </div>
                              ) : (
                                <></>
                              )}

                              {businessType == 2 && hide_price == 0 ? (
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".6rem" }}>
                                  <div>
                                    <Paragraph color="#800000" bold="600">
                                      RM {item.discount_price}
                                    </Paragraph>
                                    <Paragraph
                                      color="#333335"
                                      bold="600"
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      RM {item.individual_price}
                                    </Paragraph>
                                  </div>
                                  <div
                                    style={{
                                      background: "#333335",
                                      padding: ".3rem 1rem",
                                      borderRadius: "8px",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Paragraph color="#FFF">
                                      {item.discount_percent}%
                                    </Paragraph>
                                  </div>
                                </div>
                              ) : businessType == 2 && hide_price == 1 ? (
                                <div>
                                  {Hidden}
                                </div>
                              ) : (
                                <></>
                              )}

                              {/* <div>
                                  <Paragraph color="#800000" bold="600">
                                    RM {item.discount_price}
                                  </Paragraph>
                                  <Paragraph
                                    color="#333335"
                                    bold="600"
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    RM {item.business_price}
                                  </Paragraph>
                                </div> */}
                              {/* <div
                                  style={{
                                    background: "#333335",
                                    padding: ".3rem 1rem",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Paragraph color="#FFF">
                                    {item.discount_percent}%
                                  </Paragraph>
                                </div> */}
                              {/* </div> */}
                            </Card>
                          </a>
                        </Grid>
                      );
                    })}
                </Grid>
                <br />
                <br />
                <Stack
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    page={pageList}
                    onChange={handleChangePage}
                    count={pageNum}
                    defaultPage={pageList}
                  // count={10}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
          {tab == 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <FormInputNoLabel
                  placeholder={t("Search Here")}
                  border="1px solid #acacac"
                />
                <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                  {t("Shop Filter")}:
                </Paragraph>
                <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                  {t("Shop All")}
                </Paragraph>
                <hr />
                <Accordion expanded={expanded.includes(1)} onChange={handleChange(1)}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                    aria-controls="1"
                    id="1"
                  >
                    <Paragraph size="16px" bold="600" color="#353535">
                      {t("Type")}
                    </Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={chooseType}
                      name="radio-buttons-group"
                    >
                      {type.map((item) => (
                        <FormControlLabel
                          value={item.type_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_type_name ||
                            i18n.language === 'my' && item.malay_type_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_type_name ||
                            i18n.language === 'cn' && item.chinese_type_name === "" && item.name
                          }
                          onChange={() => handleChooseType(item.type_id)}
                        />
                      ))}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Accordion expanded={expanded.includes(2)} onChange={handleChange(2)}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                    aria-controls="2"
                    id="2"
                  >
                    <Paragraph size="16px" bold="600" color="#353535">
                      {t("Area")}
                    </Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={chooseArea}
                      name="radio-buttons-group"
                    >
                      {area.map((item) => (
                        <FormControlLabel
                          value={item.area_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_type_name ||
                            i18n.language === 'my' && item.malay_type_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_type_name ||
                            i18n.language === 'cn' && item.chinese_type_name === "" && item.name
                          }
                          onChange={() => handleChooseArea(item.area_id)}
                        />
                      ))}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
                <hr />
                <Paragraph color="#353535" bold="600" margin="1rem 0 1rem 0">
                  {t("Price Range")}
                </Paragraph>
                <PriceSlider
                  value={value}
                  onChange={handleChangeRange}
                  valueLabelDisplay="auto"
                  // getAriaValueText={valuetext}
                  min={0}
                  step={1}
                  max={200}
                  valueLabelFormat={numFormatter}
                  // marks={priceRange}
                  scale={scaleValues}
                />
                <Paragraph color="#7C7C7C">
                  {t("Price")}:
                  <b style={{ color: "#353535", marginLeft: "1rem" }}>
                    {/* RM 0 - RM 3210 */}
                    {scaleValues(value)}
                  </b>
                </Paragraph>
                <br />
                <br />
                <Button
                  label={t("Reset Filter")}
                  color="#FFF"
                  bg="#353535"
                  onClick={() => handleChangeResetFilter()}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <div className={classes.theWidth}>
                  <Paragraph
                    color="#353535"
                    bold="500"
                    className={classes.theWidth01}
                  >
                    {t("Sort By")}:
                  </Paragraph>
                  <FormInputSelect className={classes.theWidth02}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sort}
                      defaultValue={sort}
                      onChange={handleChangeSort}
                    >
                      <MenuItem value="0" style={{ color: "#000" }}>
                        {t("Latest to Oldest")}
                      </MenuItem>
                      <MenuItem value="1" style={{ color: "#000" }}>
                        {t("Oldest to Latest")}
                      </MenuItem>
                    </Select>
                  </FormInputSelect>
                </div>
                <br />

                <Grid container spacing={3}>
                  {outlet.map((item) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <a href={`../shopdetail/${item.product_id}`}>
                        <Card style={{ padding: "1rem" }}>
                          <div className={classes.theGallery}>
                            <img src={item.image} />
                          </div>
                          <Paragraph color="#333335" size="12px" bold="100">
                            {i18n.language === 'en' && item.area}
                            {i18n.language === 'my' && item.malay_name}
                            {i18n.language === 'my' && item.malay_name == "" && item.area}
                            {i18n.language === 'cn' && item.chinese_name}
                            {i18n.language === 'cn' && item.chinese_name == "" && item.area}
                          </Paragraph>
                          <Paragraph
                            color="#333335"
                            bold="600"
                            margin="0 0 .4rem 0"
                          >
                            {i18n.language === 'en' && item.name}
                            {i18n.language === 'my' && item.malay_name}
                            {i18n.language === 'my' && item.malay_name == "" && item.name}
                            {i18n.language === 'cn' && item.chinese_name}
                            {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                          </Paragraph>
                          <hr />
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: ".6rem",
                            }}
                          > */}
                            {businessType == 1 && hide_price == 0 ? (
                              <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".6rem" }}>
                                <div>
                                  <Paragraph color="#800000" bold="600">
                                    RM {item.discount_business_price}
                                  </Paragraph>
                                  <Paragraph
                                    color="#333335"
                                    bold="600"
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    RM {item.business_price}
                                  </Paragraph>
                                </div>
                                <div
                                  style={{
                                    background: "#333335",
                                    padding: ".3rem 1rem",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Paragraph color="#FFF">
                                    {item.discount_business_percent}%
                                  </Paragraph>
                                </div>
                              </div>
                            ) : businessType == 1 && hide_price == 1 ? (
                              <div>
                                {Hidden}
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* {businessType == 1 && hide_price == 0 ? (
                              <div>
                                <Paragraph color="#800000" bold="600">
                                  RM {item.discount_business_price}
                                </Paragraph>
                                <Paragraph
                                  color="#333335"
                                  bold="600"
                                  style={{ textDecoration: "line-through" }}
                                >
                                  RM {item.business_price}
                                </Paragraph>
                              </div>
                            ) : businessType == 1 && hide_price == 1 ? (
                              <div>
                                {Hidden}
                              </div>
                            ) : (
                              <></>
                            )} */}

                            {businessType == 2 && hide_price == 0 ? (
                              <div style={{ display: "flex", justifyContent: "space-between", marginTop: ".6rem" }}>
                                <div>
                                  <Paragraph color="#800000" bold="600">
                                    RM {item.discount_price}
                                  </Paragraph>
                                  <Paragraph
                                    color="#333335"
                                    bold="600"
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    RM {item.individual_price}
                                  </Paragraph>
                                </div>
                                <div
                                  style={{
                                    background: "#333335",
                                    padding: ".3rem 1rem",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Paragraph color="#FFF">
                                    {item.discount_percent}%
                                  </Paragraph>
                                </div>
                              </div>
                            ) : businessType == 2 && hide_price == 1 ? (
                              <div>
                                {Hidden}
                              </div>
                            ) : (
                              <></>
                            )}

                            {/* <div>
                              <Paragraph color="#800000" bold="600">
                                RM {item.discount_price}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                style={{ textDecoration: "line-through" }}
                              >
                                RM {item.business_price}
                              </Paragraph>
                            </div> */}
                          {/* </div> */}
                        </Card>
                      </a>
                    </Grid>
                  ))}
                </Grid>
                <br />
                <br />
                <Stack
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    page={pageList}
                    onChange={handleChangePage}
                    count={pageNum}
                    defaultPage={pageList}
                  // count={10}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
