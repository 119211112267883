import React from "react";
import Slider from "react-slick";
import useStyles, { CarouselContainer, CarouselIMG } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

// import banner from "../../assets/images/banner.png";
import service001 from "../../assets/images/service001.png";
import service002 from "../../assets/images/service002.png";
import service003 from "../../assets/images/service003.png";
import service004 from "../../assets/images/service004.png";
import service005 from "../../assets/images/service005.png";
import gallery001 from "../../assets/images/gallery001.png";
import stone001 from "../../assets/images/stone001.png";
import table from "../../assets/images/table.png";
import personstar from "../../assets/images/personstar.png";
import map from "../../assets/images/map.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import { ArrowDropDown, Mail, Phone, Storefront } from "@mui/icons-material";
import {
  contact_us,
  get_area,
  get_banner,
  get_gallery,
  get_product,
  get_profile,
  get_random_shop,
  get_stone,
  get_user,
} from "../../API/API";
import { json, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../hooks/LoadingSreen";
// import { get_article, get_faq, get_news } from "../../API/API";

import {
  motion,
  useAnimation,
  useMotionValueEvent,
  useViewportScroll,
  useElementScroll,
  useScroll,
  useTransform,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from "framer-motion";



export default function Home() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { user_id, page_id } = useParams();
  const history = useNavigate();


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings3 = {
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    height: "20rem",
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings5 = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
    ],
  };
  const settings6 = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };


  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [tab, setTab] = React.useState("0");

  const handleChangeTab = (area_id) => {
    setTab(area_id);
    console.log(area_id)
  };

  // API BANNER & SHOP & GALLERY

  const [banner, setBanner] = React.useState([]);
  // const [gallery, setGallery] = React.useState([]);

  const [gallery, setGallery] = React.useState([]);
  const [area, setArea] = React.useState([]);
  const [randomShop, setRandomShop] = React.useState([]);


  const getBanner = (page_id) => {
    get_banner({ page_id: 1 }).then((json) => {
      setBanner(json.data);
    });
  };

  const getGallery = () => {
    get_gallery({ area_id: tab }).then((json) => {
      setGallery(json.data);
    });
  };

  const getArea = () => {
    get_area().then((json) => {
      setArea(json.data);
    });
  };

  // const get_data = async () => {
  //   var res = await get_area();
  //   if (res.status) {
  //     setArea({
  //       ...res.data,
  //       name: {
  //         en: res.data.name,
  //         my: res.data.malay_area_name,
  //         cn: res.data.chinese_area_name
  //       }
  //     })
  //   }
  // }

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    // get_data();

    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);

  const getRandomShop = () => {
    get_random_shop({}).then((json) => {
      setRandomShop(json.data);
    });
  };

  // const getUser = () => {
  //   get_user({ token: token }).then((json) => {
  //     if (json.status) {
  //       setUser(json.data)
  //     } else {
  //       alert(json.message);
  //       window.localStorage.removeItem("token")
  //       window.localStorage.removeItem("business_type")
  //       window.location.reload()
  //     }
  //   })
  // }

  const [user, setUser] = React.useState([])
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");

        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  const { hide_price } = user;

  React.useEffect(() => {
    getBanner(page_id);
    getGallery();
    getArea();
    getRandomShop();
    getStone();
    if (isLoggedIn) {
      getUser();
      getProfile();
    }
  }, [
    tab,
    isLoggedIn
  ]);

  // API CONTACT US

  const [contactData, setContactData] = React.useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleContactUs = () => {
    if (
      profileData.email.length == "" ||
      profileData.name.length == "" ||
      profileData.contact.length == "" ||
      contactData.message.length == ""
    ) {
      alert(t("All Field Required"));
      return;
    }
    contact_us({ ...contactData, ...profileData, token: token }).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  const [stone, setStone] = React.useState([]);
  const getStone = () => {
    get_stone().then((json) => {
      setStone(json.data);
    });
  }

  const [profileData, setProfileData] = React.useState({
    name: "",
    contact: "",
    email: "",
  });

  const getProfile = () => {
    get_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
      } else {
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
      }
    })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  // LOADING SCREENTIMEOUT

  const [isLoading, setIsLoading] = React.useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);


  // SCROLLING ANIMATION


  const { scrollY } = useScroll();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const [scrollYValue, setScrollYValue] = React.useState(0);

  useMotionValueEvent(scrollY, "change", (scrolled) => {
    // console.log("PAGE SCROLLED", scrolled);
    setScrollYValue(scrolled);
  });

  React.useEffect(() => {
    if (scrollYValue >= 700) {
      controls1.start({ y: 0, opacity: 1, transition: { duration: 1 } });
    }
    if (scrollYValue >= 1600) {
      controls2.start({ x: 0, opacity: 1, transition: { duration: 1 } });
    }
    if (scrollYValue >= 300) {
      controls3.start({ x: 0, opacity: 1, transition: { duration: 1 } });
    }
    if (scrollYValue >= 300) {
      controls4.start({ x: 0, opacity: 1, transition: { duration: 1 } });
    }
  }, [scrollYValue]);


  return (
    <React.Fragment>
      {isLoading ? <LoadingScreen /> :
        <>
          <Navbar />

          <div>
            <Slider className={classes.root3} {...settings}>
              {banner != null &&
                banner.map((item, index) => {
                  return (
                    <>
                      <div key={index} className={classes.bannerOverlay}>
                        <div className={classes.theBanner}>
                          <CarouselContainer>
                            <CarouselIMG src={window.innerWidth > 968 ? item.pc_image : item.mobile_image} />
                          </CarouselContainer>
                        </div>
                        {/* <div className={classes.textOverlay}>
                    <Paragraph
                      className={classes.theFontTitle}
                      size="50px"
                      color="#FFF"
                      bold="700"
                      margin="0 0 .4rem 0"
                    >
                      {item.text}
                    </Paragraph>
                  </div> */}
                      </div>
                    </>
                  );
                })}
            </Slider>
          </div>

          {/* <div>
          {stone.map((item) => {
            return(
              <div>
                <Paragraph>
                  {window.innerWidth > 1024 ? item.name : item.category}
                </Paragraph>
              </div>
            )
          })}
        </div> */}
          {/* <div className={classes.bannerOverlay}>
        <div className={classes.theBanner}>
          <Slider className={classes.root} {...settings}>
            {banner.map((item) => (
              <CarouselContainer>
                <CarouselIMG src={item.image} />
                <div className={classes.textOverlay}>
                  <Paragraph
                    className={classes.theFontTitle}
                    size="24px"
                    color="black"
                    bold="700"
                    margin="0 0 .4rem 0"
                    center
                  >
                    {item.text}
                  </Paragraph>
                </div>
              </CarouselContainer>
            ))}
          </Slider>
        </div>
      </div> */}
          <div style={{ background: "#333335" }}>
            <Container>
              <Section pd="4rem 0">
                <Paragraph
                  color="#FFF"
                  bold="700"
                  size="24px"
                  margin="0 0 3rem 0"
                  center
                  style={{ fontFamily: "'Gotu', sans-serif" }}
                >
                  {t("OUR SERVICES")}
                </Paragraph>

                {window.innerWidth > 968 ? (
                  <>
                    <Slider className={classes.root2} {...settings2}>
                      <motion.div
                        initial={{
                          x: -400,
                          opacity: 0,
                        }}
                        animate={controls3}
                      >
                        <Card>
                          <div className={classes.cardHover}>
                            <div className={classes.centerCard}>
                              <div className={classes.theService}>
                                <img src={service001} />
                              </div>
                            </div>
                            <Paragraph bold="700">{t("Supply Material")}</Paragraph>
                            <hr />
                            <Paragraph size="14px">
                              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                              Aenean commodo ligula eget
                            </Paragraph>
                          </div>
                        </Card>
                      </motion.div>
                      <motion.div
                        initial={{
                          x: -400,
                          opacity: 0,
                        }}
                        animate={controls3}
                      >
                        <Card>
                          <div className={classes.cardHover}>
                            <div className={classes.centerCard}>
                              <div className={classes.theService}>
                                <img src={service002} />
                              </div>
                            </div>
                            <Paragraph bold="700">{t("Installment")}</Paragraph>
                            <hr />
                            <Paragraph size="14px">
                              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                              Aenean commodo ligula eget
                            </Paragraph>
                          </div>
                        </Card>
                      </motion.div>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service003} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Customization")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                      <motion.div
                        initial={{
                          x: 400,
                          opacity: 0,
                        }}
                        animate={controls4}
                      >
                        <Card>
                          <div className={classes.cardHover}>
                            <div className={classes.centerCard}>
                              <div className={classes.theService}>
                                <img src={service004} />
                              </div>
                            </div>
                            <Paragraph bold="700">{t("Renovation")}</Paragraph>
                            <hr />
                            <Paragraph size="14px">
                              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                              Aenean commodo ligula eget
                            </Paragraph>
                          </div>
                        </Card>
                      </motion.div>
                      <motion.div
                        initial={{
                          x: 400,
                          opacity: 0,
                        }}
                        animate={controls4}
                      >
                        <Card>
                          <div className={classes.cardHover}>
                            <div className={classes.centerCard}>
                              <div className={classes.theService}>
                                <img src={service005} />
                              </div>
                            </div>
                            <Paragraph bold="700">{t("Maintenance")}</Paragraph>
                            <hr />
                            <Paragraph size="14px">
                              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                              Aenean commodo ligula eget
                            </Paragraph>
                          </div>
                        </Card>
                      </motion.div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider className={classes.root2} {...settings2}>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service001} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Supply Material")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service002} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Installment")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service003} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Customization")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service004} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Renovation")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                      <Card>
                        <div className={classes.cardHover}>
                          <div className={classes.centerCard}>
                            <div className={classes.theService}>
                              <img src={service005} />
                            </div>
                          </div>
                          <Paragraph bold="700">{t("Maintenance")}</Paragraph>
                          <hr />
                          <Paragraph size="14px">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                            Aenean commodo ligula eget
                          </Paragraph>
                        </div>
                      </Card>
                    </Slider>
                  </>
                )}
              </Section>
            </Container>
          </div>
          <Container>
            <Section style={{ position: "relative", top: "2rem" }} pd="0">
              <Paragraph
                color="#000"
                bold="700"
                size="24px"
                margin="0 0 2rem 0"
                center
                style={{ fontFamily: "'Gotu', sans-serif" }}
              >
                {t("GALLERY")}
              </Paragraph>
              {/* <div className={classes.theFlex}>
            <div className={classes.theTabList}>
              {area.map((item) => (
                <div
                  className={
                    tab == item.area_id ? classes.theTabActive : classes.theTab
                  }
                  onClick={() => handleChangeTab(item.area_id)}
                >
                  <Paragraph
                    bold={tab == item.area_id ? "bold" : "normal"}
                    margin="0 0 .4rem 0"
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.name}
                  </Paragraph>
                </div>
              ))}
            </div>

          </div> */}
              {window.innerWidth > 768 ? (
                <div className={classes.theFlex}>
                  <div className={classes.theTabList}>
                    {area.map((item, index) => (
                      <div key={index}>
                        <div
                          key={item.area_id}
                          className={
                            tab === item.area_id ? classes.theTabActive : classes.theTab
                          }
                          onClick={() => handleChangeTab(item.area_id)}
                        >
                          <Paragraph
                            bold={tab == item.area_id ? "bold" : "normal"}
                            margin="0 0 .4rem 0"
                            style={{ textTransform: "uppercase" }}
                          >
                            {i18n.language === 'en' && item.name}
                            {i18n.language === 'my' && item.malay_area_name}
                            {i18n.language === 'my' && item.malay_area_name == "" && item.name}
                            {i18n.language === 'cn' && item.chinese_area_name}
                            {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
                          </Paragraph>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a className={classes.theMB} href="/gallery">
                    {t("VIEW MORE")}
                  </a>
                </div>
              ) : (
                <>
                  {/* <Slider className={classes.root2} {...settings6}>
                {area.map((item, index) => (
                  <div key={index}>
                    <div
                      key={item.area_id}
                      className={
                        tab === item.area_id ? classes.theTabActive1 : classes.theTab1
                      }
                      onClick={() => handleChangeTab(item.area_id)}
                    >
                      <Paragraph
                        bold={tab == item.area_id ? "bold" : "normal"}
                        margin="0 0 .4rem 0"
                        center
                        style={{ textTransform: "uppercase" }}
                      >
                        {i18n.language === 'en' && item.name}
                        {i18n.language === 'my' && item.malay_area_name}
                        {i18n.language === 'my' && item.malay_area_name == "" && item.name}
                        {i18n.language === 'cn' && item.chinese_area_name}
                        {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
                      </Paragraph>
                    </div>
                  </div>
                ))}
              </Slider> */}
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", overflowX: "scroll" }}>
                    {area.map((item, index) => (
                      <div key={index}>
                        <div
                          key={item.area_id}
                          className={
                            tab === item.area_id ? classes._theTabActive1 : classes._theTab1
                          }
                          onClick={() => handleChangeTab(item.area_id)}
                        >
                          <Paragraph
                            bold={tab == item.area_id ? "bold" : "normal"}
                            margin="0 0 .4rem 0"
                            center
                            style={{ textTransform: "uppercase" }}
                          >
                            {i18n.language === 'en' && item.name}
                            {i18n.language === 'my' && item.malay_area_name}
                            {i18n.language === 'my' && item.malay_area_name == "" && item.name}
                            {i18n.language === 'cn' && item.chinese_area_name}
                            {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
                          </Paragraph>
                        </div>
                      </div>
                    ))}
                  </Box>
                  {/* <a className={classes.theMB} href="/gallery">
                      {t("VIEW MORE")}
                    </a> */}
                </>
              )}

              <br />
              <br />
              {/* new */}
              {/* <div style={{position: "relative", top: "5rem"}}> */}


              <motion.div
                initial={{
                  y: "100%",
                  opacity: 0,
                }}
                animate={controls1}
              >
                <Slider className={classes.root2} {...settings4}>
                  {gallery.map((e, index) => (
                    (tab === "0" || e.area_id === tab) && e.display_home == 1 ? (  // Check if tab is "0" or area_id matches tab
                      <div key={index}>
                        <div key={e.area_id}>
                          <div className={classes.theGallerySlider}>
                            <img src={e.image} />
                          </div>
                        </div>
                      </div>
                    ) : null
                  ))}
                </Slider>
              </motion.div>
              {/* <div>
            <Slider className={classes.root2} {...settings4}>
              {gallery.map((e) => (
                e.area_id === tab ? (
                  <div key={e.area_id}>
                    {e.display_home == 1 ? (
                      <div className={classes.theGallerySlider}>
                        <img src={e.image} />
                      </div>
                    ) : (
                      <></>
                    )}

                  </div>
                ) : null
              ))}
            </Slider>
          </div> */}


              {/* <Slider className={classes.root2} {...settings4}>
            {gallery.map((item) => {
              return (
                <div>
                  {tab === `${item.area_id}` &&
                    <div className={classes.theGallerySlider}>
                      <img src={item.image} />
                    </div>
                  }
                </div>
              );
            })}
          </Slider> */}

              {/* </div> */}

              {/* {tab == tab && (
            <Grid container spacing={3}>
              {gallery.map((item) => (
                <Grid item xs={12} sm={3} md={3}>
                  <div className={classes.theGallery}>
                    <img src={item.image} />
                  </div>
                </Grid>
              ))}
            </Grid>
          )} */}

            </Section>
          </Container>
          <div style={{ background: "#333335" }}>
            <Section pd="4rem 0">
              <Paragraph
                color="#FFF"
                bold="700"
                size="24px"
                margin="0 0 3rem 0"
                center
                style={{ fontFamily: "'Gotu', sans-serif" }}
              >
                {t("STONE SELECTION")}
              </Paragraph>
              {/* new */}
              <Slider className={classes.root2} {...settings5}>
                {stone.map((item, index) => {
                  return (
                    <a key={index} className={classes.theStone}>
                      <img src={item.vertical_image} />
                      <div className={classes.theOverlay}>
                        <div className={classes.imageBrand}>
                          <img src={item.pdf_file} />
                        </div>
                        <br />
                        <Paragraph color="#FFF" margin="0 0 1rem 0">
                          {i18n.language === 'en' && item.category}
                          {i18n.language === 'my' && item.malay_category_name}
                          {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                          {i18n.language === 'cn' && item.chinese_category_name}
                          {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                        </Paragraph>
                        <hr />
                        <Paragraph bold="500" color="#FFF" margin="0 0 .4rem 0">
                          {i18n.language === 'en' && item.name}
                          {i18n.language === 'my' && item.malay_name}
                          {i18n.language === 'my' && item.malay_name == "" && item.name}
                          {i18n.language === 'cn' && item.chinese_name}
                          {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                        </Paragraph>
                        <a href={`../productdetail/${item.product_id}`}>
                          <Paragraph color="#FFF" style={{ padding: "10px", position: "absolute", bottom: "0", right: "0" }}>
                            {t("VIEW MORE")}
                          </Paragraph>
                        </a>
                      </div>
                    </a>
                  );
                })}
              </Slider>

              {/* <Grid container spacing={3}>
            {stone.map((item) => {
              return (
                <Grid item xs={12} sm={3} md={3}>
                  <a className={classes.theStone}>
                    <img src={item.image} />
                    <div className={classes.theOverlay}>
                      <div className={classes.imageBrand}>
                        <img src={stone001} />
                      </div>
                      <br />
                      <Paragraph color="#FFF" margin="0 0 1rem 0">
                        {item.category}
                      </Paragraph>
                      <hr />
                      <Paragraph color="#FFF" margin="0 0 .4rem 0">
                        {item.name}
                      </Paragraph>
                      <a href={`../productdetail/${item.product_id}`}>
                        <Paragraph color="#FFF" style={{ padding: "10px", position: "absolute", bottom: "0", right: "0" }}>
                          View more
                        </Paragraph>
                      </a>
                    </div>
                  </a>
                </Grid>
              );
            })}
          </Grid> */}

              {/* <Grid container spacing={3}>
              <Grid item xs={12} sm={3} md={3}>
                <a className={classes.theStone}>
                  <img src={stone001} />
                  <div className={classes.theOverlay}>
                    <Paragraph color="#FFF" margin="0 0 1rem 0">
                      G4 Quartz Stone
                    </Paragraph>
                    <hr />
                    <Paragraph color="#FFF" margin="0 0 .4rem 0">
                      GQ9013
                    </Paragraph>
                    <Paragraph color="#FFF">Calacatta Gold</Paragraph>
                  </div>
                </a>
              </Grid>
            </Grid> */}
            </Section>
          </div>
          <Container>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={controls2}
              className={classes.productOverlay}>
              <Paragraph color="#FFF">
                {t("The")}<br />{t("Most")}<br />{t("Popular")}
              </Paragraph>
              <hr />
              <Paragraph
                className={classes.textProduct}
                color="#FFF"
                bold="700"
                margin="1.4rem 0 0 0">
                {t("FINISHED PRODUCT")}
              </Paragraph>
            </motion.div>
            <Section style={{ position: "relative", bottom: "5rem" }}>
              <Paragraph
                color="#000"
                bold="700"
                size="24px"
                margin="0 0 1rem 0"
                center
                style={{ fontFamily: "'Gotu', sans-serif" }}
              >
                {t("SHOP")}
              </Paragraph>

              <div
                style={{
                  borderBottom: "3px solid #800000",
                  width: "25%",
                  margin: "0 auto",
                }}
              />
              <br />
              <br />
              <br />
              <Slider className={classes.root4} {...settings3}>
                {/* <div className={classes.theProd}>
              <img src={table} />
              <Paragraph bold="700" size="12px" margin="0 0 .4rem 0" center>
                Dining Table
              </Paragraph>
              <Paragraph bold="500" size="12px" center>
                Start from: RM1099.00
              </Paragraph>
            </div>
            <div className={classes.theProd}>
              <img src={table} />
              <Paragraph bold="700" size="12px" margin="0 0 .4rem 0" center>
                Dining Table
              </Paragraph>
              <Paragraph bold="500" size="12px" center>
                Start from: RM1099.00
              </Paragraph>
            </div>
            <div className={classes.theProd}>
              <img src={table} />
              <Paragraph bold="700" size="12px" margin="0 0 .4rem 0" center>
                Dining Table
              </Paragraph>
              <Paragraph bold="500" size="12px" center>
                Start from: RM1099.00
              </Paragraph>
            </div>
            <div className={classes.theProd}>
              <img src={table} />
              <Paragraph bold="700" size="12px" margin="0 0 .4rem 0" center>
                Dining Table
              </Paragraph>
              <Paragraph bold="500" size="12px" center>
                Start from: RM1099.00
              </Paragraph>
            </div> */}
                {randomShop.map((item, index) => (
                  <>
                    <div key={index} className={classes.theShopProd}>
                      <div style={{ position: "relative" }}>
                        <img style={{ width: "70%", height: "150px", objectFit: "contain" }} src={item.image} />
                      </div>
                      <Paragraph bold="700" size="12px" margin="0 0 .4rem 0" center>
                        {i18n.language === 'en' && item.name}
                        {i18n.language === 'my' && item.malay_name}
                        {i18n.language === 'my' && item.malay_name == "" && item.name}
                        {i18n.language === 'cn' && item.chinese_name}
                        {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                      </Paragraph>
                      {businessType == 1 && hide_price == 0 ? (
                        <Paragraph bold="500" size="12px" center>
                          RM{item.business_price}
                        </Paragraph>
                      ) : businessType == 1 && hide_price == 1 ? (
                        <Paragraph bold="500" size="12px" center>
                          {Hidden}
                        </Paragraph>
                      ) : (
                        <></>
                      )}

                      {businessType == 2 && hide_price == 0 ? (
                        <Paragraph bold="500" size="12px" center>
                          RM{item.individual_price}
                        </Paragraph>
                      ) : businessType == 2 && hide_price == 1 ? (
                        <Paragraph bold="500" size="12px" center>
                          {Hidden}
                        </Paragraph>
                      ) : (
                        <></>
                      )}


                      {/* <Paragraph bold="500" size="12px" center>
                  Start from: RM {item.individual_price}
                </Paragraph> */}
                    </div>
                  </>
                ))}
              </Slider>
              <br /><br />
              <a href="/shop">
                <Button
                  label={t("VIEW ALL")}
                  color="#FFF"
                  bg="#333335"
                  br="10px"
                  style={{ width: "100px", margin: "auto" }}
                />
              </a>
            </Section>
          </Container>
          <div className={classes.theBG}>
            <Container>
              <Section pd="4rem 0">
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={7}>
                    <Paragraph margin="0 0 1.5rem 0" size="24px" color="#FFF">
                      {t("We Like To Hear From You")}
                    </Paragraph>
                    <FormInputNoLabel
                      placeholder={t("Name")}
                      bg="#FFF"
                      name="name"
                      onChange={handleChange}
                      value={profileData.name}
                    />
                    <FormInputNoLabel
                      placeholder={t("Email")}
                      bg="#FFF"
                      name="email"
                      onChange={handleChange}
                      value={profileData.email}
                    />
                    <FormInputNoLabel
                      placeholder={t("Contact Number")}
                      bg="#FFF"
                      type="number"
                      name="contact"
                      onChange={handleChange}
                      value={profileData.contact}
                    />
                    <FormInputNoLabel
                      placeholder={t("Message")}
                      multiline
                      rows={4}
                      bg="#FFF"
                      name="message"
                      onChange={handleChange}
                    />
                    <Button
                      label={t("SUBMIT")}
                      color="#FFF"
                      bg="#333335"
                      br="10px"
                      onClick={() => handleContactUs()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.thePersonStar}>
                        <img src={personstar} />
                      </div>
                      <Paragraph color="#FFF">
                        {t("A Team With More Than 25 Years Experience In The Stone Industry.")}
                      </Paragraph>
                    </div>
                  </Grid>
                </Grid>
              </Section>
            </Container>
          </div>
          <div style={{ background: "#333335" }}>
            <Section pd="0">
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div className={classes.theCustom}>
                    <Paragraph
                      bold="700"
                      color="#FFF"
                      size="34px"
                      margin="0 0 1rem 0"
                    >
                      {t("Let's Chat With Us.")}
                    </Paragraph>
                    <Paragraph color="#FFF">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.{" "}
                    </Paragraph>
                    <br />
                    <br />
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <Mail style={{ color: "#FFF" }} />
                      <Paragraph color="#FFF" margin="0 0 0 1rem">
                        ottoglobalapexsurfacing@email.com
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <Phone style={{ color: "#FFF" }} />
                      <div>
                        <div style={{ display: "flex" }}>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            MY
                          </Paragraph>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            +607-6991449 / +6010-9301449
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            SG
                          </Paragraph>
                          <Paragraph color="#FFF" margin="0 0 0 1rem">
                            +65-63658781
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <Storefront style={{ color: "#FFF" }} />
                      <div>
                        <div style={{ display: "flex" }}>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            Mon - Fri
                          </Paragraph>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            8:30 am - 6:00 pm
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            Sat
                          </Paragraph>
                          <Paragraph color="#FFF" margin="0 0 0 1rem">
                            8.30 am - 1:00 pm
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                            Sun
                          </Paragraph>
                          <Paragraph color="#FFF" margin="0 0 0 1rem">
                            Closed
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theMap}>
                    <img src={map} />
                  </div>
                </Grid>
              </Grid>
            </Section>
          </div>
          <Footer />
        </>
      }
    </React.Fragment >
  );
}
