import React from "react";
import Slider from "react-slick";
import useStyles, { IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import table from "../../assets/images/table.png";
import desc002 from "../../assets/images/desc002.png";
import icon005 from "../../assets/images/icon005.svg";
import desc001 from "../../assets/images/desc001.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  Download,
  Remove,
} from "@mui/icons-material";
import { json, useNavigate, useParams } from "react-router-dom";
import { get_product_detail, get_related_shops, get_user } from "../../API/API";
import useCart from "../../hooks/useCart";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function ShopDetail() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { product_id, user_id } = useParams();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [count, setCount] = React.useState(1);
  const { addProduct, clearCart, getProductQuantityInCart } = useCart();


  const handleChangeQty = (new_qty, index) => {
    if (new_qty < 1) {
      return;
    }
    setCount(new_qty);
  };

  const [qtyCount, setQtyCount] = React.useState(0)

  const handleChangeDecrease = (new_qty) => {



    if (qtyCount < 1) {
      return
    }
    setQtyCount(qtyCount - 1)
  }

  const handleIncreaseQuantity = () => {
    const maxStock = shopData.stock;
    const currentQuantity = qtyCount || 0;

    console.log("stcok", maxStock);
    console.log("qty", currentQuantity)


    const productId = shopData.product_id;
    const quantityInCart = getProductQuantityInCart(productId);

    console.log("product", productId)
    console.log("quantity", quantityInCart)

    const remainingQuantity = maxStock - quantityInCart - currentQuantity;

    if (remainingQuantity <= 0) {
      alert(t("Maximum stock reached for this product"))
      return;
    }

    const incrementAmount = Math.min(1, remainingQuantity);
    // const quantity = parseFloat(currentQuantity + incrementAmount);
    // qtyCount = parseFloat(quantity)

    // console.log("nani", qtyCount)

    // if (qtyCount >= productData.stock) {
    //   return; // Stop increment if quantity reaches maxStock
    // }
  
    setQtyCount(qtyCount + 1);
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // API

  const [shopData, setShopData] = React.useState([]);
  const [typeID, setTypeID] = React.useState("");
  const [areaID, setAreaID] = React.useState("");
  const [user, setUser] = React.useState([]);
  // const [newsLatest, setNewsLatest] = React.useState([]);

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");
          localStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("isLoggedIn");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  const { hide_price } = user;

  // const [userType, setUserType] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = window.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");
  // }, [])

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);

  const getShopData = () => {
    get_product_detail({ product_id: product_id }).then((json) => {
      setShopData(json.data);
      setTypeID(json.data.type_id);
      setAreaID(json.data.area_id);
    });
  };

  console.log("this is type id", typeID)
  console.log("this is area id", areaID)

  // const getNewsLatest = () => {
  //   get_news_latest().then((json) => {
  //     setNewsLatest(json.data);
  //   });
  // };

  React.useEffect(() => {
    getShopData();
    getRelatedShops();
    if (isLoggedIn) {
      getUser();
    }
    // getNewsLatest();
  }, [
    typeID,
    areaID,
    isLoggedIn
  ]);

  // ADD TO CART


  const handleAddToCart = (shopData) => {
    if (shopData.stock == null || shopData.stock <= 0) {
      alert(t('outOfStockShop', { shopData: shopData.name }))
      // alert(`${productData.name} Is Out Of Stock`)
      return;
    }
    else if (qtyCount == 0) {
      alert(t("Please Choose the Quantity of Product"));
      return;
    }
    let prodList = {
      name: shopData.name,
      single_price: businessType == 2 ? shopData.individual_price : shopData.business_price,
      // single_price: shopData.business_price,
      // price: shopData.business_price,
      // quantity: count,
      image: shopData.image,
      product_id: shopData.product_id,
      category: shopData.category,
      surfaces: shopData.surfaces,
      brand: shopData.brand,
      quantity: qtyCount,
    };
    console.log(prodList);
    const addedSuccessfully = addProduct(prodList);
    if (addedSuccessfully) {
      toast.success(t('productAdded', { qtyCount: qtyCount }))
      setQtyCount(0)
    }

    // addProduct(prodList);
    // toast.success(t('productAdded', { qtyCount: qtyCount }))
    // toast.success(`${qtyCount} product added to cart`)
    // let array = [];
    // array.push(prodList);
    // window.localStorage.setItem("bundleList", JSON.stringify(array));
  };

  const [relatedShops, setRelatedShops] = React.useState([]);

  const getRelatedShops = () => {
    get_related_shops({
      type_id: typeID,
      area_id: areaID,
    }).then((json) => {
      setRelatedShops(json.data)
    })
  }

  console.log(businessType)
  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="8rem 0 0 0">
          <a style={{ display: "flex", alignItems: "center" }} href="/shop">
            <ArrowBackIos style={{ fontSize: "14px" }} />
            <Paragraph size="14px">{t("Back")}</Paragraph>
          </a>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theProdIMG}>
                <img src={shopData.image} />
              </div>
              <br />
              {/* <div className={classes.theGalleryList}>
                <div className={classes.theGalleryx}>
                  <img src={table} />
                </div>
                <div className={classes.theGalleryx}>
                  <img src={table} />
                </div>
                <div className={classes.theGalleryx}>
                  <img src={table} />
                </div>
              </div>
              <br /> */}
              {/* <Button label="ORDER SAMPLE" color="#FFF" bg="#353535" /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paragraph color="#353535" bold="100" margin="0 0 .2rem 0">
                {i18n.language === 'en' && shopData.area}
                {i18n.language === 'my' && shopData.malay_area_name}
                {i18n.language === 'my' && shopData.malay_area_name == "" && shopData.area}
                {i18n.language === 'cn' && shopData.chinese_area_name}
                {i18n.language === 'cn' && shopData.chinese_area_name == "" && shopData.area}
              </Paragraph>
              <Paragraph
                color="#353535"
                bold="700"
                size="34px"
                margin="0 0 .6rem 0"
              >
                {i18n.language === 'en' && shopData.name}
                {i18n.language === 'my' && shopData.malay_name}
                {i18n.language === 'my' && shopData.malay_name == "" && shopData.name}
                {i18n.language === 'cn' && shopData.chinese_name}
                {i18n.language === 'cn' && shopData.chinese_name == "" && shopData.name}
              </Paragraph>
              {businessType == 1 && hide_price == 0 && shopData.discount_price != "0.00" ? (
                <Paragraph
                  color="#800000"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  RM {shopData.discount_price}
                </Paragraph>
              ) : businessType == 1 && hide_price == 0 && shopData.discount_price == "0.00" ? (
                <Paragraph
                  color="#800000"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  RM {shopData.business_price}
                </Paragraph>
              ) : businessType == 1 && hide_price == 1 ? (
                <div>
                  {Hidden}
                </div>
              ) : (
                <></>
              )}

              {businessType == 2 && hide_price == 0 && shopData.discount_price != "0.00" ? (
                <Paragraph
                  color="#800000"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  RM {shopData.discount_price}
                </Paragraph>
              ) : businessType == 2 && hide_price == 0 && shopData.discount_price == "0.00" ? (
                <Paragraph
                  color="#800000"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  RM {shopData.individual_price}
                </Paragraph>
              ) : businessType == 2 && hide_price == 1 ? (
                <div>
                  {Hidden}
                </div>
              ) : (
                <></>
              )}




              <hr />
              <br />
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <Paragraph color="#353535" margin="0 0 .4rem 0">
                  {t("Product Category")}
                </Paragraph>
                <Paragraph
                  color="#353535"
                  bold="600"
                  style={{ textIndent: "40px" }}
                >
                  {i18n.language === 'en' && shopData.category}
                  {i18n.language === 'my' && shopData.malay_category_name}
                  {i18n.language === 'my' && shopData.malay_category_name == "" && shopData.category}
                  {i18n.language === 'cn' && shopData.chinese_category_name}
                  {i18n.language === 'cn' && shopData.chinese_category_name == "" && shopData.category}
                </Paragraph>
              </div>
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <Paragraph color="#353535" margin="0 0 .4rem 0">
                  {t("Surfaces")}
                </Paragraph>
                <Paragraph
                  color="#353535"
                  bold="600"
                  style={{ textIndent: "115px" }}
                >
                  {i18n.language === 'en' && shopData.surfaces}
                  {i18n.language === 'my' && shopData.malay_surfaces_name}
                  {i18n.language === 'my' && shopData.malay_surfaces_name == "" && shopData.surfaces}
                  {i18n.language === 'cn' && shopData.chinese_surfaces_name}
                  {i18n.language === 'cn' && shopData.chinese_surfaces_name == "" && shopData.surfaces}
                </Paragraph>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: "1rem",
                  alignItems: "center",
                }}
              >
                <Paragraph
                  color="#353535"
                  bold="600"
                // style={{ textIndent: "20px" }}
                >
                  {t("Colour")}
                </Paragraph>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    background: shopData.colour,
                    marginLeft: "115px",
                  }}
                ></div>
              </div>
              <br />
              <hr />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >

                {businessType == 1 && hide_price == 0 ? (
                  <div className={classes.theFlexQuantity}>
                    <div className={classes.theIncrement}>
                      <IncrementAction2
                        margin="0 1rem 0 0"
                        onClick={() => handleChangeDecrease()}
                      // onClick={()=>setQtyCount(qtyCount - 1)}
                      >
                        <Remove
                          className={classes.theButtonStyle2}
                        />
                      </IncrementAction2>

                      <div className={classes.theBox__}>
                        <p className={classes.theCount}>
                          {qtyCount}
                        </p>
                      </div>

                      <IncrementAction
                        margin="0 0 0 1rem"
                        // onClick={() => {
                        //   if (qtyCount < shopData.stock) {
                        //     setQtyCount(qtyCount + 1);
                        //   }
                        // }}
                        onClick={() => handleIncreaseQuantity()}
                      >
                        <Add className={classes.theButtonStyle} />
                      </IncrementAction>
                    </div>
                  </div>
                ) : businessType == 1 && hide_price == 1 ? (
                  <div>
                    {Hidden}
                  </div>
                ) : (
                  <></>
                )}

                {businessType == 2 && hide_price == 0 ? (
                  <div className={classes.theFlexQuantity}>
                    <div className={classes.theIncrement}>
                      <IncrementAction2
                        margin="0 1rem 0 0"
                        onClick={() => handleChangeDecrease()}
                      // onClick={()=>setQtyCount(qtyCount - 1)}
                      >
                        <Remove
                          className={classes.theButtonStyle2}
                        />
                      </IncrementAction2>

                      <div className={classes.theBox__}>
                        <p className={classes.theCount}>
                          {qtyCount}
                        </p>
                      </div>

                      <IncrementAction
                        margin="0 0 0 1rem"
                        // onClick={() => {
                        //   if (qtyCount < shopData.stock) {
                        //     setQtyCount(qtyCount + 1);
                        //   }
                        // }}
                        onClick={() => handleIncreaseQuantity()}
                      >
                        <Add className={classes.theButtonStyle} />
                      </IncrementAction>
                    </div>
                  </div>
                ) : businessType == 2 && hide_price == 1 ? (
                  <div>
                    {Hidden}
                  </div>
                ) : (
                  <></>
                )}


                {/* <div className={classes.theFlexQuantity}>
                  <div className={classes.theIncrement}>
                    <IncrementAction2
                      margin="0 1rem 0 0"
                      onClick={() => handleChangeQty(count - 1)}
                    // onClick={() => de_quantity(index)}
                    >
                      <Remove className={classes.theButtonStyle2} />
                    </IncrementAction2>

                    {count == 0 ? (
                      <div className={classes.theBox}>
                        <p className={classes.theCount}>0</p>
                      </div>
                    ) : (
                      <div className={classes.theBox}>
                        <p className={classes.theCount}>{count}</p>
                      </div>
                    )}

                    <IncrementAction
                      margin="0 0 0 1rem"
                      onClick={() => setCount(count + 1)}
                    // onClick={() => add_quantity(index)}
                    >
                      <Add className={classes.theButtonStyle} />
                    </IncrementAction>
                  </div>
                </div> */}

                {businessType == 1 && hide_price == 0 ? (
                  <Button
                    label={t("ADD TO CART")}
                    color="#FFF"
                    bg="#353535"
                    br="8px"
                    pd=".8rem 1.5rem"
                    onClick={() => handleAddToCart(shopData)}
                  />
                ) : businessType == 1 && hide_price == 1 ? (
                  <div>
                    {Hidden}
                  </div>
                ) : (
                  <></>
                )}
                {/* <ToastContainer
                  position='top-right'
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                  theme='light'
                /> */}

                {businessType == 2 && hide_price == 0 ? (
                  <Button
                    label={t("ADD TO CART")}
                    color="#FFF"
                    bg="#353535"
                    br="8px"
                    pd=".8rem 1.5rem"
                    onClick={() => handleAddToCart(shopData)}
                  />
                ) : businessType == 2 && hide_price == 1 ? (
                  <div>
                    {Hidden}
                  </div>
                ) : (
                  <></>
                )}
                {/* <ToastContainer
                  position='top-right'
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                  theme='light'
                /> */}

                {/* <Button
                  label="ADD TO CART"
                  color="#FFF"
                  bg="#353535"
                  br="8px"
                  pd=".8rem 1.5rem"
                  onClick={() => handleAddToCart()}
                /> */}
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <Paragraph
            size="24px"
            color="#353535"
            center
            margin="0 0 .4rem 0"
            bold="700"
            style={{ fontFamily: "'Gotu', sans-serif" }}
          >
            {t("PRODUCT DESCRIPTION")}
          </Paragraph>
          <div
            style={{
              border: "1px solid #353535",
              width: "34%",
              margin: "0 auto",
            }}
          />
          <br />
          <div className={classes.productDetail}>
            <div className={classes.theProdIMG}>
              <img src={desc001} />
            </div>
          </div>

          {/* <div className={classes.theProdIMG}>
            <img src={desc002} />
          </div> */}
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: shopData.description,
            }}
          />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paragraph
              size="24px"
              color="#353535"
              margin="0 0 1rem 0"
              bold="700"
              style={{ fontFamily: "'Gotu', sans-serif" }}
            >
              {t("Related Products")}
            </Paragraph>
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                background: "rgb(51, 51, 53)",
                margin: "0px 0px 0px 1rem",
              }}
            ></div>
          </div>

          <Grid style={{ padding: "0 0 4rem 0" }} container spacing={3}>
            {relatedShops.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <a href={`../shopdetail/${item.product_id}`}>
                  <Card style={{ padding: "1rem" }}>
                    <div className={classes.theGallery}>
                      <img src={table} />
                    </div>
                    <Paragraph color="#333335" size="12px" bold="100">
                      {i18n.language === 'en' && item.category}
                      {i18n.language === 'my' && item.malay_category_name}
                      {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                      {i18n.language === 'cn' && item.chinese_category_name}
                      {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                    </Paragraph>
                    <Paragraph
                      color="#333335"
                      bold="600"
                      margin="0 0 .4rem 0"
                    >
                      {i18n.language === 'en' && item.name}
                      {i18n.language === 'my' && item.malay_name}
                      {i18n.language === 'my' && item.malay_name == "" && item.name}
                      {i18n.language === 'cn' && item.chinese_name}
                      {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                    </Paragraph>
                  </Card>
                </a>
              </Grid>
            ))}
          </Grid>

          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ padding: "1rem" }}>
                <div className={classes.theGallery}>
                  <img src={shopData.image} />
                </div>
                <Paragraph color="#333335" size="12px" bold="100">
                  Dining Room
                </Paragraph>
                <Paragraph
                  color="#333335"
                  bold="600"
                  margin="0 0 .4rem 0"
                >
                  {shopData.name}
                </Paragraph>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ padding: "1rem" }}>
                <div className={classes.theGallery}>
                  <img src={shopData.image} />
                </div>
                <Paragraph color="#333335" size="12px" bold="100">
                  Dining Room
                </Paragraph>
                <Paragraph
                  color="#333335"
                  bold="600"
                  margin="0 0 .4rem 0"
                >
                  {shopData.name}
                </Paragraph>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ padding: "1rem" }}>
                <div className={classes.theGallery}>
                  <img src={shopData.image} />
                </div>
                <Paragraph color="#333335" size="12px" bold="100">
                  Dining Room
                </Paragraph>
                <Paragraph
                  color="#333335"
                  bold="600"
                  margin="0 0 .4rem 0"
                >
                  {shopData.name}
                </Paragraph>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ padding: "1rem" }}>
                <div className={classes.theGallery}>
                  <img src={shopData.image} />
                </div>
                <Paragraph color="#333335" size="12px" bold="100">
                  Dining Room
                </Paragraph>
                <Paragraph
                  color="#333335"
                  bold="600"
                  margin="0 0 .4rem 0"
                >
                  {shopData.name}
                </Paragraph>
              </Card>
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.theGallery}>
                <img src={table} />
              </div>
              <Paragraph
                color="#333335"
                size="12px"
                bold="100"
                margin="0 0 .4rem 0"
                center
              >
                Silestone
              </Paragraph>
              <Paragraph color="#333335" bold="600" margin="0 0 1rem 0" center>
                White Marble Dining Table
              </Paragraph>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={classes.theIcon}>
                  <img src={icon005} />
                </div>
                <Paragraph>Polished</Paragraph>
              </div>
              <hr />
              <br />
              <Button
                label="ADD TO CART"
                color="#FFF"
                bg="#353535"
                br="8px"
                pd=".4rem 1rem"
              />
            </Grid>
          </Grid>
          <br />
          <br /> */}
        </Section>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
