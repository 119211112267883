import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  theBG_: {
    position: "relative",
    padding: "2rem",
    background: "#402313",
  },

  theLogo: {
    position: "relative",
    width: "50%",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },

  theBox_: {
    position: "relative",
    background: "#fff",
    padding: "1rem",
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
  },

  theIMG: {
    position: "relative",
    width: "20%",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "20%",
    }),
  },

  theWidth: {
    width: "100vw",

    ...md("sm", {
      width: "50vw",
    }),

    ...md("md", {
      width: "100%",
    }),
  },

  theWidth2: {
    width: "150px",

    ...md("sm", {
      width: "unset",
    }),

    ...md("md", {
      width: "150px",
    }),
  },

  theFlexx: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "flex-end",
    }),
  },

  theRight: {
    marginBottom: "1rem",
    ...md("sm", {
      marginRight: "1rem",
      marginBottom: 0,
    }),
  },

  theFlex_: {
    display: "block",
    marginBottom: "1rem",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0",
      // paddingBottom: "1rem",
      // borderBottom: "1px solid #d7d7d7",
    }),
  },

  theWidths: {
    width: "100%",

    ...md("sm", {
      width: "80%",
    }),

    ...md("md", {
      width: "60%",
    }),
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: "1rem 0",
    justifyContent: "center",
    // border: "1px solid #BA2025",
    // width: '30%'
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "75%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },
};

export default makeStyles(config);
