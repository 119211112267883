import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import logo from "../../assets/images/logo.png";
import malaysia from "../../assets/images/malaysia.png";


import { ToastContainer, toast } from "react-toastify";


import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  NavbarUpper,
  Badge,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
  ShoppingBagOutlined,  
  LoginOutlined,
  GroupAdd,
  ShoppingCartOutlined,
  Language,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
// import useUser from "../../hooks/useUser";
import bannerregister from "../../assets/images/bannerregister.png";
import { get_upload_file, get_user, login, register } from "../../API/API";
import useCart from "../../hooks/useCart";
import { I18nextProvider, useTranslation } from "react-i18next";
import FormInputSelect from "../FormInputSelect/FormInputSelect";

const Navbar = () => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const { clearCart, getCart } = useCart()
  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);
  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  // const {
  //   set_user,
  //   get_user
  // } = useUser();
  // console.log(get_user() !== null)
  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const [openLogin, setOpenLogin] = React.useState(false);

  const [openSignUp, setOpenSignUp] = React.useState(false);

  // LOGIN & SIGN UP MODAL

  const handleOpenLogin = () => {
    setOpenLogin(true);
    setOpenSignUp(false);
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenLogin(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [languageAnchor, setLanguageAnchor] = React.useState(null);

  const openLanguage = Boolean(languageAnchor);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenu = (e) => {
    setLanguageAnchor(e.currentTarget);
  }

  const handleCloseLanguage = () => {
    setLanguageAnchor(null);
  }

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  // HANDLE LOGOUT

  // const handleLogout = () => {
  //   clearCart()
  //   window.localStorage.removeItem("token");
  //   window.localStorage.removeItem("business_type");

  //   history("/");
  //   window.location.reload();

  // };



  const handleLogout = () => {
    clearCart();
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("business_type");
    localStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("isLoggedIn")
    history("/");
    window.location.reload();
  };

  // API AUTH

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [registerBusiness, setRegisterBusiness] = React.useState({
    name: "",
    email: "",
    contact: "",
    password: "",
    password2: "",
    company_name: "",
    company_ssm: "",
    user_type_id: 1,
  });

  const [registerIndividual, setRegisterIndividual] = React.useState({
    name: "",
    email: "",
    contact: "",
    password: "",
    password2: "",
    user_type_id: 2,
  });

  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setRegisterBusiness({
      ...registerBusiness,
      [e.target.name]: e.target.value,
    });
    setRegisterIndividual({
      ...registerIndividual,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeUpload = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);
    var res = await get_upload_file(formData);
    if (res) {
      setRegisterBusiness({
        ...registerBusiness,
        company_ssm: res.data.data,
      });
    }
  };

  const handleRegisterBusiness = () => {
    
    console.log(registerBusiness);
    if (
      registerBusiness.email == "" ||
      registerBusiness.company_name == "" ||
      registerBusiness.name == "" ||
      registerBusiness.contact == "" ||
      registerBusiness.password == "" ||
      registerBusiness.password2 == "" ||
      registerBusiness.company_ssm == '' 
    ) {
      alert(t("All Field Required"));
      return;
    }

    register(registerBusiness).then((json) => {
      if (json.status) {
        localStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("business_type", registerBusiness.user_type_id);
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.location.reload();
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  const handleRegisterIndividual = () => {
    if (
      registerIndividual.email.length == "" ||
      registerIndividual.name.length == "" ||
      registerIndividual.contact.length == "" ||
      registerIndividual.password.length == "" ||
      registerIndividual.password2.length == ""
    ) {
      alert(t("All Field Required"));
    }

    register(registerIndividual).then((json) => {
      if (json.status) {
        localStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("business_type", registerIndividual.user_type_id);
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.location.reload();
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  const handleLogin = () => {
    if (loginData.email.length == "" || loginData.password.length == "") {
      alert(t("All Field Required"));
    }

    login(loginData).then((json) => {
      if (json.status) {
        alert(t("Welcome Back"));
        localStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem("isLoggedIn", "true");
        window.location.reload("/?page_id=1");
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    })
      .catch((error) => {
        console.error("handleLogin error:", error);
        alert(t("An error occurred during login"));
      });
  };

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);


  // CHANGE LANGAUGE

  const { i18n } = useTranslation();
  const handleChangeLang = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <NavbarContainer>
        {/* <div className={classes.theHead}>
          <Container>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Paragraph color="#FFF" size="14px">
                The latest 2022 catalog is here.{" "}
                <a style={{ color: "#FFF", textDecoration: "underline" }}>
                  {" "}
                  Download now
                </a>
              </Paragraph>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Language
                  sx={{
                    color: "#FFF",
                  }}
                />
                <Paragraph style={{ marginLeft: ".5rem" }} color="#FFF" size="12px">LANGUAGE</Paragraph>
              </div>
            </div>
          </Container>
        </div> */}
        <Container maxWidth="xxl">
          <AppBar position="fixed" style={{ background: "#fff" }} className={classes.appBar}>
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#402313" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {/* <NavbarIconLink onClick={handleDrawerToggle("right", true)}>
                      <ShapeCircle margin={"0 0 0 1rem"}>
                        <Icon src={shoppingcart} />
                      </ShapeCircle>
                      {cart != undefined ? (
                        <Badge>{cart ? cart.length : null}</Badge>
                      ) : (
                        <></>
                      )}
                    </NavbarIconLink> */}
                    {/* {token ? (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#BA2025" }} />
                      </NavbarIconLink>
                    ) : (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#BA2025" }} />
                      </NavbarIconLink>
                    )} */}

                    {token ? (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingCartOutlined style={{ color: "#353535" }} />
                          {cart != undefined ? (
                            <p>{cart ? cart.length : null}</p>
                          ) : (
                            <></>
                          )}
                        </NavbarIconLink>

                        <NavbarIconLink onClick={handleMenu}>
                          <AccountCircleRounded style={{ color: "#353535" }} />
                        </NavbarIconLink>
                        <NavbarIconLink style={{ marginLeft: "1.5rem" }}>
                          <Language
                            style={{ color: "#000" }}
                          />
                          <FormInputSelect
                            mb="0"
                            border="unset"
                            style={{
                              color: "#000",
                            }}
                            onChange={handleChangeLang}
                            value={i18n.language}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            <MenuItem value="en">EN</MenuItem>
                            <MenuItem value="my">MY</MenuItem>
                            <MenuItem value="cn">CN</MenuItem>
                          </FormInputSelect>
                        </NavbarIconLink>
                      </>
                    ) : (
                      <>
                        <NavbarIconLink onClick={handleMenu}>
                          <AccountCircleRounded style={{ color: "#353535" }} />
                        </NavbarIconLink>

                        <NavbarIconLink style={{ marginLeft: "1.5rem" }}>
                          <Language
                            style={{ color: "#000" }}
                          />
                          <FormInputSelect
                            mb="0"
                            border="unset"
                            style={{
                              color: "#000",
                            }}
                            onChange={handleChangeLang}
                            value={i18n.language}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            <MenuItem value="en">EN</MenuItem>
                            <MenuItem value="my">MY</MenuItem>
                            <MenuItem value="cn">CN</MenuItem>
                          </FormInputSelect>
                        </NavbarIconLink>
                      </>
                    )}

                    {/* <NavbarIconLink
                      href="/cart"
                      style={{ marginRight: "1.5rem" }}
                    >
                      <ShoppingCartOutlined style={{ color: "#353535" }} />
                    </NavbarIconLink>

                    <NavbarIconLink onClick={handleMenu}>
                      <AccountCircleRounded style={{ color: "#353535" }} />
                    </NavbarIconLink> */}
                  </NavbarIconMobileList>

                  <SidebarMenu
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    {/* <NavbarUpper style={{background: "#000"}}>
                      <div>
                        <Paragraph>The latest 2022 catalog is here. <a> Download now</a></Paragraph>
                      </div>
                    </NavbarUpper> */}
                    <Container>
                      <NavbarLower>
                        <NavbarLowerList>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              margin: "0 auto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                // marginLeft: "auto",
                                // width: "50%",
                              }}
                            >
                              <NavbarLogo>
                                <a href="/">
                                  <img src={logo} />
                                </a>
                              </NavbarLogo>
                              <NavbarLink color="#D5AE35"
                                href="/"
                                className={classes.navLink}
                              // href="/?page_id=1"
                              >
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === ""
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === ""
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("HOME")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/about">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "about"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "about"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("ABOUT US")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/product">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "product"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "product"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("PRODUCT")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/gallery">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "gallery"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "gallery"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("GALLERY")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/shop">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "shop"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "shop"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("SHOP")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/contact">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "contact"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "contact"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("CONTACT")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                              <NavbarLink
                                className={classes.navLink}
                                color="#000" href="/warranty">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "warranty"
                                        ? "#000"
                                        : "#000"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "warranty"
                                        ? "700"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    {t("WARRANTY")}
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                            </div>

                            <div style={{ display: "flex" }}>
                              {token ? (
                                <>
                                  <NavbarIconLink
                                    href="/cart"
                                    style={{ marginRight: "1.5rem" }}
                                  >
                                    <ShoppingCartOutlined
                                      style={{ color: "#353535" }}
                                    />
                                    {cart != undefined ? (
                                      <p>{cart ? cart.length : null}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </NavbarIconLink>

                                  <NavbarIconLink onClick={handleMenu}>
                                    <AccountCircleRounded
                                      style={{ color: "#353535" }}
                                    />
                                  </NavbarIconLink>
                                  <NavbarIconLink style={{ marginLeft: "1.5rem" }}>
                                    <Language
                                      style={{ color: "#000" }}
                                    />
                                    <FormInputSelect
                                      mb="0"
                                      border="unset"
                                      style={{
                                        color: "#000",
                                      }}
                                      onChange={handleChangeLang}
                                      value={i18n.language}
                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}
                                    >
                                      <MenuItem value="en">EN</MenuItem>
                                      <MenuItem value="my">MY</MenuItem>
                                      <MenuItem value="cn">CN</MenuItem>
                                    </FormInputSelect>
                                  </NavbarIconLink>
                                </>
                              ) : (
                                <>
                                  <a
                                    onClick={() => handleOpenLogin()}
                                    style={{
                                      background: "#000",
                                      padding: ".4rem .8rem",
                                      color: "#000",
                                      borderRadius: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LoginOutlined
                                      style={{
                                        color: "#FFF",
                                        fontSize: "20px",
                                      }}
                                    />
                                    <Paragraph
                                      margin="0 0 0 1rem"
                                      color="#FFF"
                                      bold="500"
                                      size="14px"
                                    >
                                      {t("LOGIN")}
                                    </Paragraph>
                                  </a>
                                  <a
                                    onClick={() => handleOpenSignUp()}
                                    style={{
                                      border: "1px solid #000",
                                      padding: ".4rem .8rem",
                                      color: "#000",
                                      borderRadius: "10px",
                                      display: "flex",
                                      marginLeft: "1rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <GroupAdd style={{ fontSize: "24px" }} />
                                    <Paragraph
                                      margin="0 0 0 1rem"
                                      bold="500"
                                      size="14px"
                                    >
                                      {t("REGISTER")}
                                    </Paragraph>
                                  </a>
                                  <NavbarIconLink style={{ marginLeft: "1.5rem" }}>
                                    <Language
                                      style={{ color: "#000" }}
                                    />
                                    <FormInputSelect
                                      mb="0"
                                      border="unset"
                                      style={{
                                        color: "#000",
                                      }}
                                      onChange={handleChangeLang}
                                      value={i18n.language}
                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}
                                    >
                                      <MenuItem value="en">EN</MenuItem>
                                      <MenuItem value="my">MY</MenuItem>
                                      <MenuItem value="cn">CN</MenuItem>
                                    </FormInputSelect>
                                  </NavbarIconLink>
                                </>
                              )}
                            </div>
                          </div>
                        </NavbarLowerList>
                      </NavbarLower>
                    </Container>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {token ? (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label={t("Account")}
                            bg="#353535"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            href="/account"
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label={t("Sign Out")}
                            bg="#353535"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleLogout()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label={t("Login")}
                            bg="#353535"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenLogin()}
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label={t("Register")}
                            bg="#353535"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleOpenSignUp()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  )}
                </Menu>
              </>
              <>
                {/* <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={languageAnchor}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={Boolean(languageAnchor)}
                  onClose={handleCloseLanguage}
                >
                  <MenuItem>
                    <div>
                      <Button
                        label="English"
                        onClick={() => handleChangeLang("en")}
                        value="en"
                        bg="#353535"
                        color="#fff"
                        bold="500"
                        br="4px"
                        pd=".2rem 1rem"
                        size="12px"
                        style={{ marginBottom: ".6rem" }}
                      />
                      <Button
                        label="Malay"
                        onClick={() => handleChangeLang("my")}
                        value="my"
                        bg="#353535"
                        color="#fff"
                        bold="500"
                        br="4px"
                        pd=".2rem 1rem"
                        size="12px"
                        style={{ marginBottom: ".6rem" }}
                      />
                      <Button
                        label="Chinese"
                        onClick={() => handleChangeLang("cn")}
                        value="cn"
                        bg="#353535"
                        color="#fff"
                        bold="500"
                        br="4px"
                        pd=".2rem 1rem"
                        size="12px"
                      />
                    </div>
                  </MenuItem>
                </Menu> */}
              </>
            </Toolbar>
          </AppBar>
        </Container>
        <Dialog
          open={openLogin}
          onClose={() => setOpenLogin(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenLogin(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <NavbarLogo_>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </NavbarLogo_>
              <br />
              <Paragraph center size="24px">
                {t("Sign in with your email")}
              </Paragraph>
              <FormInput
                label={t("Email Address")}
                placeholder={t("Email Address")}
                border="1px solid #707070"
                name="email"
                onChange={handleChange}
              />
              <FormInput
                label={t("Password")}
                placeholder={t("Password")}
                border="1px solid #707070"
                name="password"
                type="password"
                onChange={handleChange}
              />
              {/* <a
                style={{
                  color: "#353535",
                  textDecoration: "underline",
                  display: "block",
                }}
              >
                Forget Password?
              </a> */}
              <br />
              <Button
                label={t("Login")}
                color="#fff"
                bg="#353535"
                br="6px"
                onClick={() => handleLogin()}
              />
              <br />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Paragraph>
                  {t("Don't Have Account?")}{" "}
                  <a
                    style={{ color: "#353535", textDecoration: "underline" }}
                    onClick={() => handleOpenSignUp()}
                  >
                    {t("Sign Up")}
                  </a>
                </Paragraph>
              </div>
            </div>
            <br />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openSignUp}
          onClose={() => setOpenSignUp(false)}
          sx={{ width: "100%" }}
          scroll="body"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogContent>
            <div className={classes.theSection}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a onClick={() => setOpenSignUp(false)}>
                  <Close style={{ color: "#c5c5c5" }} />
                </a>
              </div>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theRegister}>
                    {/* <img src={bannerregister} /> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Paragraph
                    center
                    color="#353535"
                    bold="700"
                    margin="0 0 1.5rem 0"
                    size="24px"
                  >
                    {t("Create An Acoount")}
                  </Paragraph>
                  <div className={classes.theTabList}>
                    <div
                      className={
                        tab == 0 ? classes.theTabActive : classes.theTab
                      }
                      onClick={() => handleChangeTab(0)}
                    >
                      <Paragraph color={tab == 0 ? "#FFF" : "#353535"}>
                        {t("Business")}
                      </Paragraph>
                    </div>
                    <div
                      className={
                        tab == 1 ? classes.theTabActive : classes.theTab
                      }
                      onClick={() => handleChangeTab(1)}
                    >
                      <Paragraph color={tab == 0 ? "#353535" : "#FFF"}>
                        {t("Individual")}
                      </Paragraph>
                    </div>
                  </div>
                  <br />
                  {tab == 0 && (
                    <>
                      <FormInput
                        label={t("Name")}
                        placeholder={t("Name")}
                        border="1px solid #707070"
                        name="name"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Email Address")}
                        placeholder={t("Email Address")}
                        border="1px solid #707070"
                        name="email"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Contact Number")}
                        placeholder={t("Contact Number")}
                        border="1px solid #707070"
                        name="contact"
                        type="number"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Company Name")}
                        placeholder={t("Company Name")}
                        border="1px solid #707070"
                        name="company_name"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("SSM of Company")}
                        placeholder={t("SSM of Company")}
                        border="1px solid #707070"
                        name="ssm"
                        type="file"
                        onChange={handleChangeUpload}
                      />
                      <FormInput
                        label={t("Password")}
                        placeholder={t("Password")}
                        border="1px solid #707070"
                        name="password"
                        type="password"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Confirmed Password")}
                        placeholder={t("Confirmed Password")}
                        border="1px solid #707070"
                        name="password2"
                        type="password"
                        onChange={handleChange}
                      />
                      <br />
                      <Button
                        label={t("SIGN UP")}
                        color="#fff"
                        bg="#353535"
                        br="6px"
                        onClick={() => handleRegisterBusiness()}
                      />
                    </>
                  )}
                  {tab == 1 && (
                    <>
                      <FormInput
                        label={t("Name")}
                        placeholder={t("Name")}
                        border="1px solid #707070"
                        name="name"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Email Address")}
                        placeholder={t("Email Address")}
                        border="1px solid #707070"
                        name="email"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Contact Number")}
                        placeholder={t("Contact Number")}
                        border="1px solid #707070"
                        name="contact"
                        type="number"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Password")}
                        placeholder={t("Password")}
                        border="1px solid #707070"
                        name="password"
                        type="password"
                        onChange={handleChange}
                      />
                      <FormInput
                        label={t("Confirmed Password")}
                        placeholder={t("Confirmed Password")}
                        border="1px solid #707070"
                        name="password2"
                        type="password"
                        onChange={handleChange}
                      />
                      <br />
                      <Button
                        label={t("SIGN UP")}
                        color="#fff"
                        bg="#353535"
                        br="6px"
                        onClick={() => handleRegisterIndividual()}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
            <br />
          </DialogContent>
        </Dialog>
      </NavbarContainer>

      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme='light'
      />
    </I18nextProvider>
  );
};

export default Navbar;
