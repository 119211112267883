import React from "react";
import Slider from "react-slick";
import useStyles, { IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import silestone001 from "../../assets/images/silestone001.png";
import purchase from "../../assets/images/purchase.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Hidden
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import { check_promo_code, get_address, get_user, submit_order, use_promo_code } from "../../API/API";
import useCart from "../../hooks/useCart";
import { useTranslation } from "react-i18next";

export default function Payment() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  // CART LIST

  const {
    getCart,
    decreaseProduct,
    increaseProduct,
    removeProduct,
    getTotalCart,
    clearCart,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const productCartList = getcart.replace(/\\/g, "");

  const [qtyCount, setQtyCount] = React.useState(0);

  React.useEffect(() => {
    setCart(getCart());
  }, [qtyCount, getCart]);

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    // get_data();

    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);


  const [chooseAddress, setChooseAddress] = React.useState(null);

  const handleChooseAddresss = (e) => {
    setChooseAddress(e);
  };

  const [showReceipt, setShowReceipt] = React.useState(0);

  const [deliveryOption, setDeliveryOption] = React.useState(1);

  const handleChangeDeliveryOption = (event) => {
    setDeliveryOption(event.target.value);
  };

  // API GET ADDRESS LIST

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [addressList, setAddressList] = React.useState([]);

  const getAddressBook = () => {
    get_address({ token: token }).then((json) => {
      setAddressList(json.data);
    });
  };

  React.useEffect(() => {
    getAddressBook();
  }, []);

  // CHOOSE SELF PICK UP DATE

  const [chooseDate, setChooseDate] = React.useState("");

  // const handleChooseDate = (event) => {
  //   setChooseDate(event.target.value);
  // };

  // ENTER PROMOCODE

  const [remarks, setRemarks] = React.useState("");
  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  }

  const [useOrder, setUseOrder] = React.useState("");
  const handleUseOrder = (event) => {
    setUseOrder(event.target.value);
  }

  const [promoCode, setPromoCode] = React.useState("");

  const handlePromoCode = (event) => {
    setPromoCode(event.target.value);
  };

  // CHECK VALID PROMOCODE

  const handleCheckPromoCode = () => {

    // use_promo_code({
    //   token: token,
    //   promo_code: promoCode,
    // }).then((json) => {
    //   if (json.status) {
    // setPromoPrice(json.data.amount)
    setPromoPrice(0);

    check_promo_code({
      token: token,
      promo_code: promoCode,
      total_price: getTotalCart()
    }).then((json) => {
      if (json.status) {
        //   const amount = parseFloat(json.data.amount);
        //   setPromoPrice((prevPromoPrice) => prevPromoPrice + Number(amount));
        setPromoPrice(json.data)
        console.log(typeof getTotalCart())
        // setPromoPrice((prevPromoPrice) => prevPromoPrice + json.data.amount);
        // setPromoPrice((prevPromoPrice) => prevPromoPrice + parseFloat(json.data.amount));
        console.log(typeof json.data)
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    })

    //   } else {
    //     alert(json.message)
    //   }
    // })

    // if (promoCode === 'asd') {
    //   // If the promo code is correct, update the promo price state
    //   setPromoPrice(1110); // Replace '10' with the desired promo price value
    // }
    // else {
    //   alert("no promocode existed")
    //   setPromoPrice(0)
    // }
    console.log(promoCode);
  };

  // CALCULATE SHIPPING FEE

  // const [shippingFee, setShippingFee] = React.useState("0");

  const [promoPrice, setPromoPrice] = React.useState(0);

  //  CHECKOUT

  // const handleCheckout = () => {
  //   if (deliveryOption == 1) {
  //     if (chooseDate == "") {
  //       alert("Please Choose Your Self Pick Up Date");
  //       return;
  //     }
  //     let postparam = {
  //       cart: productCartList,
  //       delivery_date: chooseDate,
  //       promocode: promoCode,
  //       total_amount: parseFloat(
  //         getTotalCart() + parseFloat(shippingFee)
  //       ).toFixed(2),
  //     };
  //     console.log(postparam);
  //     // clearCart()
  //   }

  //   if (deliveryOption == 2) {
  //     if (chooseAddress == null) {
  //       alert("Please Choose Your Shipping Address");
  //       return;
  //     }
  //     let postparam = {
  //       cart: productCartList,
  //       user_address_id: chooseAddress,
  //       promocode: promoCode,
  //       total_amount: parseFloat(
  //         getTotalCart() + parseFloat(shippingFee)
  //       ).toFixed(2),
  //     };
  //     console.log(postparam);
  //     // clearCart()
  //   }

  //   // setShowReceipt(1);
  // };

  const handleCheckout = () => {
    if (deliveryOption == 1) {
      if (chooseDate == "") {
        alert(t("Please Choose Your Self Pick Up Date"));
        return;
      }
      let postparam = {
        cart: productCartList,
        pickup_date: chooseDate,
        use_order: useOrder,
        remarks: remarks,
        promo_code: promoCode,
        amount: getTotalCart(),
        total_amount: Math.max(getTotalCart() - parseFloat(promoPrice), 0).toFixed(2),
        options_id: deliveryOption,
        // shipping_fee: shippingFee,
      };

      // use_promo_code({
      //   token: token,
      //   promo_code: promoCode,
      // }).then((json) => {
      //   if (json.status) {

          submit_order(postparam).then((json) => {
            if (json.status) {
              const translatedMessage = json[`${i18n.language}_message`] || json.message;
              alert(t(`${translatedMessage}`));
              clearCart()
              setShowReceipt(1);
            } else {
              const translatedMessage = json[`${i18n.language}_message`] || json.message;
              alert(t(`${translatedMessage}`));
            }
          });

      //   } else {
      //     alert(json.message)
      //   }
      // })


      console.log(postparam);
    }

    if (deliveryOption == 2) {
      if (chooseAddress == null) {
        alert(t("Please Choose Your Shipping Address"));
        return;
      }
      const selectedAddress = addressList.find(item => item.user_address_id === chooseAddress);
      if (!selectedAddress) {
        alert(t("Selected address not found"));
        return;
      }
      let postparam = {
        options_id: deliveryOption,
        cart: productCartList,
        user_address_id: chooseAddress,
        use_order: useOrder,
        remarks: remarks,
        promo_code: promoCode,
        amount: getTotalCart(),
        total_amount: Math.max(getTotalCart() - parseFloat(promoPrice), 0).toFixed(2),
        // shipping_fee: shippingFee,
        user_id: selectedAddress.user_id,
        name: selectedAddress.name,
        phone: selectedAddress.phone,
        address: selectedAddress.address,
        address2: selectedAddress.address2,
        postcode: selectedAddress.postcode,
        state: selectedAddress.state,
        country: selectedAddress.country,
      };
      // use_promo_code({
      //   token: token,
      //   promo_code: promoCode,
      // }).then((json) => {
      //   if (json.status) {

          submit_order(postparam).then((json) => {
            if (json.status) {
              const translatedMessage = json[`${i18n.language}_message`] || json.message;
              alert(t(`${translatedMessage}`));
              clearCart()
              setShowReceipt(1);
            } else {
              const translatedMessage = json[`${i18n.language}_message`] || json.message;
              alert(t(`${translatedMessage}`));
            }
          });

      //   } else {
      //     alert(json.message)
      //   }
      // })
      console.log(postparam);

    }

  };


  const [user, setUser] = React.useState([])
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const getUser = () => {
    get_user({ token: token }).then((json) => {
      if (json.status) {
        setUser(json.data)
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("business_type");
        window.location.reload()
      }
    })
  }

  function handleChooseDate(event) {
    const selectedDate = event.target.value;

    const currentDate = new Date();

    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(currentDate.getDate());

    const selectedDateObj = new Date(selectedDate);

    if (selectedDateObj < minDate) {
      setChooseDate('');
      alert(t("Date is not valid"))
    } else {
      setChooseDate(selectedDate);
    }
  }

  return (
    <React.Fragment>
      <Navbar />

      {showReceipt == 0 ? (
        <Container>
          <Section pd="8rem 0 2rem 0">
            <Paragraph
              size="34px"
              color="#353535"
              bold="700"
              center
              margin="0 0 2rem 0"
              style={{ fontFamily: "'Gotu', sans-serif" }}
            >
              {t("Checkout")}
            </Paragraph>
            <TableContainer>
              <Table>
                <TableHead className={classes.theHead}>
                  <TableRow>
                    <TableCell>{t("Product")}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{t("Price")}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{t("Quantity")}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{t("Total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* Payment Appeared New */}
                  {cart != undefined && cart.length > 0
                    ? cart.map((item) => (
                      <TableRow>
                        <TableCell>
                          <div
                            style={{ display: "flex" }}
                            className={classes.theWidth}
                          >
                            <div className={classes.theIMG}>
                              <img src={item.image} />
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="theEllipsis"
                              >
                                {item.category}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                bold="700"
                                size="14px"
                                className="theEllipsis"
                              >
                                {item.name}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="theEllipsis"
                              >
                                {item.brand}
                              </Paragraph>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <Paragraph center>
                              RM {item.single_price}
                            </Paragraph>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <Paragraph center>{item.quantity}</Paragraph>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <Paragraph center>
                              RM {parseFloat(item.price).toFixed(2)}
                            </Paragraph>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                    : null}


                </TableBody>
              </Table>
            </TableContainer>
            <br />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Paragraph bold="600" margin="0 0 1rem 0">
                  {t("Delivery Options")}
                </Paragraph>
                <div
                  style={{
                    border: "1px solid #d7d7d7",
                    borderRadius: "8px",
                    padding: "1rem",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={deliveryOption}
                    onChange={handleChangeDeliveryOption}
                  // name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={t("Self Pick Up")}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={t("Delivery")}
                    />
                  </RadioGroup>
                </div>

                <br />
                <br />

                {deliveryOption == 1 && (
                  <>
                    <Paragraph bold="600" margin="0 0 1rem 0">
                      {t("Choose Self Pick Up Date")}
                    </Paragraph>
                    <FormInputNoLabel
                      border="1px solid #d7d7d7"
                      placeholder={t("Choose Self Pick Up Date")}
                      type="date"
                      value={chooseDate}
                      onChange={handleChooseDate}
                    />
                  </>
                )}

                {deliveryOption == 2 && (
                  <>
                    <Paragraph bold="600" margin="0 0 1rem 0">
                      {t("Choose Your Shipping Address")}
                    </Paragraph>
                    {addressList.map((item) => (
                      <div
                        className={
                          item.user_address_id == chooseAddress
                            ? classes.theListAddress
                            : classes.theListAddressDefault
                        }
                        onClick={() =>
                          handleChooseAddresss(item.user_address_id)
                        }
                      >
                        <div>
                          <Paragraph
                            className="theEllipsis"
                            margin=" 0 0 .6rem 0"
                          >
                            {item.name}
                          </Paragraph>
                        </div>
                        <Paragraph>
                          {" "}
                          {item.address}, {item.city}, {item.postcode},
                          {item.state}, {item.contry}
                        </Paragraph>
                        <Paragraph size="14px" color="#637381">
                          {item.phone}
                        </Paragraph>
                      </div>
                    ))}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paragraph bold="600" margin="0 0 1rem 0">
                  {t("Use Order At")}
                </Paragraph>
                <FormInput
                  placeholder={t("Use Order At")}
                  border="1px solid #707070"
                  style={{ width: "100%" }}
                  value={useOrder}
                  onChange={handleUseOrder}
                />
                <br />
                <Paragraph bold="600" margin="0 0 1rem 0">
                  {t("Remarks")}
                </Paragraph>
                <FormInput
                  placeholder={t("Remarks")}
                  border="1px solid #707070"
                  style={{ width: "100%" }}
                  value={remarks}
                  onChange={handleRemarks}
                />
                <br />
                <div className={classes.theFlex_}>
                  <div className={classes.theWidths}>
                    <FormInput
                      placeholder={t("Promo Code")}
                      border="1px solid #707070"
                      style={{ width: "100%" }}
                      value={promoCode}
                      onChange={handlePromoCode}
                    />
                  </div>

                  <Button
                    label={t("APPLY")}
                    color="#fff"
                    bg="#353535"
                    br="6px"
                    onClick={() => handleCheckPromoCode()}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                    paddingTop: "1rem",
                    borderTop: "1px solid #d7d7d7",
                  }}
                >
                  <Paragraph size="1.2rem">{t("Subtotal")}</Paragraph>
                  <Paragraph bold="500" size="1.2rem">
                    RM {getTotalCart().toFixed(2)}
                  </Paragraph>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <Paragraph size="1.2rem">{t("Shipping Fee")}</Paragraph>
                  {deliveryOption == 1 ? (
                    <Paragraph bold="500" size="1.2rem">
                      RM 0.00
                    </Paragraph>
                  ) : (
                    <Paragraph bold="500" size="1.2rem">
                      RM {parseFloat(shippingFee).toFixed(2)}
                    </Paragraph>
                  )}
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <Paragraph size="1.2rem">{t("Promotion Price")}</Paragraph>
                  <Paragraph bold="500" size="1.2rem">
                    - RM {parseFloat(promoPrice).toFixed(2)}
                  </Paragraph>

                  {/* <Paragraph bold="500" size="1.2rem">
                    - RM 10.00
                  </Paragraph> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                    paddingTop: "1rem",
                    borderTop: "1px solid #d7d7d7",
                  }}
                >
                  <Paragraph size="1.2rem">{t("Total")}</Paragraph>
                  {/* {deliveryOption == 1 ? (
                    <Paragraph color="#353535" bold="700" size="1.2rem">
                      RM{" "}
                      {Math.max(getTotalCart() - parseFloat(promoPrice), 0).toFixed(2)}
                    </Paragraph>
                  ) : (
                    <Paragraph color="#353535" bold="700" size="1.2rem">
                      RM{" "}
                      {parseFloat(
                        getTotalCart() - parseFloat(promoPrice)
                      ).toFixed(2)}
                    </Paragraph>
                  )} */}
                  <Paragraph color="#353535" bold="700" size="1.2rem">
                    RM{" "}
                    {Math.max(getTotalCart() - parseFloat(promoPrice), 0).toFixed(2)}
                  </Paragraph>
                </div>
              </Grid>
            </Grid>

            <br />

            <br />
            <div className={classes.theFlexx}>
              <div className={classes.theRight}>
                <Button
                  href="/cart"
                  label={t("Back to Cart")}
                  br="4px"
                  color="#353535"
                  box="1px solid #353535"
                />
              </div>
              <div>
                <Button
                  //   href="/checkout"
                  label={t("Submit Order")}
                  br="4px"
                  color="#fff"
                  bg="#353535"
                  onClick={() => handleCheckout()}
                />
              </div>
            </div>
          </Section>
        </Container>
      ) : (
        <Container>
          <Section pd="8rem 0 2rem 0">
            <div className={classes.thePurchaseBox}>
              <Paragraph
                size="24px"
                color="#353535"
                bold="700"
                center
                margin="0 0 2rem 0"
              >
                {t("Thank you for your purchase!")}
              </Paragraph>
              <div className={classes.thePurchase}>
                <img src={purchase} />
              </div>
              <div>
                <Paragraph margin="0 0 1rem 0">
                  {t("Thanks for placing order!")}
                </Paragraph>
                <Paragraph margin="0 0 1rem 0">
                  {t("We will send you a notification within 5 days when the order confirmed.")}
                </Paragraph>
                <Paragraph margin="0 0 1rem 0">
                  {t("If you have any question or queries then fell to get in contact us.")}
                </Paragraph>
                <Paragraph margin="0 0 1rem 0">{t("All the best!")}</Paragraph>
              </div>
              <br />
              <Button
                href="/"
                label={t("Continue Shopping")}
                br="4px"
                color="#353535"
                box="1px solid #353535"
              />
            </div>
          </Section>
        </Container>
      )}

      <Footer />
    </React.Fragment>
  );
}
