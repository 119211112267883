import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

    root2: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "&img": {
      height: "10px",
      width: "100%",
      objectFit: "cover"
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  root3: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  bannerOverlay: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  textOverlay: {
    display: "flex",
    flexDirection: 'column',
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "150px"
  },

  theBanner: {
    position: "relative",
    width: "100%",
    // height: "20rem",

    // ...md("md", {
    //   height: "26rem"
    // }),


    // ...md("xmd", {
    //   height: "35rem"
    // }),

    // ...md("slg", {
    //   height: "50rem"
    // }),


    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "24px",
    ...md("xmd", {
      fontSize: "44px",
    }),
    ...md("lg", {
      fontSize: "54px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("xmd", {
      fontSize: "23px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    // width: "100%",
    top: "20%",
    left: "5%",
    transform: "translateY(-50%)",

    ...md("md", {
      top: "50%",
      width: "50%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theBar: {
    position: "relative",
    width: "100%",
    bottom: 0,
    background: "#d7d7d7",
    // height: "200px",
    padding: "2rem 0",

    ...md("md", {
      position: "absolute",
      background: "rgba(242,242,242,0.2)",
    }),
  },

  theScroll: {
    overflow: "scroll",
    display: "flex",
  },

  theCardMob: {
    position: "relative",
    background: "#fff",
    borderRadius: "4px",
    padding: "1rem",
    marginRight: "1rem",
    width: "300px",
  },

  theCardMobActive: {
    position: "relative",
    background: "#fff",
    borderRadius: "4px",
    padding: "1rem",
    border: "2px solid ##FF6D00",
    marginRight: "1rem",
  },

  theCard: {
    position: "relative",
    background: "#fff",
    borderRadius: "4px",
    padding: "1rem",
    // width: '40%'
  },

  theCardActive: {
    position: "relative",
    background: "#fff",
    borderRadius: "4px",
    padding: "1rem",
    border: "2px solid ##FF6D00",
  },

  theIcon: {
    position: "relative",
    width: "20%",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },

  thePadd: {
    padding: "1rem 3rem",
    marginBottom: "2rem",
  },

  theSide: {
    position: "absolute",
    left: 0,
    // width: "4%",

    "& img": {
      width: "100%",
    },
  },

  theCount: {
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%,-50%)",
  },

  theMB: {
    marginBottom: "1rem !important",
    ...md("md", {
      marginBottom: "0 !important",
    }),
  },

  theLogoIMG: {
    position: "relative",
    width: "70%",
    margin: "0 auto",

    ...md("md", {
      width: "10%",
      margin: "unset",
    }),

    "& img": {
      width: "100%",
    },
  },

  theFlex: {
    display: "block",
    justifyContent: "space-around",
    alignItems: "center",

    ...md("md", {
      display: "flex",
    }),
  },

  theAbout: {
    position: "rerlative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theAvatar: {
    position: "relative",
    width: "50px",
    height: "50px",
    marginRight: ".6rem",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },

  theGameIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theText: {
    position: "relative",
    background: "#F2F2F2",
    padding: "1rem",
    borderRadius: "6px",
    width: "32%",
  },

  theText2: {
    position: "relative",
    background: "#F2F2F2",
    padding: "1rem",
    borderRadius: "6px",
    width: "60%",
  },

  theRow: {
    display: " flex",
    // alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "1rem",
  },

  thePayment: {
    position: "relative",
    width: "15%",
    "& img": {
      width: "100%",
    },
  },

  theFlagIMG: {
    position: "relative",
    width: "5%",
    marginRight: "1rem",
    // transform: "scale(1.5)",
    "& img": {
      width: "100%",
    },
  },

  theIconIMG: {
    position: "relative",
    width: "8%",
    "& img": {
      width: "100%",
    },
  },

  theFlexx: {
    display: "block",
    justifyContent: "space-between",
    alignItems: "center",

    ...md("md", {
      display: "flex",
    }),
  },

  theNewsIMG: {
    position: "relative",
    width: "30%",
    margin: "0 auto",
    paddingBottom: "1rem",

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "14%",
      paddingBottom: "0rem",
    }),

    "& img": {
      width: "100%",
    },
  },

  theMap: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  centerCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theService: {
    position: "relative",
    margin: "2rem 0 0 0",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "100%",
      marginBottom: "1rem",
      transition: "0.5s",
    },
  },
  imageStone: {
    width: "100%",
    position: "relative",

    "& img": {
      height: "100px",
      position: "relative",
      objectFit: "contain"
    }
  },
  imageFacility: {
    width: "100%",
    position: "relative",

    "& img": {
      height: "250px",
      position: "relative",
      objectFit: "contain"
    }
  },
  imageFacility2: {
    width: "100%",
    position: "relative",

    "& img": {
      height: "150px",
      position: "relative",
      objectFit: "contain"
    }
  },
  imageOtto: {
    width: "100%",
    position: "relative",

    "& img": {
      height: "50px",
      position: "relative",
      objectFit: "contain"
    }
  },
};

export default makeStyles(config);
