import React from "react";
import Slider from "react-slick";
import useStyles, { CarouselContainer, CarouselIMG } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import banner003 from "../../assets/images/banner003.png";

import about from "../../assets/images/about.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  HighlightOff,
  Star,
} from "@mui/icons-material";
import { json, useParams } from "react-router-dom";
import { submit_enquiry, get_banner, get_user } from "../../API/API";
import { Trans, useTranslation } from "react-i18next";
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import flagMalaysia from "../../assets/images/flagMalaysia.png";
import flagIndonesia from "../../assets/images/flagIndonesia.png"
import flagVietnam from "../../assets/images/flagVietnam.png"
import flagCambodia from "../../assets/images/flagCambodia.png"
import flagMyanmar from "../../assets/images/flagMyanmar.png"
import flagSriLanka from "../../assets/images/flagSriLanka.png"
import tristone from "../../assets/images/tristone.png"
import tristone2 from "../../assets/images/tristone2.png"
import goldFabricator from "../../assets/images/goldFabricator.png"
import goldApproved from "../../assets/images/goldApproved.jpg"
import cosentino from "../../assets/images/cosentino.png"
import sensa from "../../assets/images/sensa.png"
import dekton from "../../assets/images/dekton.png"
import silestoneLogo from "../../assets/images/silestoneLogo.png"
import stoneLogo from "../../assets/images/stoneLogo.png"
import stoneLogo1 from "../../assets/images/stoneLogo1.png"
import stoneLogo2 from "../../assets/images/stoneLogo2.png"
import logo from "../../assets/images/logo.png"
import facility from "../../assets/images/facility.png"
import ottoBrand from "../../assets/images/ottoBrand.png"
import facility2 from "../../assets/images/facility2.png"
import facility3 from "../../assets/images/facility3.png"
import ottoBrand2 from "../../assets/images/ottoBrand2.png"
import { useTheme } from "@mui/material/styles";

export default function About() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { page_id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const screenWidth = window.innerWidth;

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [banner, setBanner] = React.useState([]);

  const getBanner = (page_id) => {
    get_banner({ page_id: 4 }).then((json) => {
      setBanner(json.data);
    });
  };


  React.useEffect(() => {
    getBanner(page_id);
    // getUser();
  }, []);

  const [contactData, setContactData] = React.useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };

  // API

  const handleSubmit = () => {
    // if (
    //   contactData.name.length == "" ||
    //   contactData.email.length == "" ||
    //   contactData.contact.length == "" ||
    //   contactData.message.length == ""
    // ) {
    //   alert("All Field Required");
    //   return;
    // }

    // submit_enquiry(contactData).then((json) => {
    //   if (json.status) {
    //     alert(json.message);
    //   } else {
    //     alert(json.message);
    //   }
    // });
  };

  const [token, setToken] = React.useState([])
  const [user, setUser] = React.useState([])
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // const getUser = () => {
  //   get_user({ token: token }).then((json) => {
  //     if (json.status) {
  //       setUser(json.data)
  //     } else {
  //       alert(json.message);
  //       window.localStorage.removeItem("token");
  //       window.localStorage.removeItem("business_type");
  //       window.location.reload()
  //     }
  //   })
  // }


  return (
    <React.Fragment>
      <Navbar />

      <div>
        <Slider className={classes.root3} {...settings}>
          {banner != null &&
            banner.map((item, index) => {
              return (
                <>
                  <div key={index} className={classes.bannerOverlay}>
                    <div className={classes.theBanner}>
                      <CarouselContainer>
                        <CarouselIMG src={window.innerWidth > 968 ? item.pc_image : item.mobile_image} />
                      </CarouselContainer>
                    </div>
                    {/* <div className={classes.textOverlay}>
              <Paragraph
                    className={classes.theFontTitle}
                    size="50px"
                    color="black"
                    bold="700"
                    margin="0 0 .4rem 0"
                  >
                    {item.text}
                  </Paragraph>
              </div> */}
                  </div>
                </>
              );
            })}
        </Slider>
      </div>

      {/* <div className={classes.theBanner}>
        <img src={banner003} />
      </div> */}
      <Container style={{ marginBottom: "2rem" }}>
        <Section pd="2rem 0">
          <Paragraph color="#000" center size="18px" margin="0 0 2rem 0">
            {t("In 2001, Global-Allied was established as a fabricator an installer of Solid Surface countertops catering both to the residential and commercial sector. Today, we have expanded to include Marble, Granite, Quartz, Sintered, and many other natural stones, catering to all architectural applications.")}
          </Paragraph>
          <Paragraph
            color="#000"
            bold="700"
            size="24px"
            margin="0 0 2rem 0"
            center
            style={{ fontFamily: "'Gotu', sans-serif" }}
          >
            {t("Global-Allied in Numbers")}
          </Paragraph>
          <br />
          <Grid container spacing={3} style={{ marginBottom: "2rem", justifyContent: "center" }}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <div className={classes.centerCard}>
                  <div className={classes.theService}>
                    <FactoryIcon fontSize="large" style={{ fontSize: 50, color: "#000" }} />
                  </div>
                </div>
                <div style={{ padding: "1rem" }}>
                  <Paragraph
                    center
                    bold="700"
                    color="#000"
                    margin="0 0 1rem 0"
                  >
                    {t("Factory Space")}:
                  </Paragraph>
                  <Paragraph center>
                    {t("90,000 sq ft across 3 factories (1 in Singapore and 2 in Malaysia)")}
                  </Paragraph>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <div className={classes.centerCard}>
                  <div className={classes.theService}>
                    <GroupsIcon fontSize="large" style={{ fontSize: 50, color: "#000" }} />
                  </div>
                </div>
                <div style={{ padding: "1rem" }}>
                  <Paragraph
                    center
                    bold="700"
                    color="#000"
                    margin="0 0 1rem 0"
                  >
                    {t("Workforce")}:
                  </Paragraph>
                  <Paragraph center>
                    {t("Team of 110 people operating within Singapore and Malaysia")}
                  </Paragraph>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <div style={{ backgroundColor: "#333335", marginBottom: "2rem" }}>
        <Container>
          {isMobile ? (
            <>
              <Section pd="2rem 2rem">
                <Paragraph
                  color="#fff"
                  bold="700"
                  size="24px"
                  margin="0 0 2rem 0"
                  center
                  style={{ fontFamily: "'Gotu', sans-serif" }}
                >
                  {t("Our Growth, 2001-2012")}
                </Paragraph>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2001
                        </Paragraph>
                        <Paragraph margin="0 0 5px 0" bold="800">
                          {t("Company established")}
                        </Paragraph>
                        <Paragraph>
                          {t("Fabrications and Installation of Solid Surface countertops")}
                        </Paragraph>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2004
                        </Paragraph>
                        <Paragraph margin="0 0 5px 0" bold="800">
                          {t("Introduction of IMPERIAL Brand")}
                        </Paragraph>
                        <Paragraph>
                          <Trans i18nKey="2004" components={{ strong: <strong></strong> }} />
                          {/* {t("Commencement of Solid Surface Manufacturing plant in Johor, Malaysia, with the creation of our <strong>'IMPERIAL'</strong> brand.")} */}
                        </Paragraph>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff" style={{ overflow: "hidden" }}>
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <div>
                          <Paragraph>
                            2007
                          </Paragraph>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Venturing Abroad")}
                          </Paragraph>
                          <Paragraph margin="0 0 1rem 0">
                            {t("We undertook our first major overseas project in Columbo, Sri Lanka, where we installed kitchen countertops for an upscale condominium project. From then onwards, we have gone on to undertake projects in various Asian countries")}
                          </Paragraph>
                        </div>
                        <Slider className={classes.root2} {...settings2} >
                          <div>
                            <img src={flagMalaysia} />
                          </div>
                          <div>
                            <img src={flagIndonesia} />
                          </div>
                          <div>
                            <img src={flagVietnam} />
                          </div>
                          <div>
                            <img src={flagCambodia} />
                          </div>
                          <div>
                            <img src={flagMyanmar} />
                          </div>
                          <div>
                            <img src={flagSriLanka} />
                          </div>
                        </Slider>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2010
                        </Paragraph>
                        <div>
                          {screenWidth <= 480 ? (
                            <div className={classes.imageStone}>
                              <img src={tristone2} />
                            </div>
                          ) : (
                            <div className={classes.imageStone}>
                              <img src={tristone2} />
                            </div>
                          )}
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Introducing TRISTONE from S. Korea")}
                          </Paragraph>
                          <Paragraph>
                            <Trans i18nKey="2010" components={{ strong: <strong></strong> }} />
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2012
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Introduction of COMPRESS Brand+ New Stone Fabrication Factory")}
                          </Paragraph>
                          <Paragraph>
                            <Trans i18nKey="2012" components={{ strong: <strong></strong> }} />
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2013
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Fabrication Facility in Johor")}
                          </Paragraph>
                          <Paragraph>
                            {t("We set up a new fabrication facility in Johor, Malaysia, to cater to the Malaysian market.")}
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff" style={{ overflow: "hidden" }}>
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2015
                        </Paragraph>
                        <div>
                          <div>
                            {screenWidth <= 480 ? (
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className={classes.imageStone} style={{ margin: "0 0 10px 0" }}>
                                  <img src={goldApproved} />
                                </div>
                                <div className={classes.imageStone} style={{ margin: "0 0 1rem 0" }}>
                                  <img src={stoneLogo2} />
                                </div>
                              </div>
                            ) : (
                              <div style={{ display: "flex" }}>
                                <div className={classes.imageStone}>
                                  <img src={goldApproved} />
                                </div>
                                <div className={classes.imageStone}>
                                  <img src={stoneLogo2} />
                                </div>
                              </div>
                            )}
                          </div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Gold Fabricator for COSENTINO")}
                          </Paragraph>
                          <Paragraph>
                            <Trans i18nKey="2015" components={{ strong: <strong></strong> }} />
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2016
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Regional Growth in Malaysia")}
                          </Paragraph>
                          <Paragraph>
                            {t("We established our marketing presence in Kuala Lumpur, Malaysia.")}
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff" style={{ overflow: "hidden" }}>
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2017
                        </Paragraph>
                        <div>
                          {screenWidth <= 480 ? (
                            <div className={classes.imageFacility2}>
                              <img src={facility3} />
                            </div>
                          ) : (
                            <div className={classes.imageFacility}>
                              <img src={facility3} />
                            </div>
                          )}
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Acquired New Facility")}
                          </Paragraph>
                          <Paragraph>
                            {t("In 2017, we acquired our own 50,000 sq ft. facility in Singapore.")}
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2018
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Introduction of OTTO & OTTO+")}
                          </Paragraph>
                          <Paragraph style={{ margin: "0 0 1rem 0" }}>
                            <Trans i18nKey="2018" components={{ strong: <strong></strong> }} />
                          </Paragraph>
                          {screenWidth <= 480 ? (
                            <div>
                              <img src={ottoBrand2} />
                            </div>
                          ) : (
                            <div>
                              <img src={ottoBrand} />
                            </div>
                          )}
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2019
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Introduction of OTTO Floorclick")}
                          </Paragraph>
                          <Paragraph>
                            {t("Quality and Affordable vinyl floor board")}
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent color="#fff">
                      <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                        <Paragraph>
                          2022
                        </Paragraph>
                        <div>
                          <Paragraph margin="0 0 5px 0" bold="800">
                            {t("Acquired New Facility")}
                          </Paragraph>
                          <Paragraph>
                            {t("50,000 ft factory in Johor, Malaysia.")}
                          </Paragraph>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
                {/* <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2001
                  </Paragraph>
                  <Paragraph center margin="0 0 5px 0" bold="800">
                    Company established
                  </Paragraph>
                  <Paragraph center>
                    Fabrication and Installation of Solid Surface countertops
                  </Paragraph>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2004
                  </Paragraph>
                  <Paragraph center margin="0 0 5px 0" bold="800">
                    Introduction of IMPERIAL Brand
                  </Paragraph>
                  <Paragraph center>
                    Commencement of Solid Surface Manufacturing plant in Johor, Malaysia, with the
                    crearion of our <strong>'IMPERIAL'</strong> brand.
                  </Paragraph>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2007
                  </Paragraph>
                  <Paragraph center margin="0 0 5px 0" bold="800">
                    Venturing Abroad
                  </Paragraph>
                  <Paragraph center margin="0 0 1rem 0">
                    We undertook our first majot overseas project in Columbo, Sri Lanka,
                    where we installed kitchen countertops for an upscale condomunium
                    project. From then onwards, we have gone on to undertake projects in
                    various Asian countries
                  </Paragraph>
                  <div>
                    <Slider className={classes.root2} {...settings2} >
                      <div>
                        <img src={flagMalaysia} />
                      </div>
                      <div>
                        <img src={flagIndonesia} />
                      </div>
                      <div>
                        <img src={flagVietnam} />
                      </div>
                      <div>
                        <img src={flagCambodia} />
                      </div>
                      <div>
                        <img src={flagMyanmar} />
                      </div>
                      <div>
                        <img src={flagSriLanka} />
                      </div>
                    </Slider>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2010
                  </Paragraph>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <img src={tristone} />
                    </div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Introducing TRISTONE from S. Korea
                    </Paragraph>
                    <Paragraph center>
                      We added <strong>'TRISTONE'</strong>, a 100& arcylic solid surface from
                      South Korea, to the range of products that we are marketing.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2012
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Introduction of COMPRESS Brand+ New Stone Fabrication Factory
                    </Paragraph>
                    <Paragraph center>
                      We introduced the <strong>'COMPRESS'</strong> brand of Quartz Surfaces to cater
                      to the growing demand of competitively-priced Quartz surface material
                      in the residential countertop market. With that, we also set up our own
                      stone fabrication factory.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2013
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Fabrication Facility in Johor
                    </Paragraph>
                    <Paragraph center>
                      We set up a new fabrication facility in Johor, Malaysia, to cater
                      to the Malaysian market.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2015
                  </Paragraph>
                  <div>
                    <div>
                      {screenWidth <= 480 ? (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <div style={{ margin: "0 0 10px 0" }}>
                            <img src={goldFabricator} />
                          </div>
                          <div style={{ margin: "0 0 1rem 0" }}>
                            <img src={stoneLogo} />
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div style={{ margin: "0 1rem 0 0" }}>
                            <img src={goldFabricator} />
                          </div>
                          <div>
                            <img src={stoneLogo} />
                          </div>
                        </div>
                      )}
                    </div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Gold Fabricator for COSENTINO
                    </Paragraph>
                    <Paragraph center>
                      We were appointed as a <strong>GOLD FABRICATOR</strong> for the <strong>COSENTINO</strong> range
                      of products in Singapore. Their range of products are
                      Silestone, Dekton and Sensa. These are Quartz, Ultra Compact and
                      Premium Granite materials respectively.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2016
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Regional Growth in Malaysia
                    </Paragraph>
                    <Paragraph center>
                      We established our marketing presence in Kuala Lumpur, Malaysia.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2017
                  </Paragraph>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <img src={facility} />
                    </div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Acquired New Facility
                    </Paragraph>
                    <Paragraph center>
                      In 2017, we acquired our own 50,000 sq ft. facility in Singapore.
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2018
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Introduction of OTTO & OTTO+
                    </Paragraph>
                    <Paragraph center style={{ margin: "0 0 1rem 0" }}>
                      We introduced our in-house brand <strong>'OTTO'</strong> and <strong>'OTTO+'</strong>,
                      comprising of Quartz and Ultra-Compact materials respectively.
                    </Paragraph>
                    <div style={{ textAlign: "center" }}>
                      <img src={ottoBrand} />
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2019
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Introduction of OTTO Floorclick
                    </Paragraph>
                    <Paragraph center>
                      Quality and Affordable v floor board
                    </Paragraph>
                  </div>
                </div>
                <div style={{ margin: "0 0 2rem 0", color: "#fff" }}>
                  <Paragraph center>
                    2022
                  </Paragraph>
                  <div>
                    <Paragraph center margin="0 0 5px 0" bold="800">
                      Acquired New Facility
                    </Paragraph>
                    <Paragraph center>
                      50,000 ft factory in Johor, Malaysia.
                    </Paragraph>
                  </div>
                </div> */}
              </Section>
            </>
          ) : (
            <Section pd="2rem 0px">
              <Paragraph
                color="#fff"
                bold="700"
                size="24px"
                margin="0 0 2rem 0"
                center
                style={{ fontFamily: "'Gotu', sans-serif" }}
              >
                {t("Our Growth, 2001-2012")}
              </Paragraph>
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2001
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <Paragraph margin="0 0 5px 0" bold="800">
                      {t("Company established")}
                    </Paragraph>
                    <Paragraph>
                      {t("Fabrications and Installation of Solid Surface countertops")}
                    </Paragraph>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2004
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff" sx={{ padding: "6px 0 0px 32px" }}>
                    <Paragraph margin="0 0 5px 0" bold="800">
                      {t("Introduction of IMPERIAL Brand")}
                    </Paragraph>
                    <Paragraph>
                      <Trans i18nKey="2004" components={{ strong: <strong></strong> }} />
                    </Paragraph>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2007
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff" style={{ overflow: "hidden" }}>
                    <Paragraph margin="0 0 5px 0" bold="800">
                      {t("Venturing Abroad")}
                    </Paragraph>
                    <Paragraph margin="0 0 1rem 0">
                      {t("We undertook our first major overseas project in Columbo, Sri Lanka, where we installed kitchen countertops for an upscale condominium project. From then onwards, we have gone on to undertake projects in various Asian countries")}
                    </Paragraph>
                    <Slider className={classes.root2} {...settings2} >
                      <div>
                        <img src={flagMalaysia} />
                      </div>
                      <div>
                        <img src={flagIndonesia} />
                      </div>
                      <div>
                        <img src={flagVietnam} />
                      </div>
                      <div>
                        <img src={flagCambodia} />
                      </div>
                      <div>
                        <img src={flagMyanmar} />
                      </div>
                      <div>
                        <img src={flagSriLanka} />
                      </div>
                    </Slider>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2010
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <div className={classes.imageStone} style={{ textAlign: "left", margin: "0 0 1rem 0" }}>
                        <img src={tristone2} />
                      </div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Introducing TRISTONE from S. Korea")}
                      </Paragraph>
                      <Paragraph>
                        <Trans i18nKey="2010" components={{ strong: <strong></strong> }} />
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2012
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Introduction of COMPRESS Brand+ New Stone Fabrication Factory")}
                      </Paragraph>
                      <Paragraph>
                        <Trans i18nKey="2012" components={{ strong: <strong></strong> }} />
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2013
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Fabrication Facility in Johor")}
                      </Paragraph>
                      <Paragraph>
                        {t("We set up a new fabrication facility in Johor, Malaysia, to cater to the Malaysian market.")}
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2015
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <div style={{ display: "flex", margin: "0 0 1rem 0" }}>
                        <div className={classes.imageStone}>
                          <img src={goldApproved} />
                        </div>
                        <div className={classes.imageStone}>
                          <img src={stoneLogo2} />
                        </div>
                      </div>
                      {/* <img src={goldFabricator} /> */}
                      {/* <div style={{ display: "flex", flexDirection: "column"}}>
                    <img src={cosentino} />
                    <img src={sensa} />
                    <img src={dekton} />
                    <img src={silestoneLogo} />
                  </div> */}
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Gold Fabricator for COSENTINO")}
                      </Paragraph>
                      <Paragraph>
                        <Trans i18nKey="2015" components={{ strong: <strong></strong> }} />
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2016
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Regional Growth in Malaysia")}
                      </Paragraph>
                      <Paragraph>
                        {t("We established our marketing presence in Kuala Lumpur, Malaysia.")}
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2017
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <div className={classes.imageFacility} style={{ margin: "0 0 1rem 0" }}>
                        <img src={facility3} />
                      </div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Acquired New Facility")}
                      </Paragraph>
                      <Paragraph>
                        {t("In 2017, we acquired our own 50,000 sq ft. facility in Singapore.")}
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2018
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Introduction of OTTO & OTTO+")}
                      </Paragraph>
                      <Paragraph style={{ margin: "0 0 1rem 0" }}>
                        <Trans i18nKey="2018" components={{ strong: <strong></strong> }} />
                      </Paragraph>
                      <div className={classes.imageOtto} style={{ textAlign: "left" }}>
                        <img src={ottoBrand} />
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2019
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Introduction of OTTO Floorclick")}
                      </Paragraph>
                      <Paragraph>
                        {t("Quality and Affordable vinyl floor board")}
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="#fff">
                    2022
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent color="#fff">
                    <div>
                      <Paragraph margin="0 0 5px 0" bold="800">
                        {t("Acquired New Facility")}
                      </Paragraph>
                      <Paragraph>
                        {t("50,000 ft factory in Johor, Malaysia.")}
                      </Paragraph>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Section>
          )}
        </Container >
      </div >
      <Container>
        <Section pd="2rem 0">
          {isMobile ? (
            <>
              <div style={{ padding: "1rem 0 3rem 0" }}>
                <div style={{ textAlign: "center", margin: "0 0 1rem 0" }}>
                  <img src={logo} />
                </div>
                <Paragraph center bold="700">
                  Global-Allied Pte Ltd
                </Paragraph>
                <Paragraph center>
                  {t("Factory/Office/Showroom")}
                </Paragraph>
                <Paragraph center>
                  8 Sungei Kadut Street 6 Sinagpore 728856
                </Paragraph>
                <Paragraph center>
                  <strong>Tel:</strong> +65-63658781 | <strong>Fax:</strong> +65-63658780
                </Paragraph>
                <Paragraph center>
                  <strong>Website:</strong> <a href="https://www.global-group.com.sg">www.global-group.com.sg</a>
                </Paragraph>
              </div>
            </>
          ) : (
            <Grid container spacing={3} style={{ marginBottom: "2rem", justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'right' }}>
                <div>
                  <img src={logo} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Paragraph bold="700">
                  Global-Allied Pte Ltd
                </Paragraph>
                <Paragraph>
                  {t("Factory/Office/Showroom")}
                </Paragraph>
                <Paragraph>
                  8 Sungei Kadut Street 6 Sinagpore 728856
                </Paragraph>
                <Paragraph>
                  <strong>Tel:</strong> +65-63658781 | <strong>Fax:</strong> +65-63658780
                </Paragraph>
                <Paragraph>
                  <strong>Website:</strong> <a href="https://www.global-group.com.sg">www.global-group.com.sg</a>
                </Paragraph>
              </Grid>
            </Grid>
          )}
        </Section>
      </Container>

      <Footer />
    </React.Fragment >
  );
}
