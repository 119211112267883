import React, { useState } from "react";
import Slider from "react-slick";
import useStyles, { CarouselContainer, CarouselIMG, IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import silestone001 from "../../assets/images/silestone001.png";
import desc001 from "../../assets/images/desc001.png";
import gallery001 from "../../assets/images/gallery001.png";
import gallery002 from "../../assets/images/gallery002.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
} from "@mui/material";
import Button from "../../components/Button/Button";
import { Add, ArrowBackIos, ArrowDropDown, Download, HideImage, Remove } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_product_detail,
  get_gallery,
  get_random_gallery,
  get_related_product,
  get_related_image,
  get_user,
  get_related_description_image,
  get_download,
} from "../../API/API";
import useCart from "../../hooks/useCart";
import ReactImageMagnify from "react-image-magnify";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function ProductDetail() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { product_id, user_id } = useParams();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // API

  const [productData, setProductData] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [brandID, setBrandID] = React.useState("");
  const [seriesID, setSeriesID] = React.useState("");
  const [productID, setProductID] = React.useState("");
  const [user, setUser] = React.useState("");
  const [downloadImage, setDownloadImage] = React.useState([]);
  // const [productID, setProductID] = React.useState("");

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");
          localStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("isLoggedIn");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  const { hide_price } = user;

  // const [userType, setUserType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = window.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");

  //   let hide_price = window.localStorage.getItem("hide_price");
  //   setHidePrice(hide_price || "");
  // }, []);

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);

  const getProductData = () => {
    get_product_detail({ product_id: product_id }).then((json) => {
      setProductData(json.data);
      setBrandID(json.data.brand_id)
      setSeriesID(json.data.series_id)
      setProductID(json.data.product_id)
    });
  };

  // console.log("this is brand id", brandID)
  // console.log("this is series id", seriesID)
  // console.log("this is product id", productID)
  // console.log("this is product id", productID)

  // const getProductList = () => {
  //   get_news_latest().then((json) => {
  //     setProductList(json.data);
  //   });
  // };

  React.useEffect(() => {
    getProductData();
    // getGallery();
    getRandomGallery();
    getRelatedProduct();
    getRelatedImage();
    if (isLoggedIn) {
      getUser();
    }
    // getProductList();
    // getDownloadImage();
    getRelatedDescription();
  }, [
    brandID,
    seriesID,
    productID,
    isLoggedIn
  ]);

  // GET ENQUIRY

  const { addProduct, increaseProduct, decreaseProduct, getProductQuantityInCart } = useCart();


  const [qtyCount, setQtyCount] = React.useState(0)

  const handleChangeDecrease = (new_qty) => {



    if (qtyCount < 1) {
      return
    }
    setQtyCount(qtyCount - 1)
  }

  const handleIncreaseQuantity = () => {
    const maxStock = productData.stock;
    const currentQuantity = qtyCount || 0;

    console.log("stcok", maxStock);
    console.log("qty", currentQuantity)


    const productId = productData.product_id;
    const quantityInCart = getProductQuantityInCart(productId);

    console.log("product", productId)
    console.log("quantity", quantityInCart)

    const remainingQuantity = maxStock - quantityInCart - currentQuantity;

    if (remainingQuantity <= 0) {
      alert(t("Maximum stock reached for this product"))
      return;
    }

    const incrementAmount = Math.min(1, remainingQuantity);
    console.log("increment", incrementAmount)
    // const quantity = parseFloat(currentQuantity + incrementAmount);
    // qtyCount = parseFloat(quantity)

    // console.log("nani", qtyCount)

    // if (qtyCount >= productData.stock) {
    //   return; // Stop increment if quantity reaches maxStock
    // }
  
    setQtyCount(qtyCount + 1);
  };
  const handleAddToCart = (productData) => {
    if (productData.stock == 0) {
      alert(t('outOfStockProduct', { productData: productData.name }))
      // alert(`${productData.name} Is Out Of Stock`)
      return;
    }
    else if (qtyCount == 0) {
      alert(t("Please Choose the Quantity of Product"));
      return;
    }
    let prodList = {
      name: productData.name,
      single_price: businessType == 2 ? productData.individual_price : productData.business_price,
      // single_price: productData.business_price,
      // price: productData.business_price,
      // quantity: productData.quantity,
      image: productData.image,
      product_id: productData.product_id,
      category: productData.category,
      thickness: productData.thickness,
      brand: productData.brand,
      quantity: qtyCount,
    };
    const addedSuccessfully = addProduct(prodList);
    if (addedSuccessfully) {
      toast.success(t('productAdded', { qtyCount: qtyCount }))
      setQtyCount(0)
    }
    // toast.success(`${qtyCount} product added to cart`)
    // console.log(prodList);
  };


  const handleEnquire = () => {
    let prodList = {
      name: productData.name,
      single_price: businessType == 2 ? productData.individual_price : productData.business_price,
      // single_price: productData.business_price,
      // price: productData.business_price,
      // quantity: productData.quantity,
      image: productData.image,
      product_id: productData.product_id,
      category: productData.category,
      thickness: productData.thickness,
      brand: productData.brand,
      quantity: qtyCount,
    };
    // addProduct(prodList);
    console.log(prodList)
  };

  const [gallery, setGallery] = React.useState([]);

  const getGallery = () => {
    get_gallery().then((json) => {
      setGallery(json.data[0].gallery)
    })
  }

  const [randomGallery, setRandomGallery] = React.useState([]);

  const getRandomGallery = () => {
    get_random_gallery({ product_id: product_id }).then((json) => {
      setRandomGallery(json.data)
    });
  };

  const [relatedProduct, setRelatedProduct] = React.useState([]);

  const getRelatedProduct = () => {
    get_related_product({
      brand_id: brandID,
      series_id: seriesID,
      product_id: productID,
    }).then((json) => {
      setRelatedProduct(json.data)
    });
  };

  const [relatedImage, setRelatedImage] = React.useState([]);

  const getRelatedImage = () => {
    get_related_image({
      product_id: product_id,
    }).then((json) => {
      setRelatedImage(json.data)
    });
  };

  const [relatedDescription, setRelatedDescription] = React.useState([]);

  const getRelatedDescription = () => {
    get_related_description_image({
      product_id: product_id
    }).then((json) => {
      setRelatedDescription(json.data)
    });
  }

  // function downloadImage(dataUrl) {
  //   const a = document.createElement("a");

  //   a.setAttribute("download", productData.image);
  //   a.setAttribute("href", dataUrl);
  //   a.click();
  // }

  const [download, setDownload] = React.useState([]);

  const getDownload = () => {
    get_download({ product_id: product_id }).then((json) => {
      setDownload(json);
    });
  };

  const handleDownloadImage = () => {
    get_download({ product_id: product_id }).then((response) => {
      const isMobile = window.innerWidth <= 768;

      if (isMobile) {
        const url = URL.createObjectURL(response);
        window.open(url);
      } else {
        const blobUrl = URL.createObjectURL(response);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${productData.name}.jpeg`);
        link.click();

        URL.revokeObjectURL(blobUrl);
      }

    }).catch((error) => {
      console.error('Error downloading image:', error);
    });
  };

  // const handleDownloadImage = () => {
  //   fetch(downloadImage.image)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const isMobile = window.innerWidth <= 768;

  //       if (isMobile) {
  //         const url = URL.createObjectURL(blob);
  //         window.open(url);
  //       } else {
  //         saveAs(blob, `${productData.name}.jpeg`);
  //       }
  //     })
  //     .catch((error) => console.error('Error downloading image:', error));


  //   .then((blob) => saveAs(blob, 'image.jpg'))
  //   .catch((error) => console.error('Error downloading image:', error));
  // }

  const [selectedImage, setSelectedImage] = React.useState(productData.image)

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="8rem 0">
          <a style={{ display: "flex", alignItems: "center" }} href="/product">
            <ArrowBackIos style={{ fontSize: "14px" }} />
            <Paragraph size="14px">{t("Back")}</Paragraph>
          </a>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.theProdIMG}>
                <ReactImageMagnify {...{
                  smallImage: {
                    alt: 'productData.image',
                    src: selectedImage ? selectedImage : productData.image,
                    isFluidWidth: true,
                    width: 500,
                    height: 300,
                  },
                  largeImage: {
                    src: selectedImage ? selectedImage : productData.image,
                    width: 1800,
                    height: 1300,
                  },
                }}
                />
                {/* <img src={productData.image} /> */}
              </div>
              <div style={{ overflow: "auto", position: "relative", whiteSpace: "nowrap" }}>
                <div className={classes.theProdIMG1}>
                  <Slider className={classes.root} {...settings}>

                    {relatedImage != null &&
                      relatedImage.map((item) => {
                        return (
                          <CarouselContainer
                            onClick={() => setSelectedImage(item.image)}
                          >
                            <CarouselIMG src={item.image} />
                          </CarouselContainer>
                        );
                      })}

                  </Slider>
                </div>
              </div>
              <br />

              <div style={{ width: "40%" }}>
                <Button label={t("SAMPLE REQUEST")} color="#FFF" bg="#353535" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paragraph
                color="#353535"
                bold="700"
                size="24px"
                margin="0 0 .4rem 0"
              >
                {i18n.language === 'en' && productData.name}
                {i18n.language === 'my' && productData.malay_name}
                {i18n.language === 'my' && productData.malay_name == "" && productData.name}
                {i18n.language === 'cn' && productData.chinese_name}
                {i18n.language === 'cn' && productData.chinese_name == "" && productData.name}
              </Paragraph>
              <Paragraph>
                {i18n.language === 'en' && productData.brand}
                {i18n.language === 'my' && productData.malay_brand_name}
                {i18n.language === 'my' && productData.malay_brand_name == "" && productData.brand}
                {i18n.language === 'cn' && productData.chinese_brand_name}
                {i18n.language === 'cn' && productData.chinese_brand_name == "" && productData.brand}
              </Paragraph>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" margin="0 0 .4rem 0">
                      {t("Product Category")}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" margin="0 0 .4rem 0">
                      {t("Standard Size")}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" margin="0 0 .4rem 0">
                      {t("Thickness")}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    {businessType == 1 && hide_price == 0 ? (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        {t("Price")}
                      </Paragraph>
                    ) : businessType == 1 && hide_price == 1 ? (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        {Hidden}
                      </Paragraph>
                    ) : (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        <></>
                      </Paragraph>
                    )}

                    {businessType == 2 && hide_price == 0 ? (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        {t("Price")}
                      </Paragraph>
                    ) : businessType == 2 && hide_price == 1 ? (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        {Hidden}
                      </Paragraph>
                    ) : (
                      <Paragraph color="#353535" margin="0 0 .4rem 0">
                        <></>
                      </Paragraph>
                    )}

                    {/* <Paragraph color="#353535" margin="0 0 .4rem 0">
                      Price
                    </Paragraph> */}
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" margin="0 0 .4rem 0">
                      {t("Colour")}
                    </Paragraph>
                  </div>
                  {businessType == 1 && hide_price == 0 ? (
                    <div className={classes.theFlexQuantity}>
                      <div className={classes.theIncrement}>
                        <IncrementAction2
                          margin="0 1rem 0 0"
                          onClick={() => handleChangeDecrease()}
                        // onClick={()=>setQtyCount(qtyCount - 1)}
                        >
                          <Remove
                            className={classes.theButtonStyle2}
                          />
                        </IncrementAction2>

                        <div className={classes.theBox__}>
                          <p className={classes.theCount}>
                            {qtyCount}
                          </p>
                        </div>

                        <IncrementAction
                          margin="0 0 0 1rem"
                          onClick={() => handleIncreaseQuantity()}
                        >
                          <Add className={classes.theButtonStyle} />
                        </IncrementAction>
                      </div>
                    </div>
                  ) : businessType == 1 && hide_price == 1 ? (
                    <div>
                      {Hidden}
                    </div>
                  ) : (
                    <></>
                  )}

                  {businessType == 2 && hide_price == 0 ? (
                    <div className={classes.theFlexQuantity}>
                      <div className={classes.theIncrement}>
                        <IncrementAction2
                          margin="0 1rem 0 0"
                          onClick={() => handleChangeDecrease()}
                        // onClick={()=>setQtyCount(qtyCount - 1)}
                        >
                          <Remove
                            className={classes.theButtonStyle2}
                          />
                        </IncrementAction2>

                        <div className={classes.theBox__}>
                          <p className={classes.theCount}>
                            {qtyCount}
                          </p>
                        </div>

                        <IncrementAction
                          margin="0 0 0 1rem"
                          // onClick={() => {
                          //   if (qtyCount < productData.stock) {
                          //     setQtyCount(qtyCount + 1);
                          //   }
                          // }}
                          onClick={() => handleIncreaseQuantity()}
                        >
                          <Add className={classes.theButtonStyle} />
                        </IncrementAction>
                      </div>
                    </div>
                  ) : businessType == 2 && hide_price == 1 ? (
                    <div>
                      {Hidden}
                    </div>
                  ) : (
                    <></>
                  )}

                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" bold="600">
                      {i18n.language === 'en' && productData.category}
                      {i18n.language === 'my' && productData.malay_category_name}
                      {i18n.language === 'my' && productData.malay_category_name == "" && productData.category}
                      {i18n.language === 'cn' && productData.chinese_category_name}
                      {i18n.language === 'cn' && productData.chinese_category_name == "" && productData.category}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" bold="600">
                      {productData.size}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Paragraph color="#353535" bold="600">
                      {productData.thickness}
                    </Paragraph>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    {businessType == 1 && hide_price == 0 ? (
                      <Paragraph color="#353535" bold="600">
                        RM{productData.business_price} /{t("slab")}
                      </Paragraph>
                    ) : businessType == 1 && hide_price == 1 ? (
                      <Paragraph color="#353535" bold="600">
                        {Hidden}
                      </Paragraph>
                    ) : (
                      <Paragraph color="#353535" bold="600">
                        <></>
                      </Paragraph>
                    )}

                    {businessType == 2 && hide_price == 0 ? (
                      <Paragraph color="#353535" bold="600">
                        RM{productData.individual_price} /{t("slab")}
                      </Paragraph>
                    ) : businessType == 2 && hide_price == 1 ? (
                      <Paragraph color="#353535" bold="600">
                        {Hidden}
                      </Paragraph>
                    ) : (
                      <Paragraph color="#353535" bold="600">
                        <></>
                      </Paragraph>
                    )}

                    {/* <Paragraph color="#353535" bold="600">
                      RM {productData.business_price}
                    </Paragraph> */}
                  </div>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      marginBottom: "1rem",
                      background: productData.colour,
                    }}
                  ></div>
                  <div style={{ marginBottom: "1rem" }}>
                    <a
                      // href={productData.pdf_file}
                      style={{
                        display: "flex",
                        border: "1px solid #000",
                        padding: ".4rem 1rem",
                        borderRadius: "8px",
                        width: "62%",
                        justifyContent: "center",
                      }}
                      onClick={() => handleDownloadImage()}
                    >
                      <Download />
                      <Paragraph>{t("FULL VIEW")}</Paragraph>
                    </a>
                    {/* <Button
                      label="FULL VIEW"
                      br="1px solid #000"
                      style={{
                        display: "flex",
                        border: "1px solid #000",
                        padding: ".4rem 1rem",
                        borderRadius: "8px",
                        width: "62%",
                        justifyContent: "center",
                      }}
                      onClick={() => handleDownloadImage()}
                    /> */}
                  </div>
                  {businessType == 1 && hide_price == 0 ? (
                    <Button
                      label={t("ADD TO CART")}
                      box="1px solid #353535"
                      color="#FFF"
                      bg="#353535"
                      br="8px"
                      // className={classes.buttonHover}
                      style={{
                        display: "flex",
                        border: "1px solid #000",
                        padding: ".4rem 1rem",
                        borderRadius: "8px",
                        width: "62%",
                        justifyContent: "center",
                      }}
                      onClick={() => handleAddToCart(productData)}
                    />
                  ) : businessType == 1 && hide_price == 1 ? (
                    <div>
                      {Hidden}
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme='light'
                  /> */}

                  {businessType == 2 && hide_price == 0 ? (
                    <Button
                      label={t("ADD TO CART")}
                      box="1px solid #353535"
                      color="#FFF"
                      bg="#353535"
                      br="8px"
                      // className={classes.buttonHover}
                      style={{
                        display: "flex",
                        border: "1px solid #000",
                        padding: ".4rem 1rem",
                        borderRadius: "8px",
                        width: "62%",
                        justifyContent: "center",
                      }}
                      onClick={() => handleAddToCart(productData)}
                    />
                  ) : businessType == 2 && hide_price == 1 ? (
                    <div>
                      {Hidden}
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme='light'
                  /> */}

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />

          <div>
            <Paragraph
              size="24px"
              color="#353535"
              center
              margin="0 0 .4rem 0"
              bold="700"
              style={{ fontFamily: "'Gotu', sans-serif" }}
            >
              {t("PRODUCT DESCRIPTION")}
            </Paragraph>
            <div
              style={{
                border: "1px solid #353535",
                width: "34%",
                margin: "0 auto",
              }}
            />
            <br />
            <div className={classes.productDetail}>
              <div className={classes.theProdIMG}>
                <img src={productData.description_image} />
                {relatedDescription.map((item) => {
                  return (
                    <img src={item.image} />
                  )
                })}
              </div>
            </div>
          </div>


          {/* <div style={{ marginTop: "1rem" }}>
            <Paragraph color="#353535" margin="0 0 .4rem 0" size="20px">
              {productData.description}
            </Paragraph>
          </div> */}

          {/* <Paragraph
            dangerouslySetInnerHTML={{
              __html: productData.description,
            }}
          /> */}
          <br />
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paragraph
              size="24px"
              color="#353535"
              margin="0 0 1rem 0"
              bold="700"
              style={{ fontFamily: "'Gotu', sans-serif" }}
            >
              {t("Related Products")}
            </Paragraph>
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                background: "rgb(51, 51, 53)",
                margin: "0px 0px 0px 1rem",
              }}
            ></div>
          </div>

          <Grid container spacing={3}>
            {relatedProduct != null &&
              relatedProduct.map((product) => {
                return (
                  <Grid item xs={12} sm={6} md={3}>
                    <a href={`../productdetail/${product.product_id}`}>
                      <div className={classes.theGallery1}>
                        <img src={product.image} />
                        {product.status == "new" ? (
                          <div className={classes.theTag}>
                            <Paragraph color="#fff" bold="600">
                              {t("NEW")}
                            </Paragraph>
                          </div>
                        ) : (
                          <div>
                            {Hidden}
                          </div>
                        )}
                      </div>
                      <Paragraph color="#333335" size="12px" bold="100">
                        {i18n.language === 'en' && product.category}
                        {i18n.language === 'my' && product.malay_category_name}
                        {i18n.language === 'my' && product.malay_category_name == "" && product.category}
                        {i18n.language === 'cn' && product.chinese_category_name}
                        {i18n.language === 'cn' && product.chinese_category_name == "" && product.category}
                      </Paragraph>
                      <Paragraph color="#333335" bold="600">
                        {i18n.language === 'en' && product.name}
                        {i18n.language === 'my' && product.malay_name}
                        {i18n.language === 'my' && product.malay_name == "" && product.name}
                        {i18n.language === 'cn' && product.chinese_name}
                        {i18n.language === 'cn' && product.chinese_name == "" && product.name}
                      </Paragraph>
                    </a>
                  </Grid>
                );
              })}
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2rem 0 0 0",
            }}
          >
            <Paragraph
              size="24px"
              color="#353535"
              margin="0 0 1rem 0"
              bold="700"
              style={{ fontFamily: "'Gotu', sans-serif" }}
            >
              {t("Gallery")}
            </Paragraph>
            <div
              style={{
                flexGrow: 1,
                height: "1px",
                background: "rgb(51, 51, 53)",
                margin: "0px 0px 0px 1rem",
              }}
            ></div>
          </div>
          {/* <Paragraph
            color="#194234"
            size="28px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            Gallery
          </Paragraph> */}
          <Slider className={classes.root} {...settings2}>
            {randomGallery != null &&
              randomGallery.map((gallery) => {
                return (
                  <div className={classes.theGallery2}>
                    <img src={gallery.image} />
                  </div>
                );
              })}
          </Slider>

          {/* <Grid container spacing={3}>
            {randomGallery != null &&
              randomGallery.map((gallery) => {
                return (
                  <Grid item xs={12} sm={12} md={4}>
                    <Card style={{ padding: "1rem" }}>
                      <div className={classes.theGallery2}>
                        <img src={gallery.image} />
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <div>
                          <div style={{ display: "flex", margingBottom: ".4rem" }}>
                            <Paragraph bold="600" color="black">
                              Material:
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {gallery.category}
                            </Paragraph>
                          </div>
                          <div style={{ display: "flex", margingBottom: ".4rem" }}>
                            <Paragraph bold="600" color="black">
                              Brand:
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {gallery.brand}
                            </Paragraph>
                          </div>
                          <div style={{ display: "flex", margingBottom: ".4rem" }}>
                            <Paragraph bold="600" color="black">
                              Series:
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {gallery.series}
                            </Paragraph>
                          </div>
                          <div style={{ display: "flex", margingBottom: ".4rem" }}>
                            <Paragraph bold="600" color="black">
                              Color:
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {gallery.colour}
                            </Paragraph>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "88px",
                            height: "88px",
                            borderRadius: "50%",
                            background: gallery.colour_image,
                            border: "1px solid #000",
                          }}
                        ></div>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
          </Grid> */}
          <br /><br />

          {/* <Grid container spacing={3}>
            {gallery.map((item) => {
              return (
                <Grid item xs={12} sm={12} md={4}>
                  <Card style={{ padding: "1rem" }}>
                    <div className={classes.theGallery}>
                      <img src={item.image} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex", marginBottom: ".4rem" }}>
                          <Paragraph bold="600" color="#353535">
                            Material :
                          </Paragraph>
                          <Paragraph style={{ textIndent: "10px" }}>
                            {item.category}
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex", marginBottom: ".4rem" }}>
                          <Paragraph bold="600" color="#353535">
                            Brand :
                          </Paragraph>
                          <Paragraph style={{ textIndent: "10px" }}>
                            {item.brand}
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex", marginBottom: ".4rem" }}>
                          <Paragraph bold="600" color="#353535">
                            Series :
                          </Paragraph>
                          <Paragraph style={{ textIndent: "10px" }}>
                            {item.series}
                          </Paragraph>
                        </div>
                        <div style={{ display: "flex", marginBottom: ".4rem" }}>
                          <Paragraph bold="600" color="#353535">
                            Color :
                          </Paragraph>
                          <Paragraph style={{ textIndent: "10px" }}>
                            {item.colour}
                          </Paragraph>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "88px",
                          height: "88px",
                          borderRadius: "50%",
                          background: item.colour,
                          border: "1px solid #000",
                        }}
                      ></div>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <br /> */}

          {/* <div className={classes.productDetail}>
            <Grid container spacing={3}>
              {randomGallery.map((item) => {
                return(
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theGallery}>
                    <img src={item.image} />
                  </div>
                  <Paragraph color="#333335" size="12px" bold="100">
                    {item.category}
                  </Paragraph>
                  <Paragraph color="#333335" bold="600" margin="0 0 .6rem 0">
                    {item.name}
                  </Paragraph>
                </Grid>
                );
              })}
            </Grid>
          </div>
          <br /><br /> */}

          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.theGallery}>
                <img src={silestone001} />
                <div className={classes.theTag}>
                  <Paragraph color="#FFF" bold="600">
                    NEW
                  </Paragraph>
                </div>
              </div>
              <Paragraph color="#333335" size="12px" bold="100">
                Silestone
              </Paragraph>
              <Paragraph color="#333335" bold="600">
                1 GQ8002 CITRINE
              </Paragraph>
            </Grid>
          </Grid>
          <br />
          <br /> */}


        </Section>
      </Container>
      <div style={{ background: "#353535", padding: "2rem 1rem" }}>
        <Button
          label={t("GET ENQUIRY")}
          bg="#353535"
          color="#FFF"
          box="1px solid #FFF"
          br="10px"
          style={{ width: "30%", margin: "0 auto" }}
          onClick={() => handleEnquire()}
        />
      </div>
      <Footer />
    </React.Fragment>
  );
}
