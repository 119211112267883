import styled from "styled-components";
import { TextField, FormControl } from "@mui/material";
import { Link } from "react-router-dom";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bannerregister from "../../assets/images/bannerregister.png";

export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const NavbarContainer = styled.div`
  // background: #1B1D1E;
  /* background: rgb(255, 128, 1);
  background: linear-gradient(
    0deg,
    rgba(255, 128, 1, 1) 35%,
    rgba(255, 215, 95, 1) 100%
  ); */
  // display: flex;
  // align-items: center;
  // padding: 0.3rem 0;
  // box-shadow: 1px 8px 5px -3px rgba(240,240,240,1);

  @media screen and (min-width: 992px) {
    // padding: 1rem 0;
  }

  .MuiPaper-root {
    background-color: transparent;
    /* box-shadow: 0 0 5px 0 rgb(0 0 0 / 9%) !important; */
  }
`;

export const NavbarList = styled.div`
  display: block;
  width: 100%;
  // padding-top: 1rem;
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLower = styled.div`
  // justify-content: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  padding: 1rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NavbarLink = styled.a`
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold};
  align-items: center;
  display: flex;
  padding: 0 1rem;
  position: relative;
  font-size: 18px;

  @media screen and (min-width: 992px) {
    // padding: 0 1rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 1rem;
  }

  @media screen and (min-width: 1600px) {
    padding: 0 1rem;
  }

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled.a`
  width: 80px;
  // margin-left: 6rem;
  display: block;

  @media screen and (min-width: 992px) {
    width: 80px;
    margin-right: 2rem;
  }

  @media screen and (min-width: 1440px) {
    width: 80px;
    margin-right: 2rem;
  }
  /* padding-top: 8px; */
  img {
    width: 80px;

    @media screen and (min-width: 992px) {
      width: 80px;
    }

    @media screen and (min-width: 1440px) {
      width: 80px;
    }

    @media screen and (min-width: 1920px) {
      width: 80px;
    }
  }
`;

export const NavbarLogo_ = styled.a`
  margin: 0 auto;
  display: block;
  // border-bottom: 1px solid #9cacaf;
  padding-bottom: 1rem;

  img {
    width: 15%;
    margin: 0 auto;
    display: block;
  }
`;

export const NavbarIconList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarIconLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ShapeCircle = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #eeeeee;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);
  margin: ${({ margin }) => margin};

  @media screen and (min-width: 992px) {
    width: 40px;
    height: 40px;
    padding: 0.3rem 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const Icon = styled.img`
  width: 80%;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    width: 70%;
  }
`;

export const Badge = styled.span`
  border-radius: 50%;
  background: #5abb4a;
  color: #fff;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.2rem 0.4rem;
  display: initial;
  font-weight: 600;
  top: -20%;
  margin-left: -20px;
  position: absolute;
  right: -8px;
`;

export const NavbarMobileLogo = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 10vw 0 5vw;

  @media screen and (min-width: 768px) {
    margin: 0 0 0 24vw;
  }
  img {
    width: 70px;
    margin: 0.8rem 0.2rem;
    /* @media screen and (min-width: 768px) {
      width: 100px;
      margin: unset;
    } */
    @media screen and (min-width: 992px) {
      width: 100px;
    }
  }
`;

export const NavbarIconMobileList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;

  .MuiListItem-root {
    display: flex;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
`;

export const NavLinking = styled.a`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  // padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;

export const NavbarLeftList = styled.div`
  align-items: center;
  display: flex;
`;

export const SearchContainerDesktop = styled.div`
  align-items: center;
  display: flex;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 50%;
`;

export const Box = styled.div`
  padding: 0.66rem 1rem;
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f8f8f8;
  p {
    color: #000;
    margin: 0;
  }
`;

export const SearchField = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eeeeee;
  background-clip: padding-box;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const SearchButton = styled(Link)`
  /* background: #7f222c; */
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0.6rem 0.8rem;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  align-items: center;
  display: flex;
`;

export const SearchIcon = styled(Search)`
  color: ${({ color }) => color};
`;

export const ModalBox = styled.div`
  position: absolute;
  background-color: #2c3248;
  /* background-image: url("../assets/images/bgoverlaymodal.png"); */
  /* width: 100%;
	background-size: 100% 100%; */
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 0 5px #ccc; */
  /* padding: 1rem; */
  /* height: 750px; */
  overflow: scroll;
  border-radius: 25px;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 992px) {
    width: 40%;
  }
  @media screen and (min-width: 1920px) {
    width: 24%;
  }
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const CustomInput = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  //   background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  /* border-radius: 6px; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-indent: 14px; */
  width: 100%;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

const config = {
  theNavLink: {
    position: "relative",
    width: "5vw",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },
  sidenavbar: {
    width: 300,
  },
  theStickyHeader: {
    position: "relative",
    background: "#5b5b5b",
    padding: ".4rem 2rem",
  },
  theBox: {
    display: "flex",
    justifyContent: "end",
  },
  theFlex_: {
    display: "block",
    ...md("sm", {
      display: "flex",
      alignItems: "center",
    }),
  },
  theDivider: {
    borderBottom: "1px dashed #979797",
    margin: "2rem 0",
  },
  theBtnSize: {
    position: "relative",
    width: "100%",
    margin: "0 1rem 1rem 0",

    ...md("sm", {
      width: "22%",
    }),
  },
  theBtnSizex: {
    position: "relative",
    // width: "100%",
    margin: "0 1rem 2rem 0",
    fontSize: "14px",
  },
  theFlex__: {
    display: "block",
    flexWrap: "wrap",

    ...md("sm", {
      display: "flex",
      flexWrap: "wrap",
    }),
  },
  theFlexBtn: {
    display: "block",

    ...md("sm", {
      display: "flex",
    }),
  },

  theUserBox: {
    position: "relative",
  },

  theIconIMG: {
    position: "relative",
    width: "40px",
    margin: "0 auto",
    // marginRight: ".6rem",
    paddingBottom: ".6rem",

    ...md("sm", {
      width: "40px",
    }),

    ...md("md", {
      width: "30px",
    }),

    ...md("xmd", {
      width: "40px",
    }),

    ...md("lg", {
      width: "40px",
    }),

    "& img": {
      width: "100%",
    },
  },

  theIconIMG_: {
    position: "relative",
    width: "10%",
    marginRight: ".6rem",
    "& img": {
      width: "100%",
    },
  },

  theFont: {
    fontSize: "14px",
    transition: "0.3s",
    fontFamily: "'Gotu', sans-serif",


    // "&:hover": {
    //   // textDecoration: "underline",
    //   transform: "translateY(0) scale(1.1)",
    //   transition: "0.3s",
    // },

    ...md("sm", {
      fontSize: "12.5px",
    }),

    ...md("xmd", {
      fontSize: "14px",
    }),
  },

  theHead: {
    padding: "1rem 0",
    background: "#353535",
    ...md("md", {
      // padding: "1rem 6rem",
    }),
  },

  theRegister: {
    // position: "relative",
    // width: "100%",
    // "& img": {
    //   width: "100%",
    // },

    display: "none",
    ...md("md", {
      height: "105vh",
      backgroundImage: "url(" + bannerregister + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
    }),
  },

  // theMB: {
  //   marginTop: "2rem",
  //   display: "block",
  //   fontStyle: "italic",
  //   ...md("md", {
  //     marginTop: "0rem",
  //     fontStyle: "unset",
  //   }),
  // },

  theTabList: {
    display: "block",
    width: "100%",
    ...md("md", {
      display: "flex",
      justifyContent: "center",
    }),
  },

  theTabActive: {
    background: "#353535",
    border: "1px solid #353535",
    cursor: "pointer",
    padding: ".4rem 2rem",
    // borderRadius: "8px 0px 0px 8px"
    display: "flex",
    justifyContent: "center",
  },
  theTab: {
    background: "#FFF",
    border: "1px solid #353535",
    cursor: "pointer",
    padding: ".4rem 2rem",
    // borderRadius: "0px 8px 8px 0px"
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    background: '#fff',
    "&:hover": {
      opacity: 1,
    },
    '&:not(:hover)': {
      opacity: 0.8,
    },
  },
  navLink: {
    transition: "0.3s",

    "&:hover": {
      // textDecoration: "underline",
      transform: "translateY(0) scale(1.2)",
      transition: "0.3s",

    }
  }
};

export default makeStyles(config);
