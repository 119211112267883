import React from "react";
// import Slider from "react-slick";
import useStyles, {
  FormInputSelect,
  IncrementAction,
  IncrementAction2,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import bannerproduct from "../../assets/images/bannerproduct.png";

import silestone001 from "../../assets/images/silestone001.png";

import icon001 from "../../assets/images/icon001.svg";
import icon002 from "../../assets/images/icon002.svg";
import icon003 from "../../assets/images/icon003.svg";
import icon004 from "../../assets/images/icon004.svg";
import icon005 from "../../assets/images/icon005.svg";
import icon006 from "../../assets/images/icon006.svg";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Pagination,
  Hidden,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  Download,
  HighlightOff,
  Remove,
  Star,
} from "@mui/icons-material";
import "./styles.css";

import {
  get_area,
  get_banner,
  get_brand,
  get_category,
  get_colour,
  get_product,
  get_profile,
  get_surfaces,
  get_user,
} from "../../API/API";
import useCart from "../../hooks/useCart";
import { json, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import addNotification from "react-push-notification";
import { useTranslation } from "react-i18next";

export default function Product() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { user_id, page_id } = useParams();

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [sort, setSort] = React.useState(0);

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  // const [expanded, setExpanded] = React.useState(1);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [expanded, setExpanded] = React.useState([1]);
  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded((prevExpanded) => [...prevExpanded, panel]);
    } else {
      setExpanded((prevExpanded) =>
        prevExpanded.filter((item) => item !== panel)
      );
    }
  };

  // API

  const [category, setCategory] = React.useState([]);

  const [colour, setColour] = React.useState([]);

  // const [area, setArea] = React.useState([]);

  const [brand, setBrand] = React.useState([]);

  const [surfaces, setSurfaces] = React.useState([]);

  const [user, setUser] = React.useState([]);

  const [userID, setUserID] = React.useState([])

  const getCategory = () => {
    get_category().then((json) => {
      setCategory(json.data);
      // setChooseCategory(json.data[0].category_id)
      // console.log("SELECTED ",json.data[0].category_id)
    });
  };
  const getColour = () => {
    get_colour().then((json) => {
      setColour(json.data);
    });
  };
  // const getArea = () => {
  //   get_area().then((json) => {
  //     setArea(json.data);
  //   });
  // };
  const getBrand = () => {
    get_brand().then((json) => {
      setBrand(json.data);
    });
  };

  const getSurfaces = () => {
    get_surfaces().then((json) => {
      setSurfaces(json.data);
    })
  }

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");
          localStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("isLoggedIn");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  const { hide_price } = user;
  console.log(user)
  React.useEffect(() => {
    getCategory();
    getColour();
    // getArea();
    getBrand();
    getSurfaces();
    if (isLoggedIn) {
      getUser();
    }
    getBanner(page_id)
  }, [isLoggedIn]);

  // GET PRODUCT API

  const [product, setProduct] = React.useState([]);

  const [chooseCategory, setChooseCategory] = React.useState(0);

  const handleChooseCategory = (category_id) => {
    setChooseCategory(category_id);
    console.log(category_id);

    // const productList = [...product]


    // if(category_id){
    //   console.log(productList.length)
    //   setCountProd()
    // }
  };

  // React.useEffect(()=>{

  // },[chooseCategory])

  const [chooseBrand, setChooseBrand] = React.useState(0);

  const handleChooseBrand = (brand_id) => {
    setChooseBrand(brand_id);
    setChooseSubSeries(0);
    console.log(brand_id);
  };

  const [chooseSubSeries, setChooseSubSeries] = React.useState(0);

  const handleChooseSubSeries = (series_id) => {
    setChooseSubSeries(series_id);
    console.log(series_id);
  };

  const [chooseSurfaces, setchooseSurfaces] = React.useState(0);

  const handleChooseSurfaces = (surfaces_id) => {
    setchooseSurfaces(surfaces_id);
    console.log(surfaces_id);
  };

  const [chooseColour, setChooseColour] = React.useState(0);

  const handleChooseColour = (colour_id) => {
    setChooseColour(colour_id);
    console.log(colour_id);
  };

  const [chooseMinPrice, setChooseMinPrice] = React.useState(0);

  const [chooseMaxPrice, setChooseMaxPrice] = React.useState(0);

  const handleChangeResetFilter = () => {
    setChooseCategory(0);
    setChooseBrand(0);
    setChooseSubSeries(0);
    setchooseSurfaces(0);
    setChooseColour(0);
    setChooseMinPrice(0);
    setChooseMaxPrice(0);
    setValue([0, 100]);
  };


  const [countProd, setCountProd] = React.useState(0)

  const getProduct = () => {
    get_product({
      token: token,
      category_id: chooseCategory,
      brand_id: chooseBrand,
      series_id: chooseSubSeries,
      surfaces_id: chooseSurfaces,
      colour_id: chooseColour,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
    }).then((json) => {
      setProduct(json.data);
      setPageNum(json.pages);




      // setCountProd(json.data.length)
    });
  };



  React.useEffect(() => {
    getProduct();
  }, [
    chooseCategory,
    chooseBrand,
    chooseSubSeries,
    chooseSurfaces,
    chooseColour,
    chooseMinPrice,
    chooseMaxPrice,
    sort,
  ]);

  const [pageList, setPageList] = React.useState(1);

  const [pageNum, setPageNum] = React.useState("");

  const handleChangePage = (ispage) => {
    setPageList(ispage);
    get_product({
      category_id: chooseCategory,
      brand_id: chooseBrand,
      series_id: chooseSubSeries,
      surfaces_id: chooseSurfaces,
      colour_id: chooseColour,
      min_price: parseFloat(chooseMinPrice * 25),
      max_price: parseFloat(chooseMaxPrice * 25),
      sort_by: sort,
      page: ispage,
    }).then((json) => {
      setProduct(json.data);
    });
  };

  // PRODUCT FLITER PRICE RANGE

  const priceRange = [
    {
      value: 0,
      scaledValue: 0,
    },
    {
      value: 25,
      scaledValue: 1000,
    },
    {
      value: 50,
      scaledValue: 1250,
    },
    {
      value: 75,
      scaledValue: 1875,
    },
    {
      value: 100,
      scaledValue: 2500,
    },
    {
      value: 125,
      scaledValue: 3125,
    },
    {
      value: 150,
      scaledValue: 3750,
    },
    {
      value: 175,
      scaledValue: 4375,
    },
    {
      value: 200,
      scaledValue: 5000,
    },
  ];

  const scaleValues = (valueArray) => {
    let min = scale(valueArray[0]);
    let max = scale(valueArray[1]);

    return "RM " + min + " - " + "RM " + max;
  };

  const scale = (value) => {
    if (value === undefined) {
      return undefined;
    }
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = priceRange[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = priceRange[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  function numFormatter(num) {
    if (num >= 0) {
      return "RM " + num.toFixed(0);
    }
  }

  const [value, setValue] = React.useState([0, 100]);

  const handleChangeRange = (event, newValue) => {
    setValue(newValue);
  };

  const getValue = () => {
    setChooseMinPrice(value[0]);
    setChooseMaxPrice(value[1]);
  };

  // SEARCH

  React.useEffect(() => {
    getValue();
  }, [value]);

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  // GET BUSINESS TYPE

  // const [userType, setUserType] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = windows.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");
  // }, [])

  const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  React.useEffect(() => {
    let business_type = window.localStorage.getItem("business_type");
    setBusinessType(business_type || "");

    // let hide_price = window.localStorage.getItem("hide_price");
    // setHidePrice(hide_price || "");
  }, []);

  // COUNT QUANTITY

  const { addProduct, getProductQuantityInCart } = useCart();

  const handleDecreaseQuantity = (index) => {
    var cartTemp = [...product];

    console.log(cartTemp)



    if (cartTemp[index].hasOwnProperty("quantity") == false) {
      cartTemp[index].quantity = 0;
    }

    var quantity = parseFloat(cartTemp[index].quantity - 1);



    if (quantity > 0) {
      cartTemp[index]["quantity"] = parseFloat(quantity);
      setProduct(cartTemp);
    }
    setProduct(cartTemp);
  };

  // const handleIncreaseQuantity = (index) => {
  //   var cartTemp = [...product];
  //   const maxStock = cartTemp[index].stock;
  //   const currentQuantity = cartTemp[index].quantity || 0;


  //   if (cartTemp[index].hasOwnProperty("quantity") == false) {
  //     cartTemp[index].quantity = 0;
  //   }


  //   if (currentQuantity >= maxStock) {
  //     return; // Stop increment if quantity reaches maxStock
  //   } else {

  //     var quantity = parseFloat(cartTemp[index].quantity + 1);

  //     cartTemp[index]["quantity"] = parseFloat(quantity);

  //   }

  //   setProduct(cartTemp);
  // };

  const handleIncreaseQuantity = (index) => {
    const cartTemp = [...product];
    const productToUpdate = cartTemp[index];
    const maxStock = productToUpdate.stock;
    const currentQuantity = productToUpdate.quantity || 0;

    console.log("qty", currentQuantity)
  
    const productId = productToUpdate.product_id;
    const quantityInCart = getProductQuantityInCart(productId);

    console.log("product", productId)
    console.log("quantity", quantityInCart)
  
    const remainingQuantity = maxStock - quantityInCart - currentQuantity;
  
    if (remainingQuantity <= 0) {
      alert(t("Maximum stock reached for this product"))
      return; // Stop increment if remaining quantity needed to reach maxStock is zero or negative
    }
  
    const incrementAmount = Math.min(1, remainingQuantity);
    const quantity = parseFloat(currentQuantity + incrementAmount);
    cartTemp[index].quantity = parseFloat(quantity);
  
    setProduct(cartTemp);
  };

  // ADD TO CART


  const toastId = React.useRef(null);

  const handleAddToCart = (item) => {



    console.log("PRODUCT ID", item.product_id)

    if (item.stock == 0) {
      alert(t('outOfStockMessage', { item: item.name }));
      return;
    }
    else if (item.quantity == undefined || 0) {
      alert(t("Please Choose the Quantity of Product"));
      return;
    }
    else if (item.quantity > item.stock) {
      alert(t("Quantity exceeds available stock"));
      return;
    }
    let prodList = {
      name: item.name,
      single_price: businessType == 2 ? item.individual_price : item.business_price,
      // single_price: item.business_price,
      // price: item.business_price,
      quantity: item.quantity,
      image: item.image,
      product_id: item.product_id,
      category: item.category,
      texture: item.texture,
      brand: item.brand,
      stock: item.stock
    };

    const addedSuccessfully = addProduct(prodList);
    if (addedSuccessfully) {
      toast.success(t('ProductAddedToCart', { item: item.quantity }))
      
    }

    // if(! toast.isActive(toastId.current)) {
    //   toastId.current =  toast(t('ProductAddedToCart', { item: item.product_id }));
    // }



    // alert(`${item.quantity} product added to cart`);
    // console.log(prodList);
  };

  const [banner, setBanner] = React.useState();

  const getBanner = () => {
    get_banner({ page_id: 2 }).then((json) => {
      setBanner(json.data)
    })
  }

  return (
    <React.Fragment>
      <Navbar />

      <div>
        {banner != null &&
          banner.map((item) => {
            return (
              <div className={classes.bannerOverlay}>
                <div className={classes.theBanner}>
                  <img src={window.innerWidth > 968 ? item.pc_image : item.mobile_image} />
                </div>
                <div className={classes.textOverlay}>
                  <Paragraph
                    className={classes.theFontTitle}
                    // size="24px"
                    color="black"
                    bold="700"
                    margin="0 0 .4rem 0"
                    center
                    style={{ fontFamily: "'Gotu', sans-serif" }}
                  >
                    {t("SEARCH PRODUCT")}
                  </Paragraph>
                  <br />
                  <div className={classes.searchBarOverlay}>
                    <FormInputNoLabel
                      placeholder={t("Search Here")}
                      border="1px solid #acacac"
                      onChange={handleSearch}
                      value={searchKey}
                      bg="white"
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>


      {/* <div className={classes.theBanner}>
        <img src={bannerproduct} />
        <div className={classes.theBox1}>
          <Paragraph
            className={classes.theFontTitle}
            size="24px"
            color="black"
            bold="700"
            margin="0 0 .4rem 0"
            center
          >
            SEARCH PRODUCT
          </Paragraph>
        </div>
        <div className={classes.theBox}>
          <div className={classes.searchBar}>
            <FormInputNoLabel
              placeholder="Search Here"
              border="1px solid #acacac"
              onChange={handleSearch}
              value={searchKey}
              bg="white"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Paragraph
              className={classes.theFontTitle}
              color="black"
              bold="700"
              margin="0 1rem 0 0"
            >
              PRODUCT
            </Paragraph>
          </div>
        </div>
      </div> */}


      <Container>
        <Section pd="2rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              {/* <FormInputNoLabel
                placeholder="Search Here"
                border="1px solid #acacac"
                onChange={handleSearch}
                value={searchKey}
              /> */}
              <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                {t("Product Filter")}:
              </Paragraph>
              <Paragraph color="#353535" bold="600" margin="0 0 1rem 0">
                {t("All Type")}
              </Paragraph>
              <hr />
              <Accordion expanded={expanded.includes(1)} onChange={handleChange(1)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                  aria-controls="1"
                  id="1"
                >
                  <Paragraph size="16px" bold="600" color="#353535">
                    {t("Product Category")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="quartzstone"
                    value={chooseCategory}
                    name="radio-buttons-group"
                  >
                    {/* <FormControlLabel
                        value="quartzstone"
                        control={<Radio />}
                        label="Quartz Stone"
                      />
                      <FormControlLabel
                        value="sinteredstone"
                        control={<Radio />}
                        label="Sintered Stone"
                      />
                      <FormControlLabel
                        value="solidstone"
                        control={<Radio />}
                        label="Solid Stone"
                      /> */}
                    {category.map((item, index) => (
                      <div style={{ display: "flex" }} key={index}>
                        <FormControlLabel
                          value={item.category_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_category_name ||
                            i18n.language === 'my' && item.malay_category_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_category_name ||
                            i18n.language === 'cn' && item.chinese_category_name === "" && item.name
                          }
                          onChange={() => handleChooseCategory(item.category_id)}
                        />

                      </div>
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
              <hr />
              <Accordion expanded={expanded.includes(2)} onChange={handleChange(2)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                  aria-controls="2"
                  id="2"
                >
                  <Paragraph size="16px" bold="600" color="#353535">
                    {t("Brand")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="otto"
                    value={chooseBrand}
                    name="radio-buttons-group"
                  >
                    {/* <FormControlLabel
                        value="otto"
                        control={<Radio />}
                        label="OTTO"
                      />
                      <FormControlLabel
                        value="imperial"
                        control={<Radio />}
                        label="Imperial"
                      /> */}
                    {brand.map((item) => (
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={item.brand_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_brand_name ||
                            i18n.language === 'my' && item.malay_brand_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_brand_name ||
                            i18n.language === 'cn' && item.chinese_brand_name === "" && item.name
                          }
                          onChange={() => handleChooseBrand(item.brand_id)}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
              <hr />

              {brand.map((item) => (
                <>
                  {chooseBrand == item.brand_id && item.series.length > 0 && (
                    <>
                      <Accordion expanded={expanded.includes(`${item.brand_id}-series`)} onChange={handleChange(`${item.brand_id}-series`)}>
                        <AccordionSummary
                          expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                          aria-controls={`${item.brand_id}-series-content`}
                          id={`${item.brand_id}-series-header`}
                        >
                          <Paragraph size="16px" bold="600" color="#353535">
                            {t("Sub-Series")}
                          </Paragraph>
                        </AccordionSummary>
                        <AccordionDetails id={`${item.brand_id}-series-content`}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={chooseSubSeries}
                            name="radio-buttons-group"
                          >
                            {item.series.map((seriesItem) => (
                              <div style={{ display: "flex" }}>
                                <FormControlLabel
                                  value={seriesItem.series_id}
                                  control={<Radio />}
                                  label={
                                    i18n.language === 'en' && seriesItem.name ||
                                    i18n.language === 'my' && seriesItem.malay_series_name ||
                                    i18n.language === 'my' && seriesItem.malay_series_name === "" && seriesItem.name ||
                                    i18n.language === 'cn' && seriesItem.chinese_series_name ||
                                    i18n.language === 'cn' && seriesItem.chinese_series_name === "" && seriesItem.name
                                  }
                                  onChange={() => handleChooseSubSeries(seriesItem.series_id)}
                                />
                              </div>
                            ))}
                          </RadioGroup>
                        </AccordionDetails>
                      </Accordion>
                      <hr />
                    </>
                  )}
                </>
              ))}

              {/* {chooseBrand == 1 && (
                <>
                  <Accordion
                    expanded={expanded.includes(3)} onChange={handleChange(3)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="3"
                      id="3"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        OTTO {t("Sub-Series")}
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="otto+"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="otto+"
                          control={<Radio />}
                          label="OTTO +"
                        />
                        <FormControlLabel
                          value="ottoclassico"
                          control={<Radio />}
                          label="OTTO CLASSICO"
                        />
                        <FormControlLabel
                          value="ottomarbleline"
                          control={<Radio />}
                          label="OTTO MARBLELINE"
                        />
                        <FormControlLabel
                          value="ottoimpression"
                          control={<Radio />}
                          label="OTTO IMPRESSION"
                        />
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                </>
              )}

              {chooseBrand == 2 && (
                <>
                  <Accordion
                    expanded={expanded.includes(4)} onChange={handleChange(4)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                      aria-controls="4"
                      id="4"
                    >
                      <Paragraph size="16px" bold="600" color="#353535">
                        IMPERIAL {t("Sub-Series")}
                      </Paragraph>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="normalseries"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="normalseries"
                          control={<Radio />}
                          label="Normal Series"
                        />
                        <FormControlLabel
                          value="shinningseries"
                          control={<Radio />}
                          label="Shinning Series"
                        />
                        <FormControlLabel
                          value="marblelineseries"
                          control={<Radio />}
                          label="Marbleline Series"
                        />
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                  <hr />
                </>
              )} */}

              <Accordion expanded={expanded.includes(5)} onChange={handleChange(5)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                  aria-controls="5"
                  id="5"
                >
                  <Paragraph size="16px" bold="600" color="#353535">
                    {t("Surfaces")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="polished"
                    value={chooseSurfaces}
                    name="radio-buttons-group"
                  >
                    {/* <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Polished"
                      onClick={() => handleChooseSurfaces(1)}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Matte"
                      onClick={() => handleChooseSurfaces(2)}
                    /> */}
                    {surfaces.map((item) => (
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={item.surfaces_id}
                          control={<Radio />}
                          label={
                            i18n.language === 'en' && item.name ||
                            i18n.language === 'my' && item.malay_surfaces_name ||
                            i18n.language === 'my' && item.malay_surfaces_name === "" && item.name ||
                            i18n.language === 'cn' && item.chinese_surfaces_name ||
                            i18n.language === 'cn' && item.chinese_surfaces_name === "" && item.name
                          }
                          onChange={() => handleChooseSurfaces(item.surfaces_id)}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
              <hr />
              <Accordion expanded={expanded.includes(6)} onChange={handleChange(6)}>
                <AccordionSummary
                  expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                  aria-controls="6"
                  id="6"
                >
                  <Paragraph size="16px" bold="600" color="#353535">
                    {t("Colour")}
                  </Paragraph>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {/* <label className="theRadio">
                        <input type="radio" name="radio" />
                        <span
                          className="checkmark"
                          style={{ background: "#333335" }}
                        ></span>
                      </label>
                      <label className="theRadio">
                        <input type="radio" name="radio" />
                        <span
                          className="checkmark"
                          style={{ background: "#7B109B" }}
                        ></span>
                      </label> */}
                    {colour.map((item) => (
                      <label
                        className="theRadio"
                        onClick={() => handleChooseColour(item.colour_id)}
                      >
                        <input type="radio" name="radio" value={chooseColour} />
                        <span
                          className="checkmark"
                          style={{ background: item.color_code }}
                        ></span>
                      </label>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <hr />
              <Paragraph color="#353535" bold="600" margin="1rem 0 1rem 0">
                {t("Price Range")}
              </Paragraph>
              <Slider
                value={value}
                onChange={handleChangeRange}
                valueLabelDisplay="auto"
                // getAriaValueText={valuetext}
                min={0}
                step={1}
                max={200}
                valueLabelFormat={numFormatter}
                // marks={priceRange}
                scale={scaleValues}
              />
              <Paragraph color="#7C7C7C">
                {t("Price")}:
                <b style={{ color: "#353535", marginLeft: "1rem" }}>
                  {/* RM 0 - RM 3210 */}
                  {scaleValues(value)}
                </b>
              </Paragraph>
              <br />
              <br />
              <Button
                label={t("Reset Filter")}
                color="#FFF"
                bg="#353535"
                onClick={() => handleChangeResetFilter()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {window.innerWidth > 350 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={classes.theWidth}>
                    <Paragraph
                      color="#353535"
                      bold="500"
                      className={classes.theWidth01}
                    >
                      {t("Sort By")}:
                    </Paragraph>
                    <FormInputSelect className={classes.theWidth02}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sort}
                        defaultValue={sort}
                        onChange={handleChangeSort}
                        style={{ width: "80%" }}
                      >
                        <MenuItem value="0" style={{ color: "#000" }}>
                          {t("Latest to Oldest")}
                        </MenuItem>
                        <MenuItem value="1" style={{ color: "#000" }}>
                          {t("Oldest to Latest")}
                        </MenuItem>
                      </Select>
                    </FormInputSelect>
                  </div>
                  <a
                    style={{
                      display: "flex",
                      border: "1px solid #000",
                      padding: ".4rem 1rem",
                      borderRadius: "8px",
                    }}
                  >
                    <Download />
                    <Paragraph>{t("Download E-Catalog")}</Paragraph>
                  </a>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "1rem"
                    }}
                  >
                    <div className={classes.theWidth}>
                      <Paragraph
                        color="#353535"
                        bold="500"
                        className={classes.theWidth01}
                      >
                        {t("Sort By")}:
                      </Paragraph>
                      <FormInputSelect className={classes.theWidth02}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sort}
                          defaultValue={sort}
                          onChange={handleChangeSort}
                          style={{ width: "95%", marginLeft: "10px" }}
                        >
                          <MenuItem value="0" style={{ color: "#000" }}>
                            {t("Latest to Oldest")}
                          </MenuItem>
                          <MenuItem value="1" style={{ color: "#000" }}>
                            {t("Oldest to Latest")}
                          </MenuItem>
                        </Select>
                      </FormInputSelect>
                    </div>
                  </div>
                  <a
                    style={{
                      display: "flex",
                      border: "1px solid #000",
                      padding: ".4rem 1rem",
                      borderRadius: "8px",
                    }}
                  >
                    <Download />
                    <Paragraph>{t("Download E-Catalog")}</Paragraph>
                  </a>
                </>
              )}

              <br />

              <Grid container spacing={3}>
                {product != null &&
                  product.map((item, index) => {
                    if (searchKey != "") {
                      if (
                        item.name
                          .toUpperCase()
                          .indexOf(searchKey.toUpperCase()) <= -1
                      ) {
                        return;
                      }
                    }
                    return (
                      <Grid item xs={12} sm={6} md={4}>
                        {/* {businessType == 1 ? (
                          <>
                            <a href={`../productdetail/${item.product_id}`}>
                              <div className={classes.theGallery}>
                                <img src={item.image} />
                                <div className={classes.theTag}>
                                  <Paragraph color="#FFF" bold="600">
                                    NEW
                                  </Paragraph>
                                </div>
                              </div>
                              <Paragraph color="#333335" size="12px" bold="100">
                                {item.category}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .6rem 0"
                              >
                                {item.name}
                              </Paragraph>

                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRight: "1px solid #707070",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon001} />
                                  </div>
                                  <Paragraph size="14px">
                                    {item.stock_status}
                                  </Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paragraph size="14px">
                                    {item.stock}
                                  </Paragraph>
                                  <Paragraph margin="0 0 0 1rem" size="14px">
                                    Slabs
                                  </Paragraph>
                                </div>
                              </div>
                              <hr />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon004} />
                                  </div>
                                  <Paragraph size="12px">{item.size}</Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon005} />
                                  </div>
                                  <Paragraph size="12px">
                                    {item.surfaces}
                                  </Paragraph>
                                </div>
                              </div>
                            </a>
                            <>
                              <div className={classes.theFlexQuantity}>
                                <div className={classes.theIncrement}>
                                  <IncrementAction2
                                    margin="0 1rem 0 0"
                                    // onClick={() => {
                                    //   decreaseProduct(item.index);
                                    //   setQtyCount(qtyCount - 1);
                                    // }}
                                    onClick={() =>
                                      handleDecreaseQuantity(index)
                                    }
                                  >
                                    <Remove
                                      className={classes.theButtonStyle2}
                                    />
                                  </IncrementAction2>

                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>
                                      {item.hasOwnProperty("quantity") == true
                                        ? item.quantity
                                        : 0}
                                    </p>
                                  </div>

                                  <IncrementAction
                                    margin="0 0 0 1rem"
                                    onClick={() =>
                                      handleIncreaseQuantity(index)
                                    }
                                  >
                                    <Add className={classes.theButtonStyle} />
                                  </IncrementAction>
                                </div>
                              </div>

                              <Paragraph
                                color="#353535"
                                bold="700"
                                margin="0 0 1rem 0"
                                center
                              >
                                Price: RM{item.business_price} /slab
                              </Paragraph>
                              <Button
                                label="ADD TO CART"
                                box="1px solid #353535"
                                br="8px"
                                onClick={() => handleAddToCart(item)}
                              />
                            </>
                          </>
                        ) : (
                          <a href={`../productdetail/${item.product_id}`}>
                            <div className={classes.theGallery}>
                              <img src={item.image} />
                              <div className={classes.theTag}>
                                <Paragraph color="#FFF" bold="600">
                                  NEW
                                </Paragraph>
                              </div>
                            </div>
                            <Paragraph color="#333335" size="12px" bold="100">
                              {item.category}
                            </Paragraph>
                            <Paragraph
                              color="#333335"
                              bold="600"
                              margin="0 0 .6rem 0"
                            >
                              {item.name}
                            </Paragraph>
                          </a>
                        )} */}

                        {businessType == 1 ? (
                          <>
                            <a href={`../productdetail/${item.product_id}`}>
                              {item.cover_image == "" ? (
                                <div className={classes.theGallery}>
                                  <img src={item.image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={classes.theGallery}>
                                  <img src={item.cover_image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              )}
                              <Paragraph color="#333335" size="12px" bold="100">
                                {i18n.language === 'en' && item.category}
                                {i18n.language === 'my' && item.malay_category_name}
                                {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                                {i18n.language === 'cn' && item.chinese_category_name}
                                {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .6rem 0"
                              >
                                {i18n.language === 'en' && item.name}
                                {i18n.language === 'my' && item.malay_name}
                                {i18n.language === 'my' && item.malay_name == "" && item.name}
                                {i18n.language === 'cn' && item.chinese_name}
                                {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                              </Paragraph>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRight: "1px solid #707070",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon001} />
                                  </div>
                                  <Paragraph size="14px">
                                    {item.stock_status}
                                  </Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paragraph size="14px">
                                    {item.stock}
                                  </Paragraph>
                                  <Paragraph margin="0 0 0 1rem" size="14px">
                                    {t("Slabs")}
                                  </Paragraph>
                                </div>
                              </div>
                              <hr />
                              <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingBottom: "5px"
                              }}>
                                <Paragraph size="12px">{item.size}</Paragraph>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon004} />
                                  </div>
                                  <Paragraph size="12px">{item.thickness}</Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon005} />
                                  </div>
                                  <Paragraph size="12px">
                                    {i18n.language === 'en' && item.surfaces}
                                    {i18n.language === 'my' && item.malay_surfaces_name}
                                    {i18n.language === 'my' && item.malay_surfaces_name == "" && item.surfaces}
                                    {i18n.language === 'cn' && item.chinese_surfaces_name}
                                    {i18n.language === 'cn' && item.chinese_surfaces_name == "" && item.surfaces}
                                  </Paragraph>
                                </div>
                              </div>
                            </a>
                            <>

                              {businessType == 1 && hide_price == 0 ? (
                                <div className={classes.theFlexQuantity}>
                                  <div className={classes.theIncrement}>
                                    <IncrementAction2
                                      margin="0 1rem 0 0"
                                      // onClick={() => {
                                      //   decreaseProduct(item.index);
                                      //   setQtyCount(qtyCount - 1);
                                      // }}
                                      onClick={() =>
                                        handleDecreaseQuantity(index)
                                      }
                                    >
                                      <Remove
                                        className={classes.theButtonStyle2}
                                      />
                                    </IncrementAction2>

                                    <div className={classes.theBox}>
                                      <p className={classes.theCount}>
                                        {item.hasOwnProperty("quantity") == true
                                          ? item.quantity
                                          : 0}
                                      </p>
                                    </div>

                                    <IncrementAction
                                      margin="0 0 0 1rem"
                                      onClick={() =>
                                        handleIncreaseQuantity(index)
                                      }
                                    >
                                      <Add className={classes.theButtonStyle} />
                                    </IncrementAction>
                                  </div>
                                </div>
                              ) : businessType == 1 && hide_price == 1 ? (
                                <p className={classes.theCount}>
                                  {Hidden}
                                </p>
                              ) : (
                                <></>
                              )}
                              {/* <div className={classes.theFlexQuantity}>
                                <div className={classes.theIncrement}>
                                  <IncrementAction2
                                    margin="0 1rem 0 0"
                                    // onClick={() => {
                                    //   decreaseProduct(item.index);
                                    //   setQtyCount(qtyCount - 1);
                                    // }}
                                    onClick={() =>
                                      handleDecreaseQuantity(index)
                                    }
                                  >
                                    <Remove
                                      className={classes.theButtonStyle2}
                                    />
                                  </IncrementAction2>

                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>
                                      {item.hasOwnProperty("quantity") == true
                                        ? item.quantity
                                        : 0}
                                    </p>
                                  </div>

                                  <IncrementAction
                                    margin="0 0 0 1rem"
                                    onClick={() =>
                                      handleIncreaseQuantity(index)
                                    }
                                  >
                                    <Add className={classes.theButtonStyle} />
                                  </IncrementAction>
                                </div>
                              </div> */}

                              {/* new */}
                              {businessType == 1 && hide_price == 0 ? (
                                <Paragraph color="#353535" bold="700" margin="0 0 1rem 0" center>
                                  RM{item.business_price} /{t("slab")}
                                </Paragraph>
                              ) : businessType == 1 && hide_price == 1 ? (
                                <Paragraph color="#353535" bold="700" margin="0 0 1rem 0" center>
                                  {Hidden}
                                </Paragraph>
                              ) : (
                                <></>
                              )}

                              {businessType == 1 && hide_price == 0 ? (

                                <Button
                                  label={t("ADD TO CART")}
                                  box="1px solid #353535"
                                  br="8px"
                                  // className={classes.buttonHover}
                                  onClick={() => handleAddToCart(item)}
                                />
                              ) : businessType == 1 && hide_price == 1 ? (
                                <Button>{Hidden}</Button>
                              ) : (
                                <></>
                              )}
                              {/* <ToastContainer
                                position='top-right'
                                autoClose={2000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover={false}
                                theme='light'
                              /> */}

                              {/* <Button
                                label="ADD TO CART"
                                box="1px solid #353535"
                                br="8px"
                                onClick={() => handleAddToCart(item)}
                              /> */}
                            </>
                          </>
                        ) : businessType == 2 ? (
                          <>
                            <a href={`../productdetail/${item.product_id}`}>
                              {item.cover_image == "" ? (
                                <div className={classes.theGallery}>
                                  <img src={item.image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={classes.theGallery}>
                                  <img src={item.cover_image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              )}
                              <Paragraph color="#333335" size="12px" bold="100">
                                {i18n.language === 'en' && item.category}
                                {i18n.language === 'my' && item.malay_category_name}
                                {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                                {i18n.language === 'cn' && item.chinese_category_name}
                                {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .6rem 0"
                              >
                                {i18n.language === 'en' && item.name}
                                {i18n.language === 'my' && item.malay_name}
                                {i18n.language === 'my' && item.malay_name == "" && item.name}
                                {i18n.language === 'cn' && item.chinese_name}
                                {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                              </Paragraph>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRight: "1px solid #707070",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon001} />
                                  </div>
                                  <Paragraph size="14px">
                                    {item.stock_status}
                                  </Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paragraph size="14px">
                                    {item.stock}
                                  </Paragraph>
                                  <Paragraph margin="0 0 0 1rem" size="14px">
                                    {t("Slabs")}
                                  </Paragraph>
                                </div>
                              </div>
                              <hr />
                              <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingBottom: "5px"
                              }}>
                                <Paragraph size="12px">{item.size}</Paragraph>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon004} />
                                  </div>
                                  <Paragraph size="12px">{item.thickness}</Paragraph>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className={classes.theIcon}>
                                    <img src={icon005} />
                                  </div>
                                  <Paragraph size="12px">
                                    {i18n.language === 'en' && item.surfaces}
                                    {i18n.language === 'my' && item.malay_surfaces_name}
                                    {i18n.language === 'my' && item.malay_surfaces_name == "" && item.surfaces}
                                    {i18n.language === 'cn' && item.chinese_surfaces_name}
                                    {i18n.language === 'cn' && item.chinese_surfaces_name == "" && item.surfaces}
                                  </Paragraph>
                                </div>
                              </div>
                            </a>
                            <>
                              {businessType == 2 && hide_price == 0 ? (
                                <div className={classes.theFlexQuantity}>
                                  <div className={classes.theIncrement}>
                                    <IncrementAction2
                                      margin="0 1rem 0 0"
                                      // onClick={() => {
                                      //   decreaseProduct(item.index);
                                      //   setQtyCount(qtyCount - 1);
                                      // }}
                                      onClick={() =>
                                        handleDecreaseQuantity(index)
                                      }
                                    >
                                      <Remove
                                        className={classes.theButtonStyle2}
                                      />
                                    </IncrementAction2>

                                    <div className={classes.theBox}>
                                      <p className={classes.theCount}>
                                        {item.hasOwnProperty("quantity") == true
                                          ? item.quantity
                                          : 0}
                                      </p>
                                    </div>

                                    <IncrementAction
                                      margin="0 0 0 1rem"
                                      onClick={() =>
                                        handleIncreaseQuantity(index)
                                      }
                                    >
                                      <Add className={classes.theButtonStyle} />
                                    </IncrementAction>
                                  </div>
                                </div>
                              ) : businessType == 2 && hide_price == 1 ? (
                                <p className={classes.theCount}>
                                  {Hidden}
                                </p>
                              ) : (
                                <></>
                              )}
                              {/* <div className={classes.theFlexQuantity}>
                                <div className={classes.theIncrement}>
                                  <IncrementAction2
                                    margin="0 1rem 0 0"
                                    // onClick={() => {
                                    //   decreaseProduct(item.index);
                                    //   setQtyCount(qtyCount - 1);
                                    // }}
                                    onClick={() =>
                                      handleDecreaseQuantity(index)
                                    }
                                  >
                                    <Remove
                                      className={classes.theButtonStyle2}
                                    />
                                  </IncrementAction2>

                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>
                                      {item.hasOwnProperty("quantity") == true
                                        ? item.quantity
                                        : 0}
                                    </p>
                                  </div>

                                  <IncrementAction
                                    margin="0 0 0 1rem"
                                    onClick={() =>
                                      handleIncreaseQuantity(index)
                                    }
                                  >
                                    <Add className={classes.theButtonStyle} />
                                  </IncrementAction>
                                </div>
                              </div> */}

                              {/* new */}
                              {businessType == 2 && hide_price == 0 ? (
                                <Paragraph color="#353535" bold="700" margin="0 0 1rem 0" center>
                                  RM{item.individual_price} /{t("slab")}
                                </Paragraph>
                              ) : businessType == 2 && hide_price == 1 ? (
                                <Paragraph color="#353535" bold="700" margin="0 0 1rem 0" center>
                                  {Hidden}
                                </Paragraph>
                              ) : (
                                <></>
                              )}

                              {businessType == 2 && hide_price == 0 ? (
                                <Button
                                  label="ADD TO CART"
                                  box="1px solid #353535"
                                  br="8px"
                                  onClick={() => handleAddToCart(item)}
                                />
                              ) : businessType == 2 && hide_price == 1 ? (
                                <Button>{Hidden}</Button>
                              ) : (
                                <></>
                              )}
                              {/* <ToastContainer
                                position='top-right'
                                autoClose={2000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover={false}
                                theme='light'
                              /> */}

                              {/* <Button
                                label="ADD TO CART"
                                box="1px solid #353535"
                                br="8px"
                                onClick={() => handleAddToCart(item)}
                              /> */}
                            </>
                          </>

                        ) : (
                          <>
                            <a href={`../productdetail/${item.product_id}`}>
                              {item.cover_image == "" ? (
                                <div className={classes.theGallery}>
                                  <img src={item.image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={classes.theGallery}>
                                  <img src={item.cover_image} />
                                  {item.status == "new" ? (
                                    <div className={classes.theTag}>
                                      <Paragraph color="#fff" bold="600">
                                        {t("NEW")}
                                      </Paragraph>
                                    </div>
                                  ) : (
                                    <div>
                                      {Hidden}
                                    </div>
                                  )}
                                </div>
                              )}
                              <Paragraph color="#333335" size="12px" bold="100">
                                {i18n.language === 'en' && item.category}
                                {i18n.language === 'my' && item.malay_category_name}
                                {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                                {i18n.language === 'cn' && item.chinese_category_name}
                                {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                              </Paragraph>
                              <Paragraph
                                color="#333335"
                                bold="600"
                                margin="0 0 .6rem 0"
                              >
                                {i18n.language === 'en' && item.name}
                                {i18n.language === 'my' && item.malay_name}
                                {i18n.language === 'my' && item.malay_name == "" && item.name}
                                {i18n.language === 'cn' && item.chinese_name}
                                {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                              </Paragraph>
                            </a>
                          </>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
              <br />
              <br />
              <Stack
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  page={pageList}
                  onChange={handleChangePage}
                  count={pageNum}
                  defaultPage={pageList}
                // count={10}
                />
              </Stack>
            </Grid>
          </Grid>
        </Section >
      </Container >

      <Footer />
    </React.Fragment >
  );
}
