import React from "react";
import Slider from "react-slick";
import useStyles, {
  CarouselContainer,
  CarouselIMG,
  IncrementAction,
  IncrementAction2,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import icon001 from "../../assets/images/icon001.svg"
import icon004 from "../../assets/images/icon004.svg"
import icon005 from "../../assets/images/icon005.svg"

import bannergallery from "../../assets/images/bannergallery.png";

import gallery002 from "../../assets/images/gallery002.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  Hidden,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  HighlightOff,
  Remove,
  Star,
} from "@mui/icons-material";
import { get_area, get_banner, get_gallery, get_gallery_product, get_related_colour, get_user } from "../../API/API";
import { useNavigate, useParams } from "react-router-dom";
import useCart from "../../hooks/useCart";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../hooks/LoadingSreen";

export default function Gallery() {
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let { page_id, user_id } = useParams();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: false,
    arrows: false,
    // infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          // initialSlide: 0,
        },
      },
      {
        breakpoint: 480,

        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // initialSlide: 0,
        },
      },
    ],
  };

  const [banner, setBanner] = React.useState([]);

  const getBanner = (page_id) => {
    get_banner({ page_id: 5 }).then((json) => {
      setBanner(json.data)
    })
  }

  const [tab, setTab] = React.useState("0");

  const handleChangeTab = (area_id) => {
    setTab(area_id);
  };

  const [gallery, setGallery] = React.useState([]);
  const [area, setArea] = React.useState([]);
  const [galleryID, setGalleryID] = React.useState("");
  const [user, setUser] = React.useState([]);

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const getUser = () => {
    get_user({ token: token })
      .then((json) => {
        if (json.status) {
          setUser(json.data);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("business_type");
          localStorage.removeItem("isLoggedIn");
          sessionStorage.removeItem("isLoggedIn");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };


  // const [userType, setUserType] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = window.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");
  // }, [])

  // const [businessType, setBusinessType] = React.useState([]);
  // const [hidePrice, setHidePrice] = React.useState([]);

  // React.useEffect(() => {
  //   let business_type = window.localStorage.getItem("business_type");
  //   setBusinessType(business_type || "");

  //   let hide_price = window.localStorage.getItem("hide_price");
  //   setHidePrice(hide_price || "");
  // }, []);

  const getGallery = () => {
    get_gallery({ area_id: tab }).then((json) => {
      setGallery(json.data);
    });
  };

  const getArea = () => {
    get_area().then((json) => {
      setArea(json.data);
    });
  };

  React.useEffect(() => {
    getGallery();
    getArea();
    getBanner(page_id);
    if (isLoggedIn) {
      getUser();
    }
  }, [isLoggedIn]);

  const [openModal, setOpenModal] = React.useState(false);

  const handleClose = () => setOpenModal(false);

  const [openContent, setOpenContent] = React.useState([])
  const handleOpen = (content) => {
    setOpenContent([content])
    setOpenModal(true)
  }

  const { addProduct } = useCart();


  // const handleDecreaseQuantity = (index) => {
  //   var cartTemp = [...openContent];

  //   if (cartTemp[index].hasOwnProperty("quantity") == false) {
  //     cartTemp[index].quantity = 0;
  //   }

  //   var quantity = parseFloat(cartTemp[index].quantity - 1);

  //   if (quantity > 0) {
  //     cartTemp[index]["quantity"] = parseFloat(quantity);
  //     setOpenContent(cartTemp);
  //   }
  //   setOpenContent(cartTemp);
  // };

  // const handleIncreaseQuantity = (index) => {
  //   var cartTemp = [...openContent];

  //   if (cartTemp[index].hasOwnProperty("quantity") == false) {
  //     cartTemp[index].quantity = 0;
  //   }

  //   var quantity = parseFloat(cartTemp[index].quantity + 1);

  //   cartTemp[index]["quantity"] = parseFloat(quantity);

  //   setOpenContent(cartTemp);
  // };

  // const handleAddToCart = (item) => {
  //   if (item.quantity == undefined) {
  //     alert("Please Choose the Quantity of Product");
  //     return;
  //   }
  //   let prodList = {
  //     name: item.name,
  //     single_price: businessType == 2 ? item.individual_price : item.business_price,
  //     // single_price: item.business_price,
  //     // price: item.business_price,
  //     quantity: item.quantity,
  //     image: item.image,
  //     product_id: item.product_id,
  //     category: item.category,
  //     texture: item.texture,
  //     brand: item.brand,
  //   };
  //   addProduct(prodList);
  //   // console.log(prodList);
  // };

  // LOADING SCREENTIMEOUT

  const [isLoading, setIsLoading] = React.useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <React.Fragment>
      {/* {isLoading ? <></> :
        <> */}
      <Navbar />

      <div>
        <Slider className={classes.root3} {...settings}>
          {banner != null &&
            banner.map((item) => {
              return (
                <>
                  <div className={classes.bannerOverlay}>
                    <div className={classes.theBanner}>
                      <CarouselContainer>
                        <CarouselIMG src={window.innerWidth > 968 ? item.pc_image : item.mobile_image} />
                      </CarouselContainer>
                    </div>
                    {/* <div className={classes.textOverlay}>
                    <Paragraph
                      className={classes.theFontTitle}
                      size="50px"
                      color="black"
                      bold="700"
                      margin="0 0 .4rem 0"
                    >
                      {item.text}
                    </Paragraph>
                  </div> */}
                  </div>
                </>
              )
            })}
        </Slider>
      </div>

      {/* <div className={classes.theBanner}>
        <img src={bannergallery} />
      </div> */}
      <Container>
        <Section pd="2rem 0">
          {window.innerWidth > 768 ? (
            <div className={classes.theFlex}>
              <div className={classes.theTabList}>
                {area.map((item, index) => (
                  <div key={index}>
                    <div
                      key={item.area_id}
                      className={
                        tab === item.area_id ? classes.theTabActive : classes.theTab
                      }
                      onClick={() => handleChangeTab(item.area_id)}
                    >
                      <Paragraph
                        bold={tab == item.area_id ? "bold" : "normal"}
                        margin="0 0 .4rem 0"
                        style={{ textTransform: "uppercase" }}
                      >
                        {i18n.language === 'en' && item.name}
                        {i18n.language === 'my' && item.malay_area_name}
                        {i18n.language === 'my' && item.malay_area_name == "" && item.name}
                        {i18n.language === 'cn' && item.chinese_area_name}
                        {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
                      </Paragraph>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            // <Slider className={classes.root2} {...settings2}>
            //   {area.map((item, index) => (
            //     <div key={index}>
            //       <div
            //         key={item.area_id}
            //         className={
            //           tab === item.area_id ? classes.theTabActive1 : classes.theTab1
            //         }
            //         onClick={() => handleChangeTab(item.area_id)}
            //       >
            //         <Paragraph
            //           bold={tab == item.area_id ? "bold" : "normal"}
            //           margin="0 0 .4rem 0"
            //           center
            //           style={{ textTransform: "uppercase" }}
            //         >
            //           {i18n.language === 'en' && item.name}
            //           {i18n.language === 'my' && item.malay_area_name}
            //           {i18n.language === 'my' && item.malay_area_name == "" && item.name}
            //           {i18n.language === 'cn' && item.chinese_area_name}
            //           {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
            //         </Paragraph>
            //       </div>
            //     </div>
            //   ))}
            // </Slider>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", overflowX: "scroll" }}>
              {area.map((item, index) => (
                <div key={index}>
                  <div
                    key={item.area_id}
                    className={
                      tab === item.area_id ? classes._theTabActive1 : classes._theTab1
                    }
                    onClick={() => handleChangeTab(item.area_id)}
                  >
                    <Paragraph
                      bold={tab == item.area_id ? "bold" : "normal"}
                      margin="0 0 .4rem 0"
                      center
                      style={{ textTransform: "uppercase" }}
                    >
                      {i18n.language === 'en' && item.name}
                      {i18n.language === 'my' && item.malay_area_name}
                      {i18n.language === 'my' && item.malay_area_name == "" && item.name}
                      {i18n.language === 'cn' && item.chinese_area_name}
                      {i18n.language === 'cn' && item.chinese_area_name == "" && item.name}
                    </Paragraph>
                  </div>
                </div>
              ))}
            </Box>
          )}
          {/* <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Paragraph
            margin="0 0 .4rem 0"
            style={{ textTransform: "uppercase", cursor: "pointer" }}
            onClick={() => handleChangeTab("0")}
          >
            Show All
          </Paragraph>
          </div> */}
          <br />
          {/* {tab == tab && ( */}
          <Grid container spacing={3}>
            {gallery.map((content) => (
              tab == "0" || content.area_id === tab ? (
                <Grid item xs={12} sm={12} md={4}>
                  <a onClick={() => handleOpen(content)}>
                    <Card style={{ padding: "1rem" }}>
                      <div className={classes.theGallery}>
                        <img src={content.image} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex", marginBottom: ".4rem" }}>
                            <Paragraph bold="600" color="#353535">
                              {t("Material")} :
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {i18n.language === 'en' && content.category}
                              {i18n.language === 'my' && content.malay_category_name}
                              {i18n.language === 'my' && content.malay_category_name == "" && content.category}
                              {i18n.language === 'cn' && content.chinese_category_name}
                              {i18n.language === 'cn' && content.chinese_category_name == "" && content.category}
                            </Paragraph>
                          </div>
                          <div style={{ display: "flex", marginBottom: ".4rem" }}>
                            <Paragraph bold="600" color="#353535">
                              {t("Brand")} :
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {i18n.language === 'en' && content.brand}
                              {i18n.language === 'my' && content.malay_brand_name}
                              {i18n.language === 'my' && content.malay_brand_name == "" && content.brand}
                              {i18n.language === 'cn' && content.chinese_brand_name}
                              {i18n.language === 'cn' && content.chinese_brand_name == "" && content.brand}
                            </Paragraph>
                          </div>
                          <div style={{ display: "flex", marginBottom: ".4rem" }}>
                            <Paragraph bold="600" color="#353535">
                              {t("Series")} :
                            </Paragraph>
                            <Paragraph style={{ textIndent: "10px" }}>
                              {i18n.language === 'en' && content.series}
                              {i18n.language === 'my' && content.malay_series_name}
                              {i18n.language === 'my' && content.malay_series_name == "" && content.series}
                              {i18n.language === 'cn' && content.chinese_series_name}
                              {i18n.language === 'cn' && content.chinese_series_name == "" && content.series}
                            </Paragraph>
                          </div>
                          {/* <div style={{ display: "flex", marginBottom: ".4rem" }}>
                          <Paragraph bold="600" color="#353535">
                            Color :
                          </Paragraph>
                          <Paragraph style={{ textIndent: "10px" }}>
                            {item.colour}
                          </Paragraph>
                        </div> */}
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {content.gallery_product.map((a) => (
                          <div>
                            {a.gallery_product_detail.map((item) => (
                              <div
                                key={a.image}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                  marginRight: "-20px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  src={item.image}
                                  alt="Color"
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </Card>
                  </a>
                </Grid>
              ) : null
            ))}

          </Grid>
          {/* )} */}

          {openContent.map((content, index) => {
            if (content.gallery_product.length === 0) {
              return null;
            }

            return (
              <Modal
                open={openModal}
                onClose={handleClose}
              >
                <div className={classes.modalContainer}>
                  {openContent.map((content, index) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {content.gallery_product.map((a) => (
                          <div>
                            {a.gallery_product_detail.map((item) => {
                              return (
                                <div style={{ margin: "1rem" }}>
                                  <>
                                    <a href={`../productdetail/${item.product_id}`}>
                                      <div className={classes.theGallery1}>
                                        <img src={item.image} />
                                        {item.status == "new" ? (
                                          <div className={classes.theTag}>
                                            <Paragraph color="#fff" bold="600">
                                              {t("NEW")}
                                            </Paragraph>
                                          </div>
                                        ) : (
                                          <div>
                                            {Hidden}
                                          </div>
                                        )}
                                      </div>
                                      <Paragraph color="#333335" size="12px" bold="100">
                                        {i18n.language === 'en' && item.category}
                                        {i18n.language === 'my' && item.malay_category_name}
                                        {i18n.language === 'my' && item.malay_category_name == "" && item.category}
                                        {i18n.language === 'cn' && item.chinese_category_name}
                                        {i18n.language === 'cn' && item.chinese_category_name == "" && item.category}
                                      </Paragraph>
                                      <Paragraph
                                        color="#333335"
                                        bold="600"
                                        margin="0 0 .6rem 0"
                                      >
                                        {i18n.language === 'en' && item.name}
                                        {i18n.language === 'my' && item.malay_name}
                                        {i18n.language === 'my' && item.malay_name == "" && item.name}
                                        {i18n.language === 'cn' && item.chinese_name}
                                        {i18n.language === 'cn' && item.chinese_name == "" && item.name}
                                      </Paragraph>
                                    </a>
                                  </>

                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    )
                  })}
                </div>
              </Modal>
            );
          })}


          {/* <Modal
            open={openModal}
            onClose={handleClose}
          >
            <div className={classes.modalContainer}>
              <Grid container spacing={3}>
                {galleryProd.map((item) => (
                  <Grid item xs={12} sm={12} md={4}>
                    <a onClick={handleOpen}>
                    <Card style={{ padding: "1rem" }}>
                      {item.gallery_product.map((a) => (
                        <div>
                      {item.gallery_product_detail.map((b) => (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <a href={`../productdetail/${b.product_id}`}>
                            <div className={classes.theGallery1}>
                              <img src={b.image} />
                            </div>
                            <Paragraph color="#333335" size="12px" bold="100">
                              {b.category}
                            </Paragraph>
                            <Paragraph color="#333335" bold="600">
                              {b.name}
                            </Paragraph>
                          </a>
                        </div>
                      ))}
                      </div>
                      ))}
                    </Card>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </div>

          </Modal> */}
          {/* {tab == 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ padding: "1rem" }}>
                  <div className={classes.theGallery}>
                    <img src={gallery002} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Material :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          Sintered Stone
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Brand :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Series :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO+
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Color :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          G56024 Artic Grey
                        </Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "88px",
                        height: "88px",
                        borderRadius: "50%",
                        background: "#878787",
                        border: "1px solid #000",
                      }}
                    ></div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {tab == 2 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ padding: "1rem" }}>
                  <div className={classes.theGallery}>
                    <img src={gallery002} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Material :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          Sintered Stone
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Brand :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Series :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO+
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Color :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          G56024 Artic Grey
                        </Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "88px",
                        height: "88px",
                        borderRadius: "50%",
                        background: "#878787",
                        border: "1px solid #000",
                      }}
                    ></div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {tab == 3 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ padding: "1rem" }}>
                  <div className={classes.theGallery}>
                    <img src={gallery002} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Material :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          Sintered Stone
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Brand :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Series :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO+
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Color :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          G56024 Artic Grey
                        </Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "88px",
                        height: "88px",
                        borderRadius: "50%",
                        background: "#878787",
                        border: "1px solid #000",
                      }}
                    ></div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {tab == 4 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ padding: "1rem" }}>
                  <div className={classes.theGallery}>
                    <img src={gallery002} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Material :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          Sintered Stone
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Brand :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Series :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO+
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Color :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          G56024 Artic Grey
                        </Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "88px",
                        height: "88px",
                        borderRadius: "50%",
                        background: "#878787",
                        border: "1px solid #000",
                      }}
                    ></div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
          {tab == 5 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ padding: "1rem" }}>
                  <div className={classes.theGallery}>
                    <img src={gallery002} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Material :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          Sintered Stone
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Brand :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Series :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          OTTO+
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex", marginBottom: ".4rem" }}>
                        <Paragraph bold="600" color="#353535">
                          Color :
                        </Paragraph>
                        <Paragraph style={{ textIndent: "10px" }}>
                          G56024 Artic Grey
                        </Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "88px",
                        height: "88px",
                        borderRadius: "50%",
                        background: "#878787",
                        border: "1px solid #000",
                      }}
                    ></div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )} */}
        </Section>
      </Container>

      <Footer />
      {/* </>
      } */}
    </React.Fragment>
  );
}
