import React from "react";
import FormLabel from "../Typography/FormLabel";
import { FormGroup, TextInput } from "./styles";

const FormInputNoLabel = ({
  children,
  textLabelColor,
  bold,
  label,
  margin,
  bg,
  textcolor,
  mb,
  border,
  ...props
}) => {
  return (
    <FormGroup style={{width: "100%"}}  >
      {/* <FormLabel textLabelColor={textLabelColor} bold={bold} margin={margin}>
        {label}
      </FormLabel> */}
      <TextInput  bg={bg} textcolor={textcolor} border={border} mb={mb} {...props}>
        {children}
      </TextInput>
    </FormGroup>
  );
};

export default FormInputNoLabel;
