import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../language/en.json"
import translationCN from "../language/cn.json";
import translationMY from "../language/my.json";

const resources = {
  en: {
    translation: translationEN,
  },
  cn: {
    translation: translationCN,
  },
  my: {
    translation: translationMY,
  },
};

const langKey = 'lang';

const savedLanguage = localStorage.getItem(langKey);

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function setLanguage(lang) {
  i18n.changeLanguage(lang);
  localStorage.setItem(langKey, lang);
}

export { setLanguage };

export default i18n;
