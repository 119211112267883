import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import {
  Person,
  Lock,
  Bookmark,
  Inbox,
  Close,
  Edit,
  Delete,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import {
  add_address,
  delete_address,
  edit_address,
  edit_profile,
  get_address,
  get_address_detail,
  get_orders_history,
  get_profile,
  get_user,
  reset_password,
} from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Account() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleOpenEditModal = (user_address_id) => {
    setOpenEditModal(true, user_address_id);
    setAddressBookID(user_address_id)
  };

  const [checked, setChecked] = React.useState(true);

  // const [defaultAddress, setDefaultAddress] = React.useState(1);

  // const handleChangeCheck = (event) => {
  //   setChecked(event.target.checked);

  //   console.log(event.target.checked);

  //   if (event.target.checked == true) {
  //     setDefaultAddress(1);
  //   }

  //   if (event.target.checked == false) {
  //     setDefaultAddress(0);
  //   }
  // };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const [profileData, setProfileData] = React.useState({
    name: "",
    contact: "",
  });

  const [addressData, setAddressData] = React.useState({
    name: "",
    address: "",
    address2: "",
    state: "",
    country: "",
    phone: "",
    city: "",
    postcode: "",
  });

  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const [addressList, setAddressList] = React.useState([]);

  const [addressBookID, setAddressBookID] = React.useState(0);

  const [defaultAddressChanged, setDefaultAddressChanged] = React.useState(false);

  const [addressBookDetail, setAddressBookDetail] = React.useState({
    name: "",
    address: "",
    address2: "",
    state: "",
    country: "",
    phone: "",
    city: "",
    postcode: "",
  });

  const [defaultAddress, setDefaultAddress] = React.useState("");

  React.useEffect(() => {
    setAddressBookDetail(prevDetail => ({
      ...prevDetail,
      default_address: defaultAddress
    }));
  }, [defaultAddress]);

  const handleCheckboxChange = () => {
    setDefaultAddress(prevAddress => (prevAddress === "0" ? "1" : "0"));
    setDefaultAddressChanged(!defaultAddressChanged);
    setUserID(userID)
    setAddressBookID(addressBookID)
  };

  React.useEffect(() => {
    console.log("this is changed default address:", defaultAddress);
    console.log("user ID:", userID);
    console.log("user Address ID", addressBookID)
  }, [defaultAddress]);


  const [orderHis, setOrderHis] = React.useState([]);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
    setAddressBookDetail({
      ...addressBookDetail,
      [e.target.name]: e.target.value,
    });
  };

  const getProfile = () => {
    get_profile({ token: token }).then((json) => {
      setProfileData(json.data);
    });
  };

  const getOrderHistory = () => {
    get_orders_history({ token: token }).then((json) => {
      setOrderHis(json.data);
    });
  };

  const getAddressBook = () => {
    get_address({ token: token }).then((json) => {
      setAddressList(json.data);
    });
  };

  const [userID, setUserID] = React.useState("");

  const getAddressBookDetail = () => {
    get_address_detail({ user_address_id: addressBookID }).then((json) => {
      setAddressBookDetail(json.data);
      setUserID(json.data.user_id);
      setDefaultAddress(json.data.default_address)
    });
    console.log("This is the user ID", userID)
    console.log("This is the user address ID", addressBookID)
    console.log("This is the default",)
  };

  React.useEffect(() => {
    getProfile();
    getOrderHistory();
    getAddressBook();
    getAddressBookDetail();
    getUser();
  }, [
    addressBookID,
    userID,
  ]);

  // EDIT PROFILE

  const handleEditProfile = () => {
    if (profileData.name.length == "" || profileData.contact.length == "") {
      alert(t("All Field Required"));
    } else {
      edit_profile({
        ...profileData,
        token: token,
      }).then((json) => {
        if (json.status) {
          const translatedMessage = json[`${i18n.language}_message`] || json.message;
          alert(t(`${translatedMessage}`));
          window.location.reload();
        } else {
          const translatedMessage = json[`${i18n.language}_message`] || json.message;
          alert(t(`${translatedMessage}`));
        }
      });
    }
  };

  // CHANGE PASSWORD

  const handleSavePassword = () => {
    if (
      passwordData.old_password.length == "" ||
      passwordData.password.length == "" ||
      passwordData.password2.length == ""
    ) {
      alert(t("All Field Required"));
    } else {
      reset_password({
        ...passwordData,
        token: token,
      }).then((json) => {
        if (json.status) {
          const translatedMessage = json[`${i18n.language}_message`] || json.message;
          alert(t(`${translatedMessage}`));
          window.location.reload();
        } else {
          const translatedMessage = json[`${i18n.language}_message`] || json.message;
          alert(t(`${translatedMessage}`));
        }
      });
    }
  };

  // ADD NEW ADDRESS BOOK

  const handleAddAddress = () => {
    if (
      addressData.name.length == "" ||
      addressData.phone.length == "" ||
      addressData.address.length == "" ||
      addressData.address2.length == "" ||
      addressData.city.length == "" ||
      addressData.postcode.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert(t("All Field Required"));
    }

    add_address({
      ...addressData,
      token: token,
    }).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.location.reload();
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  // ADDRESS BOOK DETAIL

  // EDIT ADDRESS BOOK

  const handleEditAddress = () => {
    if (
      addressBookDetail.name.length == "" ||
      addressBookDetail.phone.length == "" ||
      addressBookDetail.address.length == "" ||
      addressBookDetail.address2.length == "" ||
      addressBookDetail.city.length == "" ||
      addressBookDetail.postcode.length == "" ||
      addressBookDetail.state.length == "" ||
      addressBookDetail.country.length == ""
    ) {
      alert(t("All Field Required"));
    }
    let updatedDefaultAddress = addressBookDetail.default_address;
    if (defaultAddressChanged) {
      updatedDefaultAddress = addressBookDetail.default_address === "0" ? "1" : "0";
    }

    edit_address({
      ...addressBookDetail,
      token: token,
      user_address_id: addressBookID,
      // default_address: updatedDefaultAddress,
    }).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.location.reload();
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  // DELETE ADDRESS BOOK

  const handleDeleteAddress = (user_address_id) => {
    delete_address({ user_address_id: user_address_id }).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.location.reload();
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };


  const [user, setUser] = React.useState([])
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const getUser = () => {
    get_user({ token: token }).then((json) => {
      if (json.status) {
        setUser(json.data)
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("business_type");
        window.location.reload()
      }
    })
  }


  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section pd="8rem 0 0 0">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={3}>
              <br />
              {tab == 0 && (
                <div>
                  <Paragraph center bold="600" size="20px" color="#353535"
                    style={{ fontFamily: "'Gotu', sans-serif" }}
                  >
                    {t("MY ACCOUNT")}
                  </Paragraph>
                </div>
              )}
              {tab == 1 && (
                <div>
                  <Paragraph center bold="600" size="20px" color="#353535"
                    style={{ fontFamily: "'Gotu', sans-serif" }}
                  >
                    {t("SECURITY")}
                  </Paragraph>
                </div>
              )}
              {tab == 2 && (
                <div>
                  <Paragraph center bold="600" size="20px" color="#353535"
                    style={{ fontFamily: "'Gotu', sans-serif" }}
                  >
                    {t("ADDRESS BOOK")}
                  </Paragraph>
                </div>
              )}
              {tab == 3 && (
                <div>
                  <Paragraph center bold="600" size="20px" color="#353535"
                    style={{ fontFamily: "'Gotu', sans-serif" }}
                  >
                    {t("ORDER HISTORY")}
                  </Paragraph>
                </div>
              )}
              <br />
              <div>
                <a
                  className={tab == 0 ? classes.theLActive : classes.theL}
                  onClick={() => handleChangeTab(0)}
                >
                  <Person style={{ marginRight: 10 }} />
                  <Paragraph>{t("User Information")}</Paragraph>
                </a>
                <a
                  className={tab == 1 ? classes.theLActive : classes.theL}
                  onClick={() => handleChangeTab(1)}
                >
                  <Lock style={{ marginRight: 10 }} />
                  <Paragraph>{t("Security")}</Paragraph>
                </a>
                <a
                  className={tab == 2 ? classes.theLActive : classes.theL}
                  onClick={() => handleChangeTab(2)}
                >
                  <Bookmark style={{ marginRight: 10 }} />
                  <Paragraph>{t("Address Book")}</Paragraph>
                </a>
                <a
                  className={tab == 3 ? classes.theLActive : classes.theL}
                  onClick={() => handleChangeTab(3)}
                >
                  <Inbox style={{ marginRight: 10 }} />
                  <Paragraph>{t("Order History")}</Paragraph>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {tab == 0 && (
                <div className={classes.theBox}>
                  <Paragraph size="20px" color="#353535">
                    {t("User Information")}
                  </Paragraph>
                  <FormInput
                    label={t("Name")}
                    placeholder={t("Name")}
                    border="1px solid #707070 "
                    onChange={handleChange}
                    name="name"
                    value={profileData.name}
                  />
                  <FormInput
                    label={t("Contact Number")}
                    placeholder={t("Contact Number")}
                    border="1px solid #707070"
                    type="number"
                    name="contact"
                    onChange={handleChange}
                    value={profileData.contact}
                  />
                  <br />
                  <Button
                    label={t("SAVE CHANGES")}
                    color="#fff"
                    bg="#353535"
                    bold="600"
                    br="6px"
                    onClick={() => handleEditProfile()}
                  />
                </div>
              )}
              {tab == 1 && (
                <div className={classes.theBox}>
                  <Paragraph size="20px" color="#353535">
                    {t("Password")}
                  </Paragraph>
                  <FormInput
                    label={t("Current Password")}
                    placeholder={t("Current Password")}
                    border="1px solid #707070 "
                    type="password"
                    name="old_password"
                    onChange={handleChange}
                  />
                  <FormInput
                    label={t("New Password")}
                    placeholder={t("New Password")}
                    border="1px solid #707070 "
                    type="password"
                    name="password"
                    onChange={handleChange}
                  />
                  <FormInput
                    label={t("Re-enter New Password")}
                    placeholder={t("Re-enter New Password")}
                    border="1px solid #707070 "
                    type="password"
                    name="password2"
                    onChange={handleChange}
                  />
                  <br />
                  <Button
                    label={t("SAVE CHANGES")}
                    color="#fff"
                    bg="#353535"
                    bold="600"
                    br="6px"
                    onClick={() => handleSavePassword()}
                  />
                </div>
              )}
              {tab == 2 && (
                <div className={classes.theBox}>
                  {/* <Paragraph size="20px" color="#353535">
                      Address Book
                    </Paragraph> */}
                  <br />

                  {addressList == null ? (
                    <></>
                  ) : (
                    addressList.map((item) => (
                      <>
                        <div className={classes.theList}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={8}>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Paragraph>{item.name}</Paragraph>
                                  {/* <Button
                                    label="Default"
                                    color="#353535"
                                    box="1px solid #353535"
                                    bold="600"
                                    br="4px"
                                    pd=".4rem 1rem"
                                    size="12px"
                                  /> */}
                                </div>
                                <br />
                                <Paragraph>
                                  {item.address}, {item.address2}, {item.city}, {item.postcode},{" "}
                                  {item.state}, {item.contry}
                                </Paragraph>
                                <Paragraph size="14px" color="#637381">
                                  {item.phone}
                                </Paragraph>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              style={{ display: "flex" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "100%",
                                }}
                              >
                                <Edit
                                  style={{
                                    color: "#353535",
                                    marginRight: "1rem",
                                  }}
                                  onClick={() => handleOpenEditModal(item.user_address_id)}
                                />
                                <Delete
                                  style={{ color: "#353535", marginRight: "0" }}
                                  onClick={() =>
                                    handleDeleteAddress(item.user_address_id)
                                  }
                                />
                                {/* <Button
                                  label="DELETE ADDRESS"
                                  color="#353535"
                                  box="1px solid #353535"
                                  pd=".4rem 1rem"
                                  br="4px"
                                  size="12px"
                                /> */}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </>
                    ))
                  )}


                  <Button
                    label={t("ADD NEW ADDRESS")}
                    color="#fff"
                    bg="#353535"
                    bold="600"
                    br="6px"
                    onClick={() => handleOpenModal()}
                  />
                </div>
              )}
              {tab == 3 && (
                <div className={classes.theBox}>
                  <TableContainer style={{ height: "500px" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("Date Created")}</TableCell>
                          <TableCell>{t("Order ID")}</TableCell>
                          <TableCell>{t("Delivery Address")}</TableCell>
                          <TableCell>{t("Total Amount")}</TableCell>
                          <TableCell>{t("Status")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderHis == null ? (
                          <></>
                        ) : (
                          orderHis
                            .slice()
                            .reverse()
                            .map((item) => (
                              <TableRow>
                                <TableCell>{item.created_date}</TableCell>
                                <TableCell>{item.orders_id}</TableCell>

                                {item.address == "" ? (
                                  <TableCell>{t("Self Pick Up")}</TableCell>
                                ) : (
                                  <TableCell>{item.address}, {item.address2}, {item.postcode}, {item.state}, {item.country}</TableCell>
                                )}

                                <TableCell>$ {item.total_amount}</TableCell>
                                <TableCell style={{
                                  color: item.status === 'Delivered' ? 'green'
                                    : item.status === 'Claimed' ? 'green'
                                      : item.status === 'Cancel' ? 'red'
                                        : 'inherit'
                                }}>
                                  {i18n.language === 'en' && item.status}
                                  {i18n.language === 'my' && item.malay_status_name}
                                  {i18n.language === 'my' && item.malay_status_name == "" && item.status}
                                  {i18n.language === 'cn' && item.chinese_status_name}
                                  {i18n.language === 'cn' && item.chinese_status_name == "" && item.status}
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Footer />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              {t("Add New Address")}
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Full Name")}
                  placeholder={t("Full Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Address 2")}
                  placeholder={t("Address 2")}
                  border="1px solid #707070"
                  name="address2"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Country")}
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Town / City")}
                  placeholder={t("Town / City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Zip / Postal Code")}
                  placeholder={t("Zip / Postal Code")}
                  border="1px solid #707070"
                  name="postcode"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#353535"
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* EDIT ADDRESS */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              {t("Edit Address")}
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Full Name")}
                  placeholder={t("Full Name")}
                  border="1px solid #707070"
                  name="name"
                  onChange={handleChange}
                  value={addressBookDetail.name}
                />
                <FormInput
                  label={t("Address")}
                  placeholder={t("Address")}
                  border="1px solid #707070"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.address}
                />
                <FormInput
                  label={t("Address 2")}
                  placeholder={t("Address 2")}
                  border="1px solid #707070"
                  name="address2"
                  onChange={handleChange}
                  value={addressBookDetail.address2}
                />
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  border="1px solid #707070"
                  name="state"
                  onChange={handleChange}
                  value={addressBookDetail.state}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label={t("Country")}
                  placeholder={t("Country")}
                  border="1px solid #707070"
                  name="country"
                  onChange={handleChange}
                  value={addressBookDetail.country}
                />
                <FormInput
                  label={t("Phone")}
                  placeholder={t("Phone")}
                  border="1px solid #707070"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={addressBookDetail.phone}
                />{" "}
                <FormInput
                  label={t("Town / City")}
                  placeholder={t("Town / City")}
                  border="1px solid #707070"
                  name="city"
                  onChange={handleChange}
                  value={addressBookDetail.city}
                />{" "}
                <FormInput
                  label={t("Zip / Postal Code")}
                  placeholder={t("Zip / Postal Code")}
                  border="1px solid #707070"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                  value={addressBookDetail.postcode}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={defaultAddress === "1"}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={t("Set As Default Address")}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label={t("SUBMIT")}
              color="#fff"
              bg="#353535"
              br="6px"
              onClick={() => handleEditAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
