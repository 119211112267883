import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button as Btn,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
// import { useLocalStorage } from "../../hooks/useLang";

import logo from "../../assets/images/logo.png";

import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import Button from "../Button/Button";
import { Modal } from "@mui/material";
// import commingbg from "../../assets/images/commingbg.png";
// import comingsoon from "../../assets/images/comingsoon.png";
import { ModalBox } from "./styles";
import Paragraph from "../Typography/Paragraph";
import { I18nextProvider, useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
  theBGColor: {
    background: "#2C3248",
  },
  theBGMain: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  theContent: {
    position: "absolute",
    left: "0",
    right: "0",
    marginRight: "auto",
    marginLeft: "auto",
    top: "50%",
    transform: "translateY(-50%)",
    padding: "3rem",
  },
}));

const SidebarMenu = ({ open, onClose, handleDrawerToggle }) => {
  const classes = useStyle();
  const location = useLocation();
  const { t } = useTranslation();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    history("/");
    window.location.reload();
  };

  // SEARCH PRODUCT

  const history = useNavigate();

  //Language

  // const [languageE1, setLanguageE1] = React.useState(null);
  // const languageopen = Boolean(languageE1);
  // const handleLanguage = (event) => {
  //   setLanguageE1(event.currentTarget);
  // };
  // const handleLanguageClose = () => {
  //   setLanguageE1(null);
  // };

  // const [langJson, setLangJson, removeLangJson] = useLocalStorage(
  //   "langJson",
  //   window.localStorage.getItem("language")
  // );

  // const [lang, setLang] = React.useState(
  //   window.localStorage.getItem("language")
  // );

  // const changeLang = (value) => {
  //   setLang(value.id);
  //   window.localStorage.setItem("language", value.id);
  //   window.location.reload();
  // };
  const [openAll, setOpenAll] = React.useState(false);

  const handleClickOpenAll = () => {
    setOpenAll(true);
  };

  const handleCloseAll = () => {
    setOpenAll(false);
  };

  return (
    <>
      <Drawer
        className="theBGColor"
        anchor="left"
        open={open}
        onClose={onClose}
      >
        {sideDrawerList("left")}
        <List component="nav">
          <NavbarSidebarList>
            <NavbarSidebarListLogo>
              <Link to="/">
                <img src={logo} />
              </Link>
            </NavbarSidebarListLogo>
            <CloseButtonBox>
              <a onClick={handleDrawerToggle}>
                <CloseIcon />
              </a>
            </CloseButtonBox>

            <br />
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/"
              >
                <ListItemText
                  primary={t("HOME")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === '' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === '' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/about"
              >
                <ListItemText
                  primary={t("ABOUT US")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'about' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'about' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/product"
              >
                <ListItemText
                  primary={t("PRODUCT")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'product' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'product' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/gallery"
              >
                <ListItemText
                  primary={t("GALLERY")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'gallery' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'gallery' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/shop"
              >
                <ListItemText
                  primary={t("SHOP")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'shop' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'shop' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/contact"
              >
                <ListItemText
                  primary={t("CONTACT")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'contact' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'contact' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/warranty"
              >
                <ListItemText
                  primary={t("WARRANTY")}
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'warranty' ? "#000" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'warranty' ? "700" : "normal",
                  }}
                />
              </a>
            </ListItem>

            <br />

            {/* {token ? (
              <></>
            ) : (
              <>
                <Button
                  label="LOGIN"
                  color="#FFB90F"
                  box="1px solid #FFB90F"
                  bold="600"
                  br="6px"
                  href="/login"
                  style={{ marginBottom: "1rem" }}
                />
                <Button
                  label="CART"
                  color="#fff"
                  bg="#00b9f0"
                  bold="500"
                  br="4px"
                  // onClick={() => handleLogout()}
                  href="/register"
                  style={{ marginBottom: "1rem" }}
                />
              </>
            )} */}

            <br />
            <br />
            {/* <div>
            <Link to="/login">
              <Button
                label={langJson.login}
                color="#fff"
                bg="#c0522b"
                bold="600"
                box="2px solid #c0522b"
              />
            </Link>
          </div>
          <br />
          <div>
            <Link to="/register">
              <Button
                label={langJson.register}
                color="#c0522b"
                box="2px solid #c0522b"
                bold="600"
              />
            </Link>
          </div> */}

            {/* <div>
            <Btn
              style={{ color: "#c0522b", fontWeight: "800", marginTop:"1rem" }}
              id="basic-button"
              aria-controls={languageopen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={languageopen ? "true" : undefined}
              onClick={handleLanguage}
            >
              <LanguageIcon />
              {lang}
            </Btn>
            <Menu
              id="basic-menu"
              anchorEl={languageE1}
              open={languageopen}
              onClose={handleLanguageClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleLanguageClose}>
                <div
                  style={{ color: "#c0522b" }}
                  onClick={() => changeLang({ id: "EN" })}
                >
                  <p>{langJson.en}</p>
                </div>
              </MenuItem>
              <MenuItem onClick={handleLanguageClose}>
                <div
                  style={{ color: "#c0522b" }}
                  onClick={() => changeLang({ id: "MY" })}
                >
                  <p>{langJson.my}</p>
                </div>
              </MenuItem>
            </Menu>
          </div> */}
          </NavbarSidebarList>
        </List>
      </Drawer>

      <Modal
        open={openAll}
        onClose={handleCloseAll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          <div className={classes.BGMain}>
            {/* <img src={commingbg} /> */}
            <div className={classes.theContent}>
              <div>{/* <img src={comingsoon} /> */}</div>
              <br />
              <div>
                <Paragraph center color="#fff" bold="700">
                  Working is still in progress. Stay Tuned!
                </Paragraph>
              </div>
              <br />
              <Button
                label="Back"
                color="#fff"
                bg="#3094EB"
                br="8px"
                onClick={() => handleCloseAll()}
              />
            </div>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default SidebarMenu;
