import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import banner002 from "../../assets/images/banner002.png";
import { FormControl } from "@mui/material";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
  display: flex;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

const config = {

  theFontTitle: {
    fontSize: "34px",
    ...md("sm", {
      fontSize: "44px",
    }),
    ...md("md", {
      fontSize: "54px",
    }),
    ...md("xmd", {
      fontSize: "64px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "20px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theSeeMore: {
    width: "30%",
    fontSize: "12px",
    background: "#333335",
    padding: ".4rem 1rem",
    color: "#FFF",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "600",

    ...md("md", {
      width: "15%",
    }),

    ...md("xmd", {
      width: "10%",
    }),
  },

  theBox: {
    position: "absolute",
    // width: "100%",
    top: "50%",
    left: "5%",
    transform: "translateY(-50%)",

    ...md("md", {
      top: "50%",
      width: "50%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theService: {
    position: "relative",
    width: "20%",
    "& img": {
      width: "100%",
      marginBottom: "1rem",
    },
  },

  theFlex: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",

    ...md("md", {
      display: "flex",
    }),
  },

  theTabList: {
    display: "block",
    ...md("md", {
      display: "flex",
    }),
  },

  theMB: {
    marginTop: "2rem",
    display: "block",
    fontStyle: "italic",
    ...md("md", {
      marginTop: "0rem",
      fontStyle: "unset",
    }),
  },

  theTabActive: {
    borderBottom: "2px solid #000",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theTab: {
    borderBottom: "2px solid #FFF",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theGallery: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  theStone: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex",
    },
  },
  theOverlay: {
    position: "absolute",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    top: 0,
    left: 0,
    padding: "1rem",
    background: "rgba(0,0,0,.5)",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  theProd: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "70%",
      margin: "0 auto",
      display: "flex",
    },
  },
  theBG: {
    height: "auto",
    backgroundImage: "url(" + banner002 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
  },
  thePersonStar: {
    position: "relative",
    width: "15%",
    marginRight: "1rem",

    ...md("sm", {
      width: "8%",
    }),

    ...md("md", {
      width: "15%",
    }),
    "& img": {
      width: "100%",
    },
  },

  theMap: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theCustom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    width: "70%",
    margin: "0 auto",
    padding: "2rem",

    ...md("md", {
      padding: "2rem 6rem",
    }),
  },
  theCert: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },
  radioForm: {
    background: "rgb(51, 51, 53)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  radioUser: {
    opacity: 1,
    padding: "15px 20px 20px 20px"
  },
  theForm: {
    border: "1px solid #DDDADE",
    padding: "20px",
  },
  theWidth02: {
    // marginLeft: "1rem",
    width: "80%",
    ...md("md", {
      width: "100%",
    }),
  },
  formProduct: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 2rem 0"
  },
  pdfWarranty: {
    display: "flex",
    border: "1px solid #000",
    padding: ".4rem .8rem",
    borderRadius: "8px",
    width: "90%",
    ...md("sm", {
      width: "45%"
    }),
    ...md("md", {
      width: "30%"
    }),
    ...md("lg", {
      width: "26%"
    })
  }
};

export default makeStyles(config);
