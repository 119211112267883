import React, { useEffect, useState } from 'react';
import logo from "../assets/images/logo.png";
import './LoadingScreen.css';

function LoadingScreen() {
  const [showLogo, setShowLogo] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeOut(true);
    }, 3000); // Adjust the timeout duration as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setShowLogo(true);
    }, 500); // Adjust the fade-in delay as needed

    return () => {
      clearTimeout(fadeTimeout);
    };
  }, []);

  useEffect(() => {
    if (fadeOut) {
      const fadeOutTimeout = setTimeout(() => {
        // Perform any necessary action here, such as redirecting to another page
        console.log('Fade-out complete');
      }, 3000); // Adjust the fade-out duration as needed

      return () => {
        clearTimeout(fadeOutTimeout);
      };
    }
  }, [fadeOut]);

  return (
    <div className={`loading-screen ${fadeOut ? 'fade-out' : ''}`}>
      <div className={`preloader-plus ${showLogo ? 'complete' : ''}`}>
        <div className="preloader-content">
          <img
            src={logo}
            alt="Logo"
            className="preloader-custom-img"
          />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
