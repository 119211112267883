import React from "react";
import { BrowserRouter } from "react-router-dom";
import CartContextProvider from "./context/CartContext";
import { I18nextProvider } from "react-i18next";
import useLang from "./hooks/useLang"
import Routes from "./Routes";
import "./App.css";
function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      {isMobile ? (
        <>
          {/* <div className="box">
            <div className="head">
              <div className="eyes">
                <div className="eye left"></div>
                <div className="eye right"></div>
              </div>
              <div className="blushes">
                <div className="blush left"></div>
                <div className="blush right"></div>
              </div>
              <div className="mouth">
                <div className="lip left"></div>
                <div className="lip right"></div>
              </div>
            </div>
            <div className="shadow"></div>
          </div> */}
          <I18nextProvider i18n={useLang}>
          <CartContextProvider>
            <BrowserRouter>
              <Routes></Routes>
            </BrowserRouter>
          </CartContextProvider>
          </I18nextProvider>
        </>
      ) : (
        <I18nextProvider i18n={useLang}>
        <CartContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </CartContextProvider>
        </I18nextProvider>
      )}
    </>
  );
}

export default App;
