import React, { useState } from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import GalleryPage from "./pages/Gallery/Gallery";
import ShopPage from "./pages/Shop/Shop";
import ProductPage from "./pages/Product/Product";
import ProductDetailPage from "./pages/ProductDetail/ProductDetail";
import ShopDetailPage from "./pages/ShopDetail/ShopDetail";
import AccountPage from "./pages/Account/Account";
import CartPage from "./pages/Cart/Cart";
import PaymentPage from "./pages/Payment/Payment";
import WarrantyPage from "./pages/Warranty/Warranty";

export default function Routes() {
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route  path="/shopdetail/:product_id" element={<ShopDetailPage />} />
      <Route path="/product" element={<ProductPage />} />
      <Route path="/productdetail/:product_id" element={<ProductDetailPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/warranty" element={<WarrantyPage />} />
    </Router>
  );
}
