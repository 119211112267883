import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;


export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;


const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theBanner: {
    position: "relative",
    width: "100%",
    // height: "25rem",

    // ...md("md", {
    //   height: "50rem"
    // }),

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "20px",
    ...md("sm", {
      fontSize: "24px",
    }),
    ...md("md", {
      fontSize: "34px",
    }),
    ...md("lg", {
      fontSize: "44px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("xmd", {
      fontSize: "23px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",

    ...md("md", {
      top: "50%",
      left: "50%",
      //   width: "100%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theBox1: {
    position: "absolute",
    // width: "100%",
    top: "24%",
    width: "50%",
    left: "26.5%",
    transform: "translateY(-50%)",

    ...md("xmd", {
      left: "22%",
      top: "30%",
      width: "60%",
    }),
    ...md("slg", {
      left: "20%",
      top: "33%",
    }),
    ...md("xl", {
      left: "20%",
      top: "40%",
    })
  },

  theFlex: {
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    ...md("md", {
      display: "flex",
    }),
  },

  theTabList: {
    display: "block",
    width: "100%",
    ...md("md", {
      display: "flex",
      justifyContent: "center",
    }),
  },

  theMB: {
    marginTop: "2rem",
    display: "block",
    fontStyle: "italic",
    ...md("md", {
      marginTop: "0rem",
      fontStyle: "unset",
    }),
  },

  theTabActive: {
    borderBottom: "2px solid #800000",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theTab: {
    borderBottom: "2px solid #FFF",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      display: "flex",
      height: "160px",
      objectFit: "cover"
    },
  },

  theWidth: {
    display: "flex",
    width: "100%",
    alignItems: "center",

    ...md("sm", {
      width: "50%",
    }),

    ...md("md", {
      width: "40%",
    }),

    ...md("xmd", {
      width: "45%",
    }),
  },

  theWidth01: {
    width: "25%",
    ...md("sm", {
      width: "30%",
    }),
    ...md("md", {
      width: "35%",
    }),
    ...md("xmd", {
      width: "25%",
    }),
  },

  theWidth02: {
    marginLeft: "1rem",
    width: "80%",

    ...md("md", {
      width: "35%",
    }),
  },


  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },
  theIcon: {
    position: "relative",
    width: "12%",
    marginRight: ".4rem",
    "& img": {
      width: "100%",
    },
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: ".8rem 0",
    justifyContent: "center",
    // border: "1px solid #BA2025",
    // width: '30%'
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "52%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  searchBar: {
    position: "relative",
    display: "flex",
    bottom: "10rem",
    left: "12rem",
    ...md("md", {
      left: "17rem",
      bottom: "12rem",
    }),
    ...md("xmd", {
      left: "24rem",
      bottom: "15rem",
    }),
    ...md("slg", {
      left: "30rem",
      bottom: "20rem",
    }),
    ...md("xl", {
      left: "40rem",
      bottom: "25rem",
    })
  },

  bannerOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  textOverlay: {
    display: "flex",
    flexDirection: 'column',
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
  },

  searchBarOverlay: {
    width: "15rem",
    ...md("sm", {
      width: "25rem"
    }),
    ...md("md", {
      width: "40rem"
    }),
    ...md("xl", {
      width: "60rem"
    })
  },
  buttonHover: {
    "&:hover": {
      background: "rgb(220,220,220)",
  }
}
};

export default makeStyles(config);
