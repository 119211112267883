import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import map from "../../assets/images/map.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  ArrowBackIos,
  ArrowDropDown,
  CheckCircleOutline,
  HighlightOff,
  Mail,
  Phone,
  Star,
  Storefront,
} from "@mui/icons-material";
import { contact_us, get_profile, get_user } from "../../API/API";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { submit_enquiry } from "../../API/API";

export default function Contact() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useNavigate();
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // API CONTACT US

  const [contactData, setContactData] = React.useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };

  const handleContactUs = () => {
    if (
      profileData.email.length == "" ||
      profileData.name.length == "" ||
      profileData.contact.length == "" ||
      contactData.message.length == ""
    ) {
      alert(t("All Field Required"));
      return;
    }
    contact_us({ ...contactData, ...profileData, token: token, }).then((json) => {
      if (json.status) {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    });
  };

  const [token, setToken] = React.useState([]);
  const [user, setUser] = React.useState([]);
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const getUser = () => {
    get_user({ token: token }).then((json) => {
      if (json.status) {
        setUser(json.data)
      } else {
        // alert(json.message);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("business_type");
        window.location.reload()
      }
    })
  }

  const [profileData, setProfileData] = React.useState({
    name: "",
    contact: "",
    email: "",
  });

  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const getProfile = () => {
    get_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      }
    })
      .catch((error) => {
        console.error("getUser error:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("business_type");
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("isLoggedIn");
        history.push("/");
        window.location.reload();
      });
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      getProfile();
    }
    // getUser()
  }, [isLoggedIn])

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ padding: "8rem 0 0 0" }}>
        <div style={{ background: "#333335" }}>
          <Section>
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div className={classes.theCustom}>
                  <Paragraph
                    bold="700"
                    color="#FFF"
                    size="34px"
                    margin="0 0 1rem 0"
                  >
                    {t("Let's Chat With Us.")}
                  </Paragraph>
                  <Paragraph color="#FFF">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.{" "}
                  </Paragraph>
                  <br />
                  <br />
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <Mail style={{ color: "#FFF" }} />
                    <Paragraph color="#FFF" margin="0 0 0 1rem">
                      ottoglobalapexsurfacing@email.com
                    </Paragraph>
                  </div>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <Phone style={{ color: "#FFF" }} />
                    <div>
                      <div style={{ display: "flex" }}>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          MY
                        </Paragraph>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          +607-6991449 / +6010-9301449
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          SG
                        </Paragraph>
                        <Paragraph color="#FFF" margin="0 0 0 1rem">
                          +65-63658781
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <Storefront style={{ color: "#FFF" }} />
                    <div>
                      <div style={{ display: "flex" }}>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          Mon - Fri
                        </Paragraph>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          8:30 am - 6:00 pm
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          Sat
                        </Paragraph>
                        <Paragraph color="#FFF" margin="0 0 0 1rem">
                          8.30 am - 1:00 pm
                        </Paragraph>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Paragraph color="#FFF" margin="0 0 1rem 1rem">
                          Sun
                        </Paragraph>
                        <Paragraph color="#FFF" margin="0 0 0 1rem">
                          Closed
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.theMap}>
                  <img src={map} />
                </div>
              </Grid>
            </Grid>
            <br />
          </Section>
        </div>
        <Container>
          <Section pd="4rem 0">
            <Paragraph margin="0 0 1.5rem 0" size="24px" color="#000">
              {t("We Like To Hear From You")}
            </Paragraph>
            <FormInputNoLabel
              placeholder={t("Name")}
              bg="#FFF"
              border="1px solid #d7d7d7"
              name="name"
              onChange={handleChange}
              value={profileData.name}
            />
            <FormInputNoLabel
              placeholder={t("Email")}
              bg="#FFF"
              border="1px solid #d7d7d7"
              name="email"
              onChange={handleChange}
              value={profileData.email}
            />
            <FormInputNoLabel
              placeholder={t("Contact Number")}
              bg="#FFF"
              border="1px solid #d7d7d7"
              type="number"
              name="contact"
              onChange={handleChange}
              value={profileData.contact}
            />
            <FormInputNoLabel
              placeholder={t("Message")}
              multiline
              rows={4}
              bg="#FFF"
              border="1px solid #d7d7d7"
              name="message"
              onChange={handleChange}
            />
            <Button
              label={t("SUBMIT")}
              color="#FFF"
              bg="#333335"
              br="10px"
              onClick={() => handleContactUs()}
            />
          </Section>
        </Container>
      </div>

      <Footer />
    </React.Fragment>
  );
}
