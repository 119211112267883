import axios from "axios";
const host = "https://globalsurfacing.co/admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
    // REGION
    // var region = "MY";
    // let region_res = window.localStorage.getItem("region");
    // if (region_res) {
    //   region = region_res;
    // }
    // if (region) {
    //   postparam["region"] = region;
    // }
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

export const login = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("business_type", json.data.user_type_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    // console.log(json.data);
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const get_category = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_category",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_colour = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_colour",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_area = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_area",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_brand = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_brand",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_banner = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_gallery = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_gallery",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const contact_us = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "contact_us",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const subscribe = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "subscribe",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_shop = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_shop",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_outlet_store = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_outlet_store",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const add_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "add_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_random_shop = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_random_shop",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_history = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_history",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const reset_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "reset_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const delete_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "delete_address ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address_detail ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_type = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_type ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_random_gallery = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_random_gallery ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_stone = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_stone ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_related_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_related_product ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_related_shops = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_related_shops ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_related_image = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_related_image ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_related_colour = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_related_colour ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
}

export const get_gallery_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_gallery_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_related_description_image = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_related_description_image",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const submit_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "submit_order",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_download = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "download_image/" + postparam.product_id);
  var blob = await response.blob();

  return blob;
};

export const get_surfaces = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_surfaces",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

// export const register_warranty = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "register_warranty",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// };


export const register_warranty = async (formData) => {
  try {
    const response = await fetch(access_endpoint_link + 'register_warranty', {
      method: "POST",
      body: formData
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const get_warranty_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_warranty_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_promo_code = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_promo_code",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const use_promo_code = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "use_promo_code",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_product_stock = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_product_stock",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};


export const get_upload_file = (postparam) => {
  return axios.post(access_endpoint_link + "get_upload_file", postparam);
};
