import React from "react";
import Slider from "react-slick";
import useStyles, { IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/FormInput/FormInput";
import silestone001 from "../../assets/images/silestone001.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";
import { check_product_stock, get_user } from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Cart() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [count, setCount] = React.useState(1);

  // CART LIST

  const {
    getCart,
    decreaseProduct,
    increaseProduct,
    removeProduct,
    getTotalCart,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const productCartList = getcart.replace(/\\/g, "");

  const [qtyCount, setQtyCount] = React.useState(0);

  React.useEffect(() => {
    setCart(getCart());
  }, [qtyCount, getCart]);

  // const [userType, setUserType] = React.useState([]);

  // React.useEffect(() => {
  //   let user_type_id = window.localStorage.getItem("user_type_id");
  //   setUserType(user_type_id || "");
  // }, [])

  // const [businessType, setBusinessType] = React.useState([]);

  // React.useEffect(() => {
  //   let business_type = window.localStorage.getItem("business_type");
  //   setBusinessType(business_type || "");
  // }, []);

  //CHECKOUT

  const handleCheckout = () => {
    const cartItems = getCart();
    const cartLength = cartItems.length;

    if (cartLength === 0) {
      alert(t("Your Cart is Empty"));
      return;
    }

    const products = cartItems.map((item) => ({
      product_id: item.product_id,
      quantity: item.quantity
    }))


    check_product_stock({
      products: JSON.stringify(products)
    }).then((json) => {
      if (json.status) {
        navigate("/payment")
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
      }
    })
  }

  // const handleCheckout = () => {
  //   var cartLength = getCart().length;
  //   if (cartLength == 0) {
  //     alert(t("Your Cart is Empty"));
  //   } else {
  //     navigate("/payment");
  //   }
  // };

  const [token, setToken] = React.useState([])
  const [user, setUser] = React.useState([])
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const getUser = () => {
    get_user({ token: token }).then((json) => {
      if (json.status) {
        setUser(json.data)
      } else {
        const translatedMessage = json[`${i18n.language}_message`] || json.message;
        alert(t(`${translatedMessage}`));
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("business_type");
        window.location.reload()
      }
    })
  }

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="8rem 0 2rem 0">
          <Paragraph
            size="34px"
            color="#353535"
            bold="700"
            center
            margin="0 0 2rem 0"
            style={{ fontFamily: "'Gotu', sans-serif" }}
          >
            {t("My Cart")}
          </Paragraph>
          <TableContainer>
            <Table>
              <TableHead className={classes.theHead}>
                <TableRow>
                  <TableCell>{t("Product")}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{t("Price")}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{t("Quantity")}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{t("Total")}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>


                {/* Cart Appeared New */}
                {cart != undefined && cart.length > 0
                  ? cart.map((item) => (
                      <TableRow>
                        <TableCell>
                          <div
                            style={{ display: "flex" }}
                            className={classes.theWidth}
                          >
                            <div className={classes.theIMG}>
                              <img src={item.image} />
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="theEllipsis"
                              >
                                {item.category}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                bold="700"
                                size="14px"
                                className="theEllipsis"
                              >
                                {item.name}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="theEllipsis"
                              >
                                {item.brand}
                              </Paragraph>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <Paragraph center>RM {item.single_price}</Paragraph>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <div className={classes.theFlexQuantity}>
                              <div className={classes.theIncrement}>
                                <IncrementAction2
                                  margin="0 1rem 0 0"
                                  onClick={() => {
                                    decreaseProduct(item.index);
                                    setQtyCount(qtyCount - 1);
                                  }}
                                >
                                  <Remove className={classes.theButtonStyle2} />
                                </IncrementAction2>

                                {count == 0 ? (
                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>0</p>
                                  </div>
                                ) : (
                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>
                                      {item.quantity}
                                    </p>
                                  </div>
                                )}

                                <IncrementAction
                                  margin="0 0 0 1rem"
                                  onClick={() => {
                                    increaseProduct(item.index);
                                    setQtyCount(qtyCount + 1);
                                  }}
                                >
                                  <Add className={classes.theButtonStyle} />
                                </IncrementAction>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <Paragraph center>
                              RM {(item.price * parseFloat(count)).toFixed(2)}
                            </Paragraph>
                          </div>
                        </TableCell>
                        <TableCell>
                          <a onClick={() => removeProduct(item.index)}>
                            <Close />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}

                  
              </TableBody>
            </Table>
          </TableContainer>
          <br />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              {/* <div className={classes.theFlex_}>
                <div className={classes.theWidths}>
                  <FormInput
                    placeholder="Promo Code"
                    border="1px solid #707070"
                    style={{ width: "100%" }}
                  />
                </div>

                <Button label="APPLY" color="#fff" bg="#353535" br="6px" />
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  paddingTop: "1rem",
                  // borderTop: "1px solid #d7d7d7",
                }}
              >
                <Paragraph size="1.2rem">{t("Subtotal")}</Paragraph>
                <Paragraph bold="500" size="1.2rem">
                  RM {getTotalCart().toFixed(2)}
                </Paragraph>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Paragraph size="1.2rem">Shipping Fee</Paragraph>
                <Paragraph bold="500" size="1.2rem">
                  RM 10.00
                </Paragraph>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  paddingTop: "1rem",
                  borderTop: "1px solid #d7d7d7",
                }}
              >
                <Paragraph size="1.2rem">Total</Paragraph>
                <Paragraph color="#353535" bold="700" size="1.2rem">
                  RM {getTotalCart().toFixed(2)}
                </Paragraph>
              </div> */}
            </Grid>
          </Grid>

          <br />

          <br />

          <div className={classes.theFlexx}>
            <div className={classes.theRight}>
              <Button
                href="/"
                label={t("Continue Shopping")}
                br="4px"
                color="#353535"
                box="1px solid #353535"
              />
            </div>
            <div>
            <Button
                  // href="/payment"
                  onClick={() => handleCheckout()}
                  label={t("Proceed to Checkout")}
                  br="4px"
                  color="#fff"
                  bg="#353535"
                />
            </div>
            {/* <div>
              {businessType == 1 ? (
                // <>
                //   <div>
                //     <Paragraph bold="700" size="24px" margin="0 0 1rem 0">
                //       CUSTOMER INFORMATION
                //     </Paragraph>
                //     <Grid container spacing={3}>
                //       <Grid item xs={12} sm={12} md={6}>
                //         <FormInput
                //           border="1px solid #d7d7d7"
                //           label="Name"
                //           placeholder="Name"
                //           name="name"
                //         />
                //       </Grid>
                //       <Grid item xs={12} sm={12} md={6}>
                //         <FormInput
                //           border="1px solid #d7d7d7"
                //           label="Company Name"
                //           placeholder="Company Name"
                //           name="company_name"
                //         />
                //       </Grid>
                //       <Grid item xs={12} sm={12} md={6}>
                //         <FormInput
                //           border="1px solid #d7d7d7"
                //           label="Email Address"
                //           placeholder="Email Address"
                //           name="email"
                //         />
                //       </Grid>
                //       <Grid item xs={12} sm={12} md={6}>
                //         <FormInput
                //           border="1px solid #d7d7d7"
                //           label="Contact Number"
                //           placeholder="Contact Number"
                //           name="contact"
                //         />
                //       </Grid>
                //       <Grid item xs={12} sm={12} md={12}>
                //         <FormInput
                //           border="1px solid #d7d7d7"
                //           label="Message"
                //           placeholder="Message"
                //           name="message"
                //           rows="4"
                //           multiline
                //         />
                //       </Grid>
                //     </Grid>
                //     <Button label="Send" bg="#DB1A4F" color="#FFF" br="4px" />
                //   </div>
                // </>
                <Button
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#353535"
                  onClick={() => alert("ENQUIRY REQUESTED")}
                />
              ) : (
                <Button
                  // href="/payment"
                  onClick={() => handleCheckout()}
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#353535"
                />
              )}
            </div> */}
          </div>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
