import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: -12,
        }),

        ...md("md", {
          right: -12,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: -20,
        }),

        ...md("md", {
          left: -20,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "34px",
    ...md("sm", {
      fontSize: "44px",
    }),
    ...md("md", {
      fontSize: "54px",
    }),
    ...md("xmd", {
      fontSize: "64px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "20px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theSeeMore: {
    width: "30%",
    fontSize: "12px",
    background: "#333335",
    padding: ".4rem 1rem",
    color: "#FFF",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "600",

    ...md("md", {
      width: "15%",
    }),

    ...md("xmd", {
      width: "10%",
    }),
  },

  theBox: {
    position: "absolute",
    // width: "100%",
    top: "50%",
    left: "5%",
    transform: "translateY(-50%)",

    ...md("md", {
      top: "50%",
      width: "50%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },
  


  theProdIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      objectFit: "cover"
    },
  },

  theProdIMG1: {
    cursor: "pointer",
    position: "relative",
    marginRight: "1rem",
    marginTop: "1rem",
    "& img": {
      width: "100px",
      height: "70px",
      objectFit: "cover",
      display: "flex",
    },
  },

  theProdIMG2: {
    position: "relative",
    width: "100%",

    "&  ": {
      width: "100%",
      objectFit: "contain"
    },
  },

  imageMagnify: {
    "& img": {
      width: "100%",
      height: "330px",
      objectFit: "contain"
    }

  },

  theGalleryList: {
    position: "relative",
    display: "flex",
    // background: "#333",
    overflow: "auto",
    whiteSpace: "nowrap",
  },

  theGalleryx: {
    position: "relative",
    marginRight: "1rem",
    "& img": {
      width: "100px",
    },
  },

  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: ".4rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theGallery1: {
    position: "relative",
    width: "100%",
    marginBottom: ".4rem",

    "& img": {
      width: "100%",
      height: "160px",
      objectFit: "cover",
      display: "flex",
    },
  },

  theGallery2: {
    position: "relative",
    width: "100%",
    marginBottom: ".4rem",

    "& img": {
      width: "100%",
      height: "20rem",
      objectFit: "cover",
      display: "flex",
    },
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },
  theIcon: {
    position: "relative",
    width: "12%",
    marginRight: ".4rem",
    "& img": {
      width: "100%",
    },
  },

  productDetail: {
    position: "relative",
    border: "1px solid black",
    borderRadius: "4px",
    padding: "1rem 1rem",
    display: "block",
    cursor: "pointer"
  },
  theFlexQuantity: {
    // position: "relative",
    alignItems: "center",
    display: "flex",
    margin: "5rem 0",
    justifyContent: "center",
    // border: "1px solid #BA2025",
    // width: '30%'
  },
  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "52%",
  },
  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theBox1: {
    position: "absolute",
    // width: "100%",
    top: "24%",
    width: "50%",
    left: "26.5%",
    transform: "translateY(-50%)",

    ...md("xmd", {
      left: "22%",
      top: "30%",
      width: "60%",
    }),
    ...md("slg", {
      left: "20%",
      top: "33%",
    }),
    ...md("xl", {
      left: "20%",
      top: "40%",
    })
  },
  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },
  buttonHover: {
    "&:hover": {
      background: "rgb(70,70,70)",
  }
}

};

export default makeStyles(config);
